import { graphql } from "@apollo/react-hoc";
import {
  CenteredSpinner,
  ErrorScreen,
  generateNaturalLanguageList,
  GenericDialog,
  getNotchHeight,
  isNullish,
} from "@igloocloud/igloosharedui";
import Add from "@mui/icons-material/Add";
import Create from "@mui/icons-material/Create";
import Delete from "@mui/icons-material/Delete";
import MoreVert from "@mui/icons-material/MoreVert";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import tinyColor from "tinycolor2";
import FlowManifestDialog from "./FlowManifestDialog";

const flows = [
  {
    id: "12345678-abcd-1234-abcd-1234567890ab",
    name: "Superamento potenza contrattuale",
    conditions: [
      {
        variable: {
          id: "f9476c22-cad8-4715-9cc9-02b520624274",
          name: "Pulsante",
          __typename: "ImpulseVariable",
        },
      },
    ],
    actions: [{ type: "notification", text: "Potenza sopra soglia" }],
  },
];

export default withTranslation()(
  graphql(
    gql`
      query ($limit: NaturalNumber!, $offset: NaturalNumber, $id: ID!) {
        thing(id: $id) {
          id
          flowCount
          flows(limit: $limit, offset: $offset) {
            id
            name
          }
        }
      }
    `,
    {
      name: "thingData",
      options: ({ id }) => ({
        variables: {
          offset: 0,
          limit: 20,
          id,
        },
      }),
    }
  )(
    class FlowsDialog extends Component {
      state = {
        fetchMoreLoading: false,
        createFlowOpen: false,
        createFlowMode: null,
        deleteFlowOpen: false,
        anchorEl: null,
        showDeleteLoading: false,
      };

      handleBackButton = () => {
        if (this.state.backStackIndex === window.backStack) {
          this.handleMenuClose();
        }
      };

      handleMenuClose = () => {
        this.setState({ anchorEl: null });

        if (window.cordova) {
          this.setState({ backStackIndex: 0 });

          --window.backStack;

          document.removeEventListener("backbutton", this.handleBackButton);
        }
      };

      queryMore = () => {
        //TODO: add lazy loading
      };

      deleteFlow = async (id) => {
        try {
          this.setState({ showDeleteLoading: true });

          //TODO: add mutation
        } finally {
          this.setState({ showDeleteLoading: false });
        }
      };

      render() {
        const { open, close, t, fullScreen, thingData } = this.props;
        const {
          fetchMoreLoading,
          createFlowOpen,
          createFlowMode,
          deleteFlowOpen,
          anchorEl,
          selectedFlow,
          showDeleteLoading,
        } = this.state;
        const {
          REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
          REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
          REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
          REACT_APP_ERROR_COLOR: errorColor,
        } = process.env;

        const listItemStyle = {
          paddingLeft:
            "calc(16px + " +
            (fullScreen ? getNotchHeight("left") : "0px") +
            ")",
          paddingRight:
            "calc(16px + " +
            (fullScreen ? getNotchHeight("right") : "0px") +
            ")",
          minHeight: "48px", //makes ListItem as tall as IconButton to solve scrolling issues
        };

        return (
          <>
            <GenericDialog
              title={t`Manage flows`}
              open={open && !createFlowOpen && !deleteFlowOpen}
              close={close}
              textButton={t`Close`}
              textButtonFunction={close}
              noHorizontalPadding
              noDialogContent
              maxWidth="sm"
            >
              {thingData.error ? (
                <ErrorScreen
                  refetch={() =>
                    thingData.refetch({
                      variables: { offset: 0, limit: 20 },
                    })
                  }
                  error={thingData.error}
                />
              ) : thingData.loading ? (
                <DialogContent style={{ padding: 0, height: "100%" }}>
                  <CenteredSpinner style={{ padding: "8px 0" }} />
                </DialogContent>
              ) : (
                <DialogContent
                  style={{ padding: 0 }}
                  onScroll={(event) => {
                    if (
                      event.target.scrollTop + event.target.clientHeight >=
                      event.target.scrollHeight - 600
                    )
                      this.queryMore();
                  }}
                >
                  <List
                    style={{
                      width: "100%",
                      textOverflow: "ellipsis",
                      padding: 0,
                    }}
                  >
                    <ListItem
                      button
                      style={listItemStyle}
                      onClick={() =>
                        this.setState({
                          createFlowOpen: true,
                          createFlowMode: "create",
                          intervalDuration: null,
                          intervalUnit: "minutes",
                          conditions: [{ localId: Date.now() }],
                          actions: [{ localId: Date.now() }],
                        })
                      }
                    >
                      <ListItemIcon>
                        <Add />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <font
                            style={{ color: textColor }}
                          >{t`New flow`}</font>
                        }
                      />
                    </ListItem>
                    {flows.map((flow) => (
                      <ListItem
                        key={"flows-dialog-list-" + flow.id}
                        style={listItemStyle}
                      >
                        <ListItemText
                          primary={
                            <font
                              style={{
                                color: textColor,
                              }}
                            >
                              {flow.name}
                            </font>
                          }
                          secondary={
                            <font
                              style={{
                                color: tinyColor(textColor)
                                  .setAlpha(0.54)
                                  .toRgbString(),
                                whiteSpace: "normal",
                              }}
                            >
                              {t`If` +
                                " " +
                                generateNaturalLanguageList(
                                  flow.conditions.map(
                                    ({ variable, operator, value }) =>
                                      (variable.__typename ===
                                      "FloatSeriesVariable"
                                        ? t("The last node of").toLowerCase() +
                                          " "
                                        : "") +
                                      variable.name.toLowerCase() +
                                      " " +
                                      (variable.__typename === "ImpulseVariable"
                                        ? t`triggers`
                                        : variable.__typename ===
                                            "FloatSeriesVariable" &&
                                          operator === "threshold"
                                        ? value === "upper"
                                          ? t`is over the upper threshold`
                                          : value === "lower"
                                          ? t`is under the lower threshold`
                                          : t`is outside the thresholds`
                                        : variable.__typename === "FileVariable"
                                        ? operator === "isEmpty"
                                          ? t`is empty`
                                          : t`is not empty`
                                        : (variable.__typename ===
                                          "BooleanVariable"
                                            ? t`is`
                                            : operator === "equalTo"
                                            ? t`is equal to`
                                            : operator === "greaterThan"
                                            ? t`is greater than`
                                            : operator ===
                                              "greaterThanOrEqualTo"
                                            ? t`is greater than or equal to`
                                            : operator === "lessThan"
                                            ? t`is less than`
                                            : operator === "lessThanOrEqualTo"
                                            ? t`is less than or equal to`
                                            : operator === "includes"
                                            ? `includes`
                                            : t`is not equal to`) +
                                          " " +
                                          (variable.__typename ===
                                          "BooleanVariable"
                                            ? value
                                              ? t`true`
                                              : t`false`
                                            : value))
                                  ),
                                  t
                                ) +
                                t`, then` +
                                " " +
                                generateNaturalLanguageList(
                                  flow.actions
                                    .map(({ type, text, variable, value }) =>
                                      type === "notification"
                                        ? t`send notification` + ` "${text}"`
                                        : type === "update"
                                        ? t`set` +
                                          " " +
                                          variable.name.toLowerCase() +
                                          " " +
                                          t`to` +
                                          " " +
                                          value
                                        : type === "increment"
                                        ? (value >= 0
                                            ? t`decrease`
                                            : t`increase`) +
                                          " " +
                                          variable.name.toLowerCase() +
                                          " " +
                                          t`by` +
                                          " " +
                                          Math.abs(value)
                                        : type === "negate"
                                        ? t`negate` +
                                          " " +
                                          variable.name.toLowerCase()
                                        : null
                                    )
                                    .filter((action) => !isNullish(action)),
                                  t
                                )}
                            </font>
                          }
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            marginRight: "48px",
                          }}
                          inset
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={(event) =>
                              this.setState({
                                anchorEl: event.currentTarget,
                                selectedFlow: flow,
                              })
                            }
                            size="large"
                          >
                            <MoreVert />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                  {fetchMoreLoading && <LinearProgress />}
                </DialogContent>
              )}
            </GenericDialog>

            <GenericDialog
              open={deleteFlowOpen}
              close={() => this.setState({ deleteFlowOpen: false })}
              title={t`Delete flow`}
              textButton={t`Close`}
              textButtonFunction={() =>
                this.setState({ deleteFlowOpen: false })
              }
              containedButton={t`Delete`}
              containedButtonFunction={() => this.deleteFlow(selectedFlow.id)}
              containedButtonLoading={showDeleteLoading}
              containedButtonDisabled={showDeleteLoading}
              isContainedButtonRed
            >
              {t`Be careful, this flow will be deleted permanently`}.
            </GenericDialog>
            <Menu
              anchorEl={anchorEl}
              open={anchorEl}
              onClose={() => this.handleMenuClose()}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  backgroundColor,
                },
              }}
              TransitionProps={{
                onEnter: () => {
                  if (window.cordova) {
                    this.setState({ backStackIndex: ++window.backStack });

                    document.addEventListener(
                      "backbutton",
                      this.handleBackButton
                    );
                  }
                },
              }}
            >
              <MenuItem
                style={{ color: textOnBackgroundColor }}
                onClick={() => {
                  this.handleMenuClose();

                  this.setState({
                    createFlowOpen: true,
                    createFlowMode: "edit",
                    intervalDuration: null,
                    intervalUnit: "minutes",
                    conditions: [{ localId: Date.now() }],
                    actions: [{ localId: Date.now() }],
                  });
                }}
              >
                <ListItemIcon>
                  <Create />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  {t`Edit`}
                </Typography>
              </MenuItem>
              <MenuItem
                style={{ color: textOnBackgroundColor }}
                onClick={() => {
                  this.handleMenuClose();

                  this.setState({ deleteFlowOpen: true });
                }}
              >
                <ListItemIcon>
                  <Delete style={{ color: errorColor }} />
                </ListItemIcon>
                <Typography
                  variant="inherit"
                  noWrap
                  style={{ color: errorColor }}
                >
                  {t`Delete`}
                </Typography>
              </MenuItem>
            </Menu>
            <FlowManifestDialog
              mode={createFlowMode}
              open={createFlowOpen}
              close={() => this.setState({ createFlowOpen: false })}
              thingId={thingData.thing?.id}
            />
          </>
        );
      }
    }
  )
);
