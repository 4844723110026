import {
  CenteredSpinner,
  debounce,
  ErrorScreen,
  getNotchHeight,
  isRoleAtLeast,
  theme,
} from "@igloocloud/igloosharedui";
import Add from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Star from "@mui/icons-material/Star";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import InputBase from "@mui/material/InputBase";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { adaptV4Theme, createTheme } from "@mui/material/styles";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Typography from "@mui/material/Typography";
import Zoom from "@mui/material/Zoom";
import withStyles from "@mui/styles/withStyles";
import querystringify from "querystringify";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import tinyColor from "tinycolor2";
import AddThing from "./AddThing";

let mergedArray = [];

const {
  REACT_APP_SECONDARY_BACKGROUND_COLOR: secondaryBackgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
  REACT_APP_TEXT_ON_SECONDARY_BACKGROUND_COLOR: textOnSecondaryBackgroundColor,
  REACT_APP_LIGHT_COLOR: lightColor,
  REACT_APP_MAIN_COLOR: mainColor,
  REACT_APP_SIDEBAR_FOLDERS_ENABLED: foldersEnabled,
  REACT_APP_TEXT_ON_MAIN_COLOR: textOnMainColor,
  REACT_APP_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS: lightContrastColor,
  REACT_APP_TEXT_ON_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS: textOnLightContrastColor,
} = process.env;

export default withTranslation()(
  withStyles((theme) => ({
    search: {
      position: "relative",
      borderRadius: 18,
      width: "calc(100% - 32px)",
      backgroundColor: secondaryBackgroundColor,
      "&:hover": {
        "&:!disabled": {
          backgroundColor: secondaryBackgroundColor,
        },
      },
    },
    mobileSearch: {
      position: "relative",
      borderRadius: 18,
      width: "calc(100% - 32px)",
      backgroundColor: tinyColor(textOnMainBackgroundColor)
        .setAlpha(0.05)
        .toRgbString(),
      "&:hover": {
        "&:!disabled": {
          backgroundColor: tinyColor(textOnMainBackgroundColor)
            .setAlpha(0.05)
            .toRgbString(),
        },
      },
    },
    searchIcon: {
      width: "48px",
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: textOnMainBackgroundColor,
      width: "100%",
    },
    input: {
      height: "100%",
      padding: "8px 8px 8px 48px",
      transition: theme.transitions.create("width"),
      color: textOnMainBackgroundColor,
    },
  }))(
    class Sidebar extends Component {
      state = {
        dialOpen: false,
        typeList: [],
        hidden: false,
        addThingOpen: false,
        lessThan1080: false,
        greaterThan560: false,
        fabWidth: null,
      };

      queryMore = async () => {
        if (
          !this.queryMore.locked &&
          this.props.collectionData.collection.thingCount > mergedArray.length
        ) {
          this.queryMore.locked = true;

          try {
            this.setState({ fetchMoreLoading: true });
            await this.props.collectionData.fetchMore({
              variables: {
                offset: this.props.collectionData.collection.things.length,
                limit:
                  this.props.collectionData.collection.thingCount -
                    this.props.collectionData.collection.things.length >=
                  20
                    ? 20
                    : this.props.collectionData.collection.thingCount % 20,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const newThings = [
                  ...prev.collection.things,
                  ...fetchMoreResult.collection.things,
                ].sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase()
                    ? 1
                    : a.name.toLowerCase() < b.name.toLowerCase()
                    ? -1
                    : 0
                );

                return {
                  collection: {
                    ...prev.collection,
                    things: newThings,
                  },
                };
              },
            });
          } finally {
            this.setState(() => {
              this.queryMore.locked = false;

              return { fetchMoreLoading: false };
            });
          }
        }
      };

      searchMore = async (searchText) => {
        if (
          !this.searchMore.locked &&
          this.props.collectionData.collection.thingCount > mergedArray.length
        ) {
          this.searchMore.locked = true;

          try {
            this.setState({ fetchMoreLoading: true });

            await this.props.collectionData.fetchMore({
              variables: {
                filter: { name: { regexMatch: "(?i)" + searchText } },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const newThings = [
                  ...prev.collection.things,
                  ...fetchMoreResult.collection.things,
                ];

                return {
                  collection: {
                    ...prev.collection,
                    things: newThings,
                  },
                };
              },
            });
          } finally {
            this.setState(() => {
              this.searchMore.locked = false;

              return { fetchMoreLoading: false };
            });
          }
        }
      };

      updateDimensions = () => {
        if (window.innerWidth < 1080) {
          this.setState({ lessThan1080: true });
        } else {
          this.setState({ lessThan1080: false });
        }

        if (window.innerWidth > 560) {
          !this.state.greaterThan560 && this.setState({ greaterThan560: true });
        } else {
          this.state.greaterThan560 && this.setState({ greaterThan560: false });
        }

        if (
          document.getElementById("thingFab") &&
          document.getElementById("thingFab").clientWidth !==
            this.props.fabWidth
        ) {
          this.props.setFabWidth(
            document.getElementById("thingFab").clientWidth
          );
        }
      };

      componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", debounce(this.updateDimensions));

        if (this.props.isMobile && window.cordova) {
          if (
            tinyColor(
              process.env.REACT_APP_MAIN_BACKGROUND_COLOR
            ).getBrightness() <= 128
          ) {
            window.StatusBar.styleLightContent();
          } else {
            window.StatusBar.styleDefault();
          }
        }
      }

      componentWillUnmount() {
        window.removeEventListener("resize", debounce(this.updateDimensions));
      }

      render() {
        const {
          collectionData: { error, loading, collection, refetch },
          classes,
          isMobile,
          t,
        } = this.props;
        const { fetchMoreLoading } = this.state;
        const thingsName = process.env.REACT_APP_THINGS_NAME || "Things";

        let sidebarContent = "";

        if (loading) {
          sidebarContent = (
            <CenteredSpinner
              style={{
                height: "calc(100% - 96px)",
                paddingTop: "32px",
              }}
            />
          );
        }

        if (error) {
          if (
            error.message === "GraphQL error: This ID is not valid" ||
            error.message ===
              "GraphQL error: The requested resource does not exist"
          ) {
            if (
              querystringify.parse(
                window.cordova && window.location.hash.split("?").length === 2
                  ? "?" + window.location.hash.split("?")[1]
                  : window.location.search
              ).thing
            ) {
              // if a thing is selected the sidebar keeps loading, waiting for the thing to redirect the user
              sidebarContent = (
                <CenteredSpinner
                  style={{
                    height: "calc(100% - 96px)",
                    paddingTop: "32px",
                  }}
                />
              );
            } else {
              // if there's no collection with the id in the url and no thing is selected, the user gets redirected
              return <Redirect to="/" />;
            }
          } else {
            sidebarContent = (
              <div>
                <ErrorScreen
                  refetch={() =>
                    refetch({
                      id: querystringify.parse(
                        window.cordova &&
                          window.location.hash.split("?").length === 2
                          ? "?" + window.location.hash.split("?")[1]
                          : window.location.search
                      ).collection,
                      offset: 0,
                      limit: 20,
                      filter: {},
                    })
                  }
                  error={error}
                />
              </div>
            );
          }
        }
        if (collection) {
          mergedArray = foldersEnabled
            ? collection.things
            : collection.starredThings.concat(collection.things);

          sidebarContent = (
            <StyledEngineProvider injectFirst>
              <ThemeProvider
                theme={theme(process.env, {
                  MuiListItem: {
                    secondaryAction: {
                      paddingLeft:
                        "calc(" + getNotchHeight("left") + " + 16px)",
                      paddingRight:
                        "calc(" + getNotchHeight("right") + " + 16px)",
                    },
                  },
                })}
              >
                {mergedArray.filter((thing) =>
                  this.props.searchText
                    ? thing.name
                        .toLowerCase()
                        .includes(this.props.searchText.toLowerCase())
                    : true
                ).length === 0 && !fetchMoreLoading ? (
                  <Typography
                    variant="h5"
                    className="notSelectable defaultCursor"
                    style={{
                      textAlign: "center",
                      marginTop: "32px",
                      marginBottom: "32px",
                      color: isMobile
                        ? textOnMainBackgroundColor
                        : textOnSecondaryBackgroundColor,
                    }}
                  >
                    {t("No " + thingsName.toLowerCase())}
                  </Typography>
                ) : (
                  <List
                    style={{
                      padding: "0",
                      height:
                        "calc(100% - 64px - " +
                        (isMobile ? getNotchHeight("top") : "0px") +
                        ")",
                      overflow: "auto",
                      overscrollBehaviorY: "contain",
                    }}
                    subheader={<li />}
                    onScroll={(event) => {
                      if (
                        event.target.scrollTop + event.target.clientHeight >=
                        event.target.scrollHeight - 600
                      )
                        this.queryMore();
                    }}
                  >
                    {mergedArray
                      .filter((thing) =>
                        this.props.searchText
                          ? thing.name
                              .toLowerCase()
                              .includes(this.props.searchText.toLowerCase())
                          : true
                      )
                      .map((thing) => (
                        <Link
                          to={
                            this.props.selectedThing !== thing.id
                              ? "/?collection=" +
                                this.props.selectedCollection +
                                "&thing=" +
                                thing.id
                              : "/?collection=" + this.props.selectedCollection
                          }
                          style={{
                            textDecoration: "none",
                            color: "unset",
                          }}
                        >
                          <ListItem
                            button
                            className="notSelectable"
                            selected={this.props.selectedThing === thing.id}
                            key={thing.id}
                            style={{
                              width: isMobile ? "100%" : "calc(100% - 16px)",
                              height: "56px",
                              margin: isMobile ? "0px" : "4px 8px",
                              borderRadius: isMobile ? "" : "8px",
                              color:
                                this.props.selectedThing === thing.id
                                  ? tinyColor(
                                      lightContrastColor || lightColor
                                    ).setAlpha(0.15)
                                  : null,
                              backgroundColor:
                                this.props.selectedThing === thing.id
                                  ? tinyColor(
                                      lightContrastColor || lightColor
                                    ).setAlpha(0.15)
                                  : null,
                            }}
                          >
                            {!foldersEnabled &&
                              (!mergedArray
                                .filter((thing) =>
                                  this.props.searchText
                                    ? thing.name
                                        .toLowerCase()
                                        .includes(
                                          this.props.searchText.toLowerCase()
                                        )
                                    : true
                                )
                                .filter(
                                  (collectionThing) => collectionThing.starred
                                )
                                .map((collectionThing) => collectionThing.id)
                                .indexOf(thing.id) ? (
                                <ListItemAvatar>
                                  <Avatar
                                    style={{
                                      backgroundColor: "transparent",
                                      color: lightContrastColor || lightColor,
                                    }}
                                  >
                                    <Star />
                                  </Avatar>
                                </ListItemAvatar>
                              ) : (
                                //checks if thing is the first to start with its initial
                                !mergedArray
                                  .filter(
                                    (collectionThing) =>
                                      !collectionThing.starred &&
                                      collectionThing.name
                                        .toLowerCase()
                                        .startsWith(thing.name[0].toLowerCase())
                                  )
                                  .filter((thing) =>
                                    this.props.searchText
                                      ? thing.name
                                          .toLowerCase()
                                          .includes(
                                            this.props.searchText.toLowerCase()
                                          )
                                      : true
                                  )
                                  .map((collectionThing) => collectionThing.id)
                                  .indexOf(thing.id) && (
                                  <ListItemAvatar>
                                    <Avatar
                                      style={{
                                        backgroundColor: "transparent",
                                        color: lightContrastColor || lightColor,
                                      }}
                                    >
                                      {thing.name[0].toUpperCase()}
                                    </Avatar>
                                  </ListItemAvatar>
                                )
                              ))}
                            <ListItemText
                              inset={
                                foldersEnabled
                                  ? thing.group
                                  : !mergedArray
                                      .filter(
                                        (collectionThing) =>
                                          !collectionThing.starred &&
                                          collectionThing.name
                                            .toLowerCase()
                                            .startsWith(
                                              thing.name[0].toLowerCase()
                                            )
                                      )
                                      .filter((thing) =>
                                        this.props.searchText
                                          ? thing.name
                                              .toLowerCase()
                                              .includes(
                                                this.props.searchText.toLowerCase()
                                              )
                                          : true
                                      )
                                      .map(
                                        (collectionThing) => collectionThing.id
                                      )
                                      .indexOf(thing.id)
                                  ? false
                                  : !!mergedArray
                                      .filter(
                                        (collectionThing) =>
                                          collectionThing.starred
                                      )
                                      .map(
                                        (collectionThing) => collectionThing.id
                                      )
                                      .indexOf(thing.id)
                              }
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                cursor: "pointer",
                              }}
                              primary={
                                <font
                                  style={{
                                    color:
                                      this.props.selectedThing === thing.id
                                        ? tinyColor(
                                            lightContrastColor || mainColor
                                          )
                                        : null,
                                  }}
                                >
                                  {thing.name}
                                </font>
                              }
                            />
                            <Link
                              to={
                                this.props.selectedThing !== thing.id
                                  ? "/?collection=" +
                                    this.props.selectedCollection +
                                    "&thing=" +
                                    thing.id
                                  : "/?collection=" +
                                    this.props.selectedCollection
                              }
                            >
                              <ListItemSecondaryAction
                                style={{ cursor: "pointer", height: "20px" }}
                              >
                                <StyledEngineProvider injectFirst>
                                  <ThemeProvider
                                    theme={createTheme(
                                      adaptV4Theme({
                                        overrides: {
                                          MuiBadge: {
                                            colorPrimary: {
                                              backgroundColor:
                                                lightContrastColor || mainColor,
                                            },
                                          },
                                        },
                                      })
                                    )}
                                  >
                                    <Badge
                                      badgeContent={
                                        <font
                                          style={{
                                            color:
                                              textOnLightContrastColor ||
                                              textOnMainColor,
                                          }}
                                        >
                                          {thing.notificationCount}
                                        </font>
                                      }
                                      invisible={!thing.notificationCount}
                                      color="primary"
                                      className="notSelectable"
                                      style={{
                                        marginTop: "-1px",
                                        marginRight: "24px",
                                      }}
                                    />
                                  </ThemeProvider>
                                </StyledEngineProvider>
                              </ListItemSecondaryAction>
                            </Link>
                          </ListItem>
                        </Link>
                      ))}
                  </List>
                )}
                {this.state.fetchMoreLoading && (
                  <LinearProgress
                    style={
                      this.props.isMobile
                        ? { position: "absolute", top: 0, width: "100%" }
                        : { marginTop: "-4px" }
                    }
                  />
                )}
              </ThemeProvider>
            </StyledEngineProvider>
          );
        }

        return (
          <>
            <div
              style={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  background: isMobile
                    ? process.env.REACT_APP_MAIN_BACKGROUND_COLOR
                    : "transparent",
                  height: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "64px",
                    paddingTop: this.props.isMobile ? getNotchHeight("top") : 0,
                    paddingLeft: getNotchHeight("left"),
                    paddingRight: this.props.isMobile
                      ? getNotchHeight("right")
                      : 0,
                  }}
                >
                  <div
                    className={isMobile ? classes.mobileSearch : classes.search}
                    style={{
                      width: "calc(100% - 32px)",
                      margin: "14.5px auto",
                      maxWidth: "352px",
                    }}
                  >
                    <div className={classes.searchIcon}>
                      <SearchIcon
                        style={{
                          color: tinyColor(textOnMainBackgroundColor)
                            .setAlpha(
                              !collection ||
                                (foldersEnabled
                                  ? collection.things &&
                                    !collection.things.length
                                  : collection.things &&
                                    collection.starredThings &&
                                    !collection.things.length &&
                                    !collection.starredThings.length)
                                ? 0.54
                                : 1
                            )
                            .toRgbString(),
                        }}
                      />
                    </div>
                    <InputBase
                      placeholder={t`Search`}
                      className="notSelectable"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.input,
                      }}
                      value={this.props.searchText}
                      onChange={(event) => {
                        const {
                          target: { value },
                        } = event;

                        this.props.searchThings(value);
                        this.searchMore(value);
                      }}
                      disabled={
                        !collection ||
                        (foldersEnabled
                          ? collection.things && !collection.things.length
                          : collection.things &&
                            collection.starredThings &&
                            !collection.things.length &&
                            !collection.starredThings.length)
                      }
                    />
                  </div>
                </div>
                {sidebarContent}
              </div>
            </div>
            {collection && (
              <>
                <Zoom
                  in={
                    this.props.userData.user &&
                    isRoleAtLeast(
                      this.props.collectionData.collection.myRole,
                      "ADMIN"
                    ) &&
                    (this.props.userData.user.emailIsVerified ||
                      mergedArray.length === 0)
                  }
                  onEntered={() =>
                    this.props.setFabWidth(
                      document.getElementById("thingFab").clientWidth
                    )
                  }
                >
                  <Fab
                    variant={
                      this.state.greaterThan560 && this.props.isMobile
                        ? "extended"
                        : "round"
                    }
                    id="thingFab"
                    color="secondary"
                    style={
                      this.props.isMobile
                        ? this.props.snackbarOpen
                          ? {
                              position: "absolute",
                              right:
                                "calc(16px + " + getNotchHeight("right") + ")",
                              bottom:
                                "calc(36px + env(safe-area-inset-bottom))",
                              transform: "translate3d(0, -64px, 0)",
                              zIndex: 1200,
                              transition:
                                "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, left 0s linear, right 0s linear, top 0s linear, bottom 0s linear",
                            }
                          : {
                              position: "absolute",
                              right:
                                "calc(16px + " + getNotchHeight("right") + ")",
                              bottom:
                                "calc(36px + env(safe-area-inset-bottom))",
                              zIndex: 1200,
                              transition:
                                "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, left 0s linear, right 0s linear, top 0s linear, bottom 0s linear",
                            }
                        : this.state.lessThan1080
                        ? {
                            position: "absolute",
                            left: "calc(max(33vw, 300px) - 72px)",
                            bottom: "calc(16px + env(safe-area-inset-bottom))",
                            zIndex: 1200,
                            transition:
                              "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, left 0s linear, right 0s linear, top 0s linear, bottom 0s linear",
                          }
                        : {
                            position: "absolute",
                            left: "288px",
                            bottom: "calc(16px + env(safe-area-inset-bottom))",
                            zIndex: 1200,
                            transition:
                              "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, left 0s linear, right 0s linear, top 0s linear, bottom 0s linear",
                          }
                    }
                    onClick={() => this.setState({ addThingOpen: true })}
                  >
                    <Add
                      style={
                        this.state.greaterThan560 && this.props.isMobile
                          ? {
                              marginRight: "8px",
                            }
                          : {}
                      }
                    />
                    {this.state.greaterThan560 &&
                      this.props.isMobile &&
                      t`Thing`}
                  </Fab>
                </Zoom>
                <AddThing
                  open={this.state.addThingOpen}
                  close={() => this.setState({ addThingOpen: false })}
                  userData={this.props.userData}
                  collection={collection.id}
                  client={this.props.client}
                />
              </>
            )}
            <Divider orientation="vertical" />
          </>
        );
      }
    }
  )
);
