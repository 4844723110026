import React, { Component } from "react";
import { GenericDialog, isEmailValid } from "@igloocloud/igloosharedui";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Clear from "@mui/icons-material/Clear";
import gql from "graphql-tag";
import { withTranslation } from "react-i18next";

const {
  REACT_APP_COLLECTION_NAME: collectionName,
  REACT_APP_OWNER_NAME: ownerName,
} = process.env;

export default withTranslation()(
  class ChangeOwner extends Component {
    state = { email: "", emailEmpty: false };

    changeOwner = async () => {
      const { t } = this.props;
      const { email } = this.state;

      if (isEmailValid(email)) {
        try {
          this.setState({ showLoading: true });

          await this.props.client.mutate({
            mutation: gql`
              mutation ($collectionId: ID!, $email: String!) {
                transferCollection(collectionId: $collectionId, email: $email) {
                  id
                }
              }
            `,
            variables: {
              collectionId: this.props.collectionId,
              email: this.state.email,
            },
          });

          this.props.close();
        } catch (e) {
          if (
            e.message ===
            "GraphQL error: This account doesn't exist, check the email passed"
          ) {
            this.setState({ emailError: t`This account doesn't exist` });
          } else if (
            e.message ===
            "GraphQL error: You already are the owner of this collection"
          ) {
            this.setState({
              emailError: t`This is you`,
            });
          } else if (
            e.message ===
            "GraphQL error: The user already has a role on this collection"
          ) {
            this.setState({
              emailError: t(
                (collectionName || "Collection") + " already shared"
              ),
            });
          } else if (
            e.message === "GraphQL error: There is already a pending transfer"
          ) {
            this.setState({
              emailError: t`This user already has a pending transfer`,
            });
          } else {
            this.setState({
              emailError: t`Error`,
            });
          }
        }

        this.setState({ showLoading: false });
      }
    };

    componentWillReceiveProps(nextProps) {
      if (this.props.open !== nextProps.open && nextProps.open) {
        this.setState({
          emailEmpty: false,
          emailError: false,
          email: "",
        });
      }
    }

    render() {
      const { open, close, t } = this.props;
      const { showLoading, email, emailError, emailEmpty } = this.state;

      return (
        <GenericDialog
          open={open}
          close={close}
          title={t("Change " + ownerName)}
          textButton={t`Close`}
          textButtonFunction={close}
          containedButton={t("Change " + (ownerName.toLowerCase() || "owner"))}
          containedButtonFunction={this.changeOwner}
          containedButtonLoading={showLoading}
          containedButtonDisabled={
            !email.replace(/\s/g, "").length ||
            emailError ||
            showLoading ||
            (!isEmailValid(email) && email)
          }
        >
          <TextField
            id="owner-email"
            label={t(`New ${ownerName.toLowerCase() || "owner"} email`)}
            type="email"
            value={email}
            variant="outlined"
            error={emailEmpty || emailError || (!isEmailValid(email) && email)}
            helperText={
              emailEmpty
                ? t`This field is required`
                : !isEmailValid(email) && email
                ? t`Enter a valid email`
                : emailError || " "
            }
            onChange={(event) =>
              this.setState({
                email: event.target.value,
                emailEmpty: event.target.value.replace(/\s/g, "") === "",
                emailError: "",
              })
            }
            onKeyPress={(event) => {
              if (event.key === "Enter" && !emailEmpty) this.changeOwner();
            }}
            style={{
              width: "100%",
            }}
            InputLabelProps={email ? { shrink: true } : {}}
            InputProps={{
              endAdornment: email && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      this.setState({
                        email: "",
                        emailEmpty: true,
                        emailError: "",
                      })
                    }
                    tabIndex="-1"
                    size="large"
                  >
                    <Clear />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </GenericDialog>
      );
    }
  }
);
