import React, { Component } from "react"
import { graphql } from "@apollo/react-hoc"
import gql from "graphql-tag"
import { GenericDialog } from "@igloocloud/igloosharedui"
import { withTranslation } from "react-i18next"

export default graphql(
  gql`
    mutation RevokeInvite($id: ID!) {
      revokePendingShare(id: $id)
    }
  `,
  {
    name: "RevokeInvite",
  }
)(
  withTranslation()(
    class RevokeInvite extends Component {
      constructor(props) {
        super(props)

        this.state = { showLoading: false }
      }

      revokeInvite = async () => {
        this.setState({ showLoading: true })

        await this.props.RevokeInvite({
          variables: {
            id: this.props.menuTarget.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            revokePendingShare: {
              id: this.props.menuTarget.id,
              __typename: "PendingShare",
            },
          },
        })

        this.setState({ showLoading: false })

        this.props.close()
      }

      render() {
        const { t } = this.props

        return (
          <GenericDialog
            open={this.props.open}
            close={this.props.close}
            title={t`Revoke invite`}
            textButton={t`Close`}
            textButtonFunction={this.props.close}
            isContainedButtonRed
            containedButton={t`Revoke`}
            containedButtonFunction={this.revokeInvite}
            containedButtonLoading={this.state.showLoading}
            containedButtonDisabled={this.state.showLoading}
          >
            {t`Are you sure you want to revoke your invite to ` +
              (this.props.menuTarget &&
                this.props.menuTarget.recipient &&
                this.props.menuTarget.recipient.name) +
              "?"}
          </GenericDialog>
        )
      }
    }
  )
)
