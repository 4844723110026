import React, { useState } from "react"
import { GenericDialog, getNotchHeight } from "@igloocloud/igloosharedui"
import Igloo from "@igloocloud/igloo.js"
import { svg2png } from "svg-png-converter"
import CircularProgress from "@mui/material/CircularProgress"
import SvgIcon from "@mui/material/SvgIcon"
import clipboardCopy from "clipboard-copy"
import Zoom from "@mui/material/Zoom"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import Switch from "@mui/material/Switch"
import StyledEngineProvider from "@mui/material/StyledEngineProvider"
import ThemeProvider from "@mui/material/styles/ThemeProvider"
import { createTheme, adaptV4Theme } from "@mui/material/styles"
import { downloadText } from "download.js"
import { withTranslation } from "react-i18next"

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const unpairThingMutation = async (
  setUnpairThingOpen,
  deselectThing,
  close,
  id,
  client,
  setUnpairThingLoading,
  reset
) => {
  try {
    setUnpairThingLoading(true)

    await client.mutation.unpairThing({ id, reset })

    deselectThing()
    setUnpairThingOpen(false)
    close()
  } catch (e) {
    setUnpairThingLoading(false)
  }
}

export default withTranslation()(({ open, close, thing, deselectThing, t }) => {
  const [unpairThingOpen, setUnpairThingOpen] = useState(false)
  const [unpairThingLoading, setUnpairThingLoading] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [showCopy, setShowCopy] = useState(true)
  const [copyRan, setCopyRan] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [showDownload, setShowDownload] = useState(true)
  const [downloadRan, setDownloadRan] = useState(false)
  const [reset, setReset] = useState(true)

  const {
    REACT_APP_THING_NAME: thingName,
    REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
    REACT_APP_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS: lightContrastColor,
    REACT_APP_MAIN_COLOR: mainColor,
  } = process.env

  const client = new Igloo(
    localStorage.getItem("accountList") &&
    localStorage.getItem("userId") &&
    JSON.parse(localStorage.getItem("accountList")).filter(
      (account) => account.id === localStorage.getItem("userId")
    )[0]
      ? JSON.parse(localStorage.getItem("accountList")).filter(
          (account) => account.id === localStorage.getItem("userId")
        )[0].token
      : "",
    localStorage.getItem("server")
      ? (localStorage.getItem("serverUnsecure") === "true"
          ? "http://"
          : "https://") +
        localStorage.getItem("server") +
        "/graphql"
      : `https://v1.igloo.ooo/graphql`
  )

  return (
    <>
      <GenericDialog
        open={open}
        hidden={unpairThingOpen}
        close={close}
        title={t`Back up code` + (window.cordova ? "" : " " + t`and QR`)}
        textButton={t`Close`}
        textButtonFunction={() => {
          close()
          setUnpairThingOpen(false)
        }}
        containedButton={t`Next`}
        containedButtonFunction={() => {
          setReset(true)
          setUnpairThingOpen(true)
        }}
        noHorizontalPadding
      >
        {({ fullScreen }) => (
          <>
            <div
              style={{
                paddingLeft:
                  "calc(24px + " +
                  (fullScreen ? getNotchHeight("left") : "0px") +
                  ")",
                paddingRight:
                  "calc(24px + " +
                  (fullScreen ? getNotchHeight("right") : "0px") +
                  ")",
              }}
            >
              {t(
                `You may want to save this ${
                  thingName ? thingName.toLowerCase() : "thing"
                }'s code`
              ) +
                " " +
                (window.cordova ? "" : t`and QR` + " ") +
                t`before you unpair it, so here` +
                " " +
                (window.cordova ? t`it is` : t`they are`)}
              !
            </div>
            <div
              style={{
                marginTop: "16px",
                marginBottom: "8px",
                textAlign: "center",
                width: "100%",
              }}
            >
              <List>
                <ListItem
                  button
                  onClick={async () => {
                    if (thing) {
                      if (window.cordova) {
                        window.cordova.plugins.clipboard.copy(thing.pairCode)
                      } else {
                        clipboardCopy(thing.pairCode)
                      }

                      //this way the zoom animation isn't shown when the dialog opens
                      setCopyRan(true)

                      setShowCopy(false)
                      setShowConfirm(true)
                      await sleep(1000)
                      setShowConfirm(false)
                      setShowCopy(true)
                    }
                  }}
                  style={{
                    paddingLeft:
                      "calc(24px + " +
                      (fullScreen ? getNotchHeight("left") : "0px") +
                      ")",
                    paddingRight:
                      "calc(24px + " +
                      (fullScreen ? getNotchHeight("right") : "0px") +
                      ")",
                  }}
                >
                  <ListItemIcon>
                    {showConfirm && (
                      <Zoom in={showConfirm}>
                        <SvgIcon style={{ margin: "0 8px" }}>
                          <svg
                            style={{
                              width: "24px",
                              height: "24px",
                              color: textColor,
                            }}
                            viewBox="0 0 24 24"
                          >
                            <path d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
                          </svg>
                        </SvgIcon>
                      </Zoom>
                    )}
                    {showCopy && (
                      <Zoom
                        in={showCopy}
                        timeout={
                          copyRan
                            ? { enter: 225, exit: 255 }
                            : { enter: 0, exit: 0 }
                        }
                      >
                        <SvgIcon style={{ margin: "0 8px" }}>
                          <svg
                            style={{
                              width: "24px",
                              height: "24px",
                              color: textColor,
                            }}
                            viewBox="0 0 24 24"
                          >
                            <path d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                          </svg>
                        </SvgIcon>
                      </Zoom>
                    )}
                  </ListItemIcon>
                  <ListItemText
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    {t`Copy code`}
                  </ListItemText>
                </ListItem>
                {!window.cordova && (
                  <ListItem
                    button
                    onClick={async () => {
                      //this way the zoom animation isn't shown when the dialog opens
                      setDownloadRan(true)
                      setShowDownload(false)
                      setShowLoading(true)

                      await downloadText(
                        process.env.REACT_APP_NAME +
                          " - " +
                          thing.name +
                          ".jpeg",
                        await svg2png({
                          input: (
                            await client.query.thing(thing.id).qrCode
                          ).trim(),
                          encoding: "buffer",
                          format: "jpeg",
                        })
                      )

                      await sleep(1000)

                      setShowLoading(false)
                      setShowDownload(true)
                    }}
                    style={{
                      paddingLeft:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("left") : "0px") +
                        ")",
                      paddingRight:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("right") : "0px") +
                        ")",
                    }}
                  >
                    <ListItemIcon>
                      {showLoading && (
                        <CircularProgress
                          size={24}
                          color="primary"
                          style={{
                            position: "absolute",
                            top: "50%",
                            marginTop: -12,
                            marginLeft: "8px",
                          }}
                        />
                      )}
                      {showDownload && (
                        <Zoom
                          in={showDownload}
                          timeout={
                            downloadRan
                              ? { enter: 225, exit: 255 }
                              : { enter: 0, exit: 0 }
                          }
                        >
                          <SvgIcon style={{ margin: "0 8px" }}>
                            <svg
                              style={{
                                width: "24px",
                                height: "24px",
                                color: textColor,
                              }}
                              viewBox="0 0 24 24"
                            >
                              <g>
                                <rect fill="none" height="24" width="24" />
                              </g>
                              <g>
                                <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
                              </g>
                            </svg>
                          </SvgIcon>
                        </Zoom>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {t`Download QR code`}
                    </ListItemText>
                  </ListItem>
                )}
              </List>
            </div>
          </>
        )}
      </GenericDialog>
      <GenericDialog
        open={unpairThingOpen}
        close={() => setUnpairThingOpen(false)}
        title={t`Unpair thing`}
        textButton={t`Close`}
        textButtonFunction={() => setUnpairThingOpen(false)}
        containedButton={t`Unpair`}
        containedButtonFunction={() =>
          unpairThingMutation(
            setUnpairThingOpen,
            deselectThing,
            close,
            thing.id,
            client,
            setUnpairThingLoading,
            reset
          )
        }
        containedButtonLoading={unpairThingLoading}
        containedButtonDisabled={unpairThingLoading}
        isContainedButtonRed
      >
        {t`Be careful, ` + thing?.name + " " + t`will be unpaired` + "."}
        <List
          style={{
            padding: 0,
            marginTop: "16px",
          }}
        >
          <ListItem
            style={{
              marginTop: "-3px",
              marginBottom: "13px",
              paddingLeft: 0,
            }}
          >
            <ListItemText primary={t`Reset thing`} />
            <ListItemSecondaryAction style={{ right: "-8px" }}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider
                  theme={createTheme(
                    adaptV4Theme({
                      palette: {
                        primary: {
                          main: lightContrastColor || mainColor,
                        },
                      },
                    })
                  )}
                >
                  <Switch
                    checked={reset}
                    onChange={(event) => setReset(event.target.checked)}
                  />
                </ThemeProvider>
              </StyledEngineProvider>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </GenericDialog>
    </>
  )
})
