import { getNotchHeight, isRoleAtLeast } from "@igloocloud/igloosharedui";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import tinyColor from "tinycolor2";

export default class SidebarHeader extends Component {
  state = {
    goToCollections: false,
  };

  componentDidMount() {
    if (!this.props.mobile && window.cordova) {
      if (tinyColor(process.env.REACT_APP_MAIN_COLOR).getBrightness() <= 128) {
        window.StatusBar.styleLightContent();
      } else {
        window.StatusBar.styleDefault();
      }
    }
  }

  render() {
    const { mobile, collectionName, myRole, fabWidth, emailIsVerified } =
      this.props;
    const {
      REACT_APP_MAIN_COLOR: mainColor,
      REACT_APP_LIGHT_COLOR: lightColor,
      REACT_APP_TEXT_ON_MAIN_COLOR: color,
    } = process.env;

    return (
      <div
        className="sidebarHeader notSelectable"
        style={{
          color,
          display: "flex",
          alignItems: "center",
          gridArea: "sidebarHeader",
          backgroundColor: mainColor,
          zIndex: 1000,
          maxWidth: "100vw",
          height: "64px",
          paddingTop: mobile ? 0 : getNotchHeight("top"),
          paddingLeft: getNotchHeight("left"),
          paddingRight: getNotchHeight("right"),
          paddingBottom: mobile ? "env(safe-area-inset-bottom)" : 0,
        }}
      >
        <Link
          to=""
          style={{
            textDecoration: "none",
            color: "unset",
          }}
        >
          <IconButton
            style={{
              color,
              marginLeft: "8px",
            }}
            onClick={() => this.setState({ goToCollections: true })}
            size="large"
          >
            <ArrowBack />
          </IconButton>
        </Link>
        <Typography
          variant="h5"
          style={{
            cursor: "default",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            color,
            lineHeight: "64px",
            marginLeft: "8px",
            marginRight: "8px",
            width: mobile
              ? this.props.fabWidth
                ? "calc(100% - " + (this.props.fabWidth + 80) + "px)"
                : "calc(100% - 136px)"
              : "calc(100% - 64px)",
          }}
        >
          {
            (!emailIsVerified || !isRoleAtLeast(myRole, "ADMIN") || fabWidth) &&
              collectionName //waits for fab to appear
          }
        </Typography>
        {lightColor === mainColor && !mobile && (
          <div
            style={{
              width: "2px",
              height: "48px",
              backgroundColor: tinyColor(color).setAlpha(0.25).toRgbString(),
              borderRadius: "1px",
            }}
          />
        )}
        {this.state.goToCollections && <Redirect push to="/" />}
      </div>
    );
  }
}
