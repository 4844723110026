import React, { Component } from "react";
import IconButton from "@mui/material/IconButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import MenuOpen from "@mui/icons-material/MenuOpen";
import moment from "moment";
import Papa from "papaparse";
import gql from "graphql-tag";
import { graphql } from "@apollo/react-hoc";
import {
  debounce,
  isNullish,
  CenteredSpinner,
  ErrorScreen,
} from "@igloocloud/igloosharedui";
import { withRouter } from "react-router";
import DatePicker from "./components/data/DatePicker";
import SeriesList from "./components/data/SeriesList";
import Plot from "./components/data/Plot";

const isJSON = (text) =>
  /^[\],:{}\s]*$/.test(
    text
      // eslint-disable-next-line
      .replace(/\\["\\\/bfnrtu]/g, "@")
      .replace(
        // eslint-disable-next-line
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        "]"
      )
      .replace(/(?:^|:|,)(?:\s*\[)+/g, "")
  );

export default withRouter(
  graphql(
    gql`
      query ($offset: NaturalNumber, $limit: NaturalNumber!) {
        user {
          id
          floatSeriesVariableCount
          floatSeriesVariables(
            limit: $limit
            offset: $offset
            bannedVisualizations: "windDirection"
          ) {
            id
            name
            unitOfMeasurement
            nodeCount
            thing {
              id
              name
            }
          }
        }
      }
    `,
    {
      name: "seriesData",
      options: () => ({
        variables: {
          offset: 0,
          limit: 20,
        },
      }),
    }
  )(
    class DataLab extends Component {
      state = {
        selectedVariables:
          localStorage.getItem("dataLabDates") &&
          isJSON(localStorage.getItem("dataLabDates")) &&
          JSON.parse(localStorage.getItem("dataLabDates"))[0] &&
          JSON.parse(localStorage.getItem("dataLabDates")).find(
            ({ id }) => id === localStorage.getItem("userId")
          )?.selectedVariables
            ? JSON.parse(localStorage.getItem("dataLabDates")).find(
                ({ id }) => id === localStorage.getItem("userId")
              ).selectedVariables
            : [],
        allSeries: [],
        startDate:
          localStorage.getItem("dataLabDates") &&
          isJSON(localStorage.getItem("dataLabDates")) &&
          JSON.parse(localStorage.getItem("dataLabDates"))[0] &&
          JSON.parse(localStorage.getItem("dataLabDates")).find(
            ({ id }) => id === localStorage.getItem("userId")
          )?.startDate
            ? new Date(
                JSON.parse(localStorage.getItem("dataLabDates")).find(
                  ({ id }) => id === localStorage.getItem("userId")
                ).startDate
              )
            : moment().subtract(1, "week"),
        endDate:
          localStorage.getItem("dataLabDates") &&
          isJSON(localStorage.getItem("dataLabDates")) &&
          JSON.parse(localStorage.getItem("dataLabDates"))[0] &&
          JSON.parse(localStorage.getItem("dataLabDates")).find(
            ({ id }) => id === localStorage.getItem("userId")
          )?.endDate
            ? new Date(
                JSON.parse(localStorage.getItem("dataLabDates")).find(
                  ({ id }) => id === localStorage.getItem("userId")
                ).endDate
              )
            : new Date(),
        expandedThings: [],
        drawerOpen: false,
        lessThan900: null,
        loadingVariables: [],
        enableAnimation: true,
      };

      refetchSeries = async (startDate, endDate, allSeries) => {
        this.setState({ enableAnimation: false });

        for (const { id } of allSeries) {
          await this.fetchSeries(id, startDate, endDate, allSeries);
        }

        this.setState({ enableAnimation: true });
      };

      fetchSeries = async (id, startDate, endDate, allSeries) => {
        const { expandedThings } = this.state;

        try {
          if (
            allSeries.find(({ id: idToFind }) => idToFind === id)
              ?.fetchedIntervals &&
            +endDate <=
              allSeries.find(({ id: idToFind }) => idToFind === id)
                .fetchedIntervals[1] &&
            +startDate >=
              allSeries.find(({ id: idToFind }) => idToFind === id)
                .fetchedIntervals[0]
          ) {
            return;
          }

          this.setState(({ loadingVariables }) => ({
            loadingVariables: [...loadingVariables, id],
          }));

          let newStartDate, newEndDate;

          if (
            allSeries.find(({ id: idToFind }) => idToFind === id)
              ?.fetchedIntervals &&
            +endDate <
              allSeries.find(({ id: idToFind }) => idToFind === id)
                .fetchedIntervals[1]
          ) {
            newStartDate = endDate;
          }

          if (
            allSeries.find(({ id: idToFind }) => idToFind === id)
              ?.fetchedIntervals &&
            +startDate >
              allSeries.find(({ id: idToFind }) => idToFind === id)
                .fetchedIntervals[0]
          ) {
            newEndDate = startDate;
          }

          const {
            data: { floatSeriesVariable: floatSeriesVariableWithNodeCounts },
          } = await this.props.client.query({
            query: gql`
              query (
                $id: ID!
                $startTimestamp: DateTime
                $endTimestamp: DateTime
                $now: DateTime
              ) {
                floatSeriesVariable(id: $id) {
                  id
                  name
                  unitOfMeasurement
                  precision
                  visualization
                  thing {
                    id
                  }
                  limitNodeCount: nodeCount(
                    filter: {
                      startTimestamp: $startTimestamp
                      endTimestamp: $endTimestamp
                    }
                  )
                  offsetNodeCount: nodeCount(
                    filter: {
                      startTimestamp: $endTimestamp
                      endTimestamp: $now
                    }
                  )
                }
              }
            `,
            variables: {
              id,
              startTimestamp: newStartDate || startDate,
              endTimestamp: newEndDate || endDate,
              now: Date.now(),
            },
          });

          if (
            floatSeriesVariableWithNodeCounts.thing.id &&
            !expandedThings.includes(floatSeriesVariableWithNodeCounts.thing.id)
          ) {
            this.setState(({ expandedThings }) => ({
              expandedThings: [
                ...expandedThings,
                floatSeriesVariableWithNodeCounts.thing.id,
              ],
            }));
          }

          if (floatSeriesVariableWithNodeCounts.limitNodeCount < 500) {
            const {
              data: { floatSeriesVariable },
            } = await this.props.client.query({
              query: gql`
                query (
                  $id: ID!
                  $limit: NaturalNumber!
                  $offset: NaturalNumber
                ) {
                  floatSeriesVariable(id: $id) {
                    id
                    name
                    unitOfMeasurement
                    precision
                    visualization
                    thing {
                      id
                    }
                    nodes(limit: $limit, offset: $offset) {
                      id
                      value
                      timestamp
                    }
                  }
                }
              `,
              variables: {
                id,
                limit: floatSeriesVariableWithNodeCounts.limitNodeCount,
                offset: floatSeriesVariableWithNodeCounts.offsetNodeCount,
              },
            });

            this.setState(({ allSeries, loadingVariables }) => ({
              allSeries: [
                ...allSeries.filter(
                  ({ id: idToRemove }) => idToRemove !== floatSeriesVariable.id
                ),
                {
                  ...floatSeriesVariable,
                  nodes: [
                    ...(allSeries.find(
                      ({ id: idToFind }) => idToFind === floatSeriesVariable.id
                    )?.nodes || []),
                    ...floatSeriesVariable.nodes,
                  ].sort((a, b) =>
                    +new Date(a.timestamp) < +new Date(b.timestamp)
                      ? 1
                      : +new Date(a.timestamp) > +new Date(b.timestamp)
                      ? -1
                      : 0
                  ),
                  fetchedIntervals: [
                    allSeries.find(
                      ({ id: idToFind }) => idToFind === floatSeriesVariable.id
                    )?.fetchedIntervals
                      ? Math.min(
                          +allSeries.find(
                            ({ id: idToFind }) =>
                              idToFind === floatSeriesVariable.id
                          )?.fetchedIntervals[0],
                          +startDate
                        )
                      : +startDate,
                    allSeries.find(
                      ({ id: idToFind }) => idToFind === floatSeriesVariable.id
                    )?.fetchedIntervals
                      ? Math.max(
                          +allSeries.find(
                            ({ id: idToFind }) =>
                              idToFind === floatSeriesVariable.id
                          )?.fetchedIntervals[1],
                          +endDate
                        )
                      : +endDate,
                  ],
                },
              ],
              loadingVariables: loadingVariables.filter(
                (variableId) => variableId !== id
              ),
            }));
          } else {
            let response = await fetch(
              `https://csv.igloo.ooo/${id}?token=${
                localStorage.getItem("accountList") &&
                localStorage.getItem("userId") &&
                JSON.parse(localStorage.getItem("accountList")).filter(
                  (account) => account.id === localStorage.getItem("userId")
                )[0]
                  ? JSON.parse(localStorage.getItem("accountList")).filter(
                      (account) => account.id === localStorage.getItem("userId")
                    )[0].token
                  : ""
              }&limit=${
                floatSeriesVariableWithNodeCounts.limitNodeCount
              }&offset=${floatSeriesVariableWithNodeCounts.offsetNodeCount}`
            );

            response.text().then((response) => {
              this.setState(({ allSeries, loadingVariables }) => ({
                allSeries: [
                  ...allSeries.filter(
                    ({ id: idToRemove }) =>
                      idToRemove !== floatSeriesVariableWithNodeCounts.id
                  ),
                  {
                    ...floatSeriesVariableWithNodeCounts,
                    nodeCount: floatSeriesVariableWithNodeCounts.limitNodeCount,
                    nodes: [
                      ...(allSeries.find(
                        ({ id: idToFind }) =>
                          idToFind === floatSeriesVariableWithNodeCounts.id
                      )?.nodes || []),
                      ...Papa.parse(response)
                        .data.splice(1)
                        .map(([value, timestamp]) => ({
                          value: value === "null" ? null : parseFloat(value),
                          timestamp,
                        }))
                        .reverse(),
                    ].sort((a, b) =>
                      +new Date(a.timestamp) < +new Date(b.timestamp)
                        ? 1
                        : +new Date(a.timestamp) > +new Date(b.timestamp)
                        ? -1
                        : 0
                    ),
                    fetchedIntervals: [
                      allSeries.find(
                        ({ id: idToFind }) =>
                          idToFind === floatSeriesVariableWithNodeCounts.id
                      )?.fetchedIntervals
                        ? Math.min(
                            +allSeries.find(
                              ({ id: idToFind }) =>
                                idToFind ===
                                floatSeriesVariableWithNodeCounts.id
                            )?.fetchedIntervals[0],
                            +startDate
                          )
                        : +startDate,
                      allSeries.find(
                        ({ id: idToFind }) =>
                          idToFind === floatSeriesVariableWithNodeCounts.id
                      )?.fetchedIntervals
                        ? Math.max(
                            +allSeries.find(
                              ({ id: idToFind }) =>
                                idToFind ===
                                floatSeriesVariableWithNodeCounts.id
                            )?.fetchedIntervals[1],
                            +endDate
                          )
                        : +endDate,
                    ],
                  },
                ],
                loadingVariables: loadingVariables.filter(
                  (variableId) => variableId !== id
                ),
              }));
            });
          }
        } catch (e) {
          this.setState(({ selectedVariables }) => ({
            selectedVariables: selectedVariables.filter(
              (variableId) => variableId !== id
            ),
          }));
        }
      };

      updateDimensions = () => {
        if (window.innerWidth < 900) {
          this.setState({ lessThan900: true });
        } else {
          this.setState({ lessThan900: false });
        }
      };

      componentDidMount() {
        const { selectedVariables, allSeries, startDate, endDate } = this.state;

        this.updateDimensions();
        window.addEventListener("resize", debounce(this.updateDimensions));

        if (
          selectedVariables.some(
            (variableId) =>
              !allSeries.find((series) => series.id === variableId)
          ) &&
          window.location.pathname === "/data"
        ) {
          selectedVariables.forEach((variable) =>
            this.fetchSeries(variable, startDate, endDate, allSeries)
          );
        }

        this.unlisten = this.props.history.listen((location) => {
          //get selectedVariables and allSeries from state to prevent outdated values being read
          if (
            this.state.selectedVariables.some(
              (variableId) =>
                !this.state.allSeries.find((series) => series.id === variableId)
            ) &&
            location.pathname === "/data"
          ) {
            this.state.selectedVariables.forEach((variable) =>
              this.fetchSeries(variable, startDate, endDate, allSeries)
            );
          }
        });

        this.props.seriesData.subscribeToMore({
          document: gql`
            subscription {
              floatSeriesVariableCreated {
                id
                name
                unitOfMeasurement
                nodeCount
                thing {
                  id
                  name
                }
              }
            }
          `,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
              return prev;
            }

            const newSeries = [
              ...prev.user.floatSeriesVariables,
              subscriptionData.data.floatSeriesVariableCreated,
            ].sort((a, b) =>
              a.thing.name.toLowerCase() > b.thing.name.toLowerCase()
                ? 1
                : a.thing.name.toLowerCase() < b.thing.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : 0
            );

            return {
              user: {
                ...prev.user,
                floatSeriesVariables: newSeries,
              },
            };
          },
        });

        this.props.seriesData.subscribeToMore({
          document: gql`
            subscription {
              floatSeriesVariableUpdated {
                id
                name
                unitOfMeasurement
                nodeCount
                thing {
                  id
                  name
                }
              }
            }
          `,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
              return prev;
            }

            const newSeries = [
              ...prev.user.floatSeriesVariables.filter(
                ({ id: idToRemove }) =>
                  idToRemove !==
                  subscriptionData.data.floatSeriesVariableUpdated.id
              ),
              subscriptionData.data.floatSeriesVariableUpdated,
            ].sort((a, b) =>
              a.thing.name.toLowerCase() > b.thing.name.toLowerCase()
                ? 1
                : a.thing.name.toLowerCase() < b.thing.name.toLowerCase()
                ? -1
                : a.name.toLowerCase() > b.name.toLowerCase()
                ? 1
                : a.name.toLowerCase() < b.name.toLowerCase()
                ? -1
                : 0
            );

            return {
              user: {
                ...prev.user,
                floatSeriesVariables: newSeries,
              },
            };
          },
        });

        this.props.seriesData.subscribeToMore({
          document: gql`
            subscription {
              floatSeriesVariableDeleted
            }
          `,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
              return prev;
            }

            const newSeries = prev.user.floatSeriesVariables.filter(
              (series) =>
                series.id !== subscriptionData.data.floatSeriesVariableDeleted
            );

            return {
              user: {
                ...prev.user,
                floatSeriesVariables: newSeries,
              },
            };
          },
        });

        this.props.seriesData.subscribeToMore({
          document: gql`
            subscription {
              thingUpdated {
                id
                name
              }
            }
          `,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
              return prev;
            }

            let newSeries = prev.user.floatSeriesVariables;

            newSeries.forEach((series) => {
              if (series.thing.id === subscriptionData.data.thingUpdated.id) {
                series.thing.name = subscriptionData.data.thingUpdated.name;
              }
            });

            return {
              user: {
                ...prev.user,
                floatSeriesVariables: newSeries.sort((a, b) =>
                  a.thing.name.toLowerCase() > b.thing.name.toLowerCase()
                    ? 1
                    : a.thing.name.toLowerCase() < b.thing.name.toLowerCase()
                    ? -1
                    : a.name.toLowerCase() > b.name.toLowerCase()
                    ? 1
                    : a.name.toLowerCase() < b.name.toLowerCase()
                    ? -1
                    : 0
                ),
              },
            };
          },
        });
      }

      componentWillUnmount() {
        window.removeEventListener("resize", debounce(this.updateDimensions));

        this.unlisten();
      }

      componentWillReceiveProps(nextProps) {
        if (!this.props.seriesData.user && nextProps.seriesData.user) {
          this.setState(({ expandedThings }) => ({
            expandedThings: [
              ...expandedThings,
              (localStorage.getItem("dataLabDates") &&
              isJSON(localStorage.getItem("dataLabDates")) &&
              JSON.parse(localStorage.getItem("dataLabDates"))[0] &&
              JSON.parse(localStorage.getItem("dataLabDates")).find(
                ({ id }) => id === localStorage.getItem("userId")
              )?.selectedVariables
                ? JSON.parse(localStorage.getItem("dataLabDates")).find(
                    ({ id }) => id === localStorage.getItem("userId")
                  ).selectedVariables
                : []
              ).map(
                (variable) =>
                  nextProps.seriesData.user.floatSeriesVariables.find(
                    ({ id: idToFind }) => idToFind === variable
                  )?.thing.id
              ),
            ],
          }));
        }
      }

      render() {
        const {
          userData: {
            error: userError,
            loading: userLoading,
            user,
            refetch: userRefetch,
          },
          seriesData: {
            error: seriesError,
            loading: seriesLoading,
            user: seriesUser,
            refetch: seriesRefetch,
          },
          mobile,
          setSnackbarOpen,
        } = this.props;
        const {
          selectedVariables,
          allSeries,
          startDate,
          endDate,
          expandedThings,
          drawerOpen,
          lessThan900,
          loadingVariables,
          enableAnimation,
        } = this.state;
        const { REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor } =
          process.env;

        const shownSeries = allSeries
          .filter(({ id }) => selectedVariables.includes(id))
          .sort(
            (a, b) =>
              selectedVariables.indexOf(a.id) - selectedVariables.indexOf(b.id)
          );

        const shownUnits = [
          ...new Set(
            shownSeries
              .filter(({ nodes }) =>
                nodes.some(
                  ({ value, timestamp }) =>
                    !isNullish(value) &&
                    new Date(timestamp) >= startDate &&
                    new Date(timestamp) <= endDate
                )
              )
              .map(({ unitOfMeasurement }) => unitOfMeasurement)
          ),
        ];

        return lessThan900 ? (
          <>
            <div
              style={{
                margin: "24px 16px 8px 16px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                setDates={(startDate, endDate) =>
                  this.setState({ startDate, endDate })
                }
                user={user}
                disabled={!seriesUser || !user}
                refetchSeries={(startDate, endDate) =>
                  this.refetchSeries(startDate, endDate, allSeries)
                }
                seriesCount={allSeries.length}
              />
              <IconButton
                onClick={(drawerOpen) => this.setState({ drawerOpen })}
                style={{ margin: "4px 0 4px 16px" }}
                disabled={!seriesUser || !user}
                size="large"
              >
                <MenuOpen />
              </IconButton>
            </div>
            <div
              style={{
                height: mobile ? "calc(100% - 88px)" : "calc(100% - 152px)",
              }}
            >
              <Plot
                selectedVariables={selectedVariables}
                shownSeries={shownSeries}
                shownUnits={shownUnits}
                startDate={startDate}
                endDate={endDate}
                user={user}
                setSnackbarOpen={setSnackbarOpen}
                loadingVariables={loadingVariables}
                enableAnimation={enableAnimation}
              />
              <SwipeableDrawer
                anchor="right"
                open={drawerOpen}
                onOpen={() => this.setState({ drawerOpen: true })}
                onClose={() => this.setState({ drawerOpen: false })}
                disableBackdropTransition={false}
                disableDiscovery
                disableSwipeToOpen
                PaperProps={{
                  style: {
                    backgroundColor,
                    maxWidth: "320px",
                    width: "calc(100% - 64px)",
                  },
                }}
              >
                {userError || seriesError ? (
                  <ErrorScreen
                    refetch={() => {
                      userRefetch({
                        limit: 20,
                        offset: 0,
                        filter: {},
                      });
                      seriesRefetch({
                        limit: 20,
                        offset: 0,
                        filter: {},
                      });
                    }}
                    error={userError || seriesError}
                  />
                ) : userLoading || seriesLoading ? (
                  <CenteredSpinner
                    style={{
                      paddingTop: "32px",
                    }}
                  />
                ) : (
                  <SeriesList
                    isInDrawer
                    allSeries={allSeries}
                    loadingVariables={loadingVariables}
                    selectedVariables={selectedVariables}
                    expandedThings={expandedThings}
                    shownUnits={shownUnits}
                    setExpandedThings={(expandedThingsFunction) =>
                      this.setState(expandedThingsFunction)
                    }
                    setSelectedVariables={(selectedVariablesFunction) =>
                      this.setState(selectedVariablesFunction)
                    }
                    fetchSeries={(id) =>
                      this.fetchSeries(id, startDate, endDate, allSeries)
                    }
                    user={user}
                    seriesUser={seriesUser}
                    seriesData={this.props.seriesData}
                  />
                )}
              </SwipeableDrawer>
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              height: "calc(100% - 64px)",
            }}
            className="notSelectable"
          >
            <div style={{ width: "320px" }}>
              <div style={{ margin: "24px 16px 8px 16px" }}>
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  setDates={(startDate, endDate) =>
                    this.setState({ startDate, endDate })
                  }
                  user={user}
                  disabled={!seriesUser || !user}
                  refetchSeries={(startDate, endDate) =>
                    this.refetchSeries(startDate, endDate, allSeries)
                  }
                  seriesCount={allSeries.length}
                />
              </div>
              {userError || seriesError ? (
                <ErrorScreen
                  refetch={() => {
                    userRefetch({
                      limit: 20,
                      offset: 0,
                      filter: {},
                    });
                    seriesRefetch({
                      limit: 20,
                      offset: 0,
                      filter: {},
                    });
                  }}
                  error={userError || seriesError}
                />
              ) : userLoading || seriesLoading ? (
                <CenteredSpinner
                  style={{
                    paddingTop: "32px",
                  }}
                />
              ) : (
                <SeriesList
                  allSeries={allSeries}
                  loadingVariables={loadingVariables}
                  selectedVariables={selectedVariables}
                  expandedThings={expandedThings}
                  shownUnits={shownUnits}
                  setExpandedThings={(expandedThingsFunction) =>
                    this.setState(expandedThingsFunction)
                  }
                  setSelectedVariables={(selectedVariablesFunction) =>
                    this.setState(selectedVariablesFunction)
                  }
                  fetchSeries={(id) =>
                    this.fetchSeries(id, startDate, endDate, allSeries)
                  }
                  user={user}
                  seriesUser={seriesUser}
                  seriesData={this.props.seriesData}
                />
              )}
            </div>
            <div
              style={{
                width: "calc(100% - 320px)",
                height: "100%",
              }}
            >
              <Plot
                selectedVariables={selectedVariables}
                shownSeries={shownSeries}
                shownUnits={shownUnits}
                startDate={startDate}
                endDate={endDate}
                user={user}
                setSnackbarOpen={setSnackbarOpen}
                loadingVariables={loadingVariables}
                enableAnimation={enableAnimation}
              />
            </div>
          </div>
        );
      }
    }
  )
);
