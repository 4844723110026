import React, { Component } from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import { graphql } from "@apollo/react-hoc"
import gql from "graphql-tag"
import { withTranslation } from "react-i18next"

export default graphql(
  gql`
    mutation DeleteCollection($id: ID!) {
      deleteCollection(id: $id)
    }
  `,
  {
    name: "DeleteCollection",
  }
)(
  withTranslation()(
    class DeleteCollection extends Component {
      constructor(props) {
        super(props)

        this.state = {
          showLoading: false,
        }
      }

      deleteCollectionMutation = async () => {
        this.setState({ showLoading: true })

        await this.props.DeleteCollection({
          variables: {
            id: this.props.collection.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            deleteCollection: {
              id: this.props.collection.id,
            },
          },
        })

        this.setState({ showLoading: false })

        this.props.close()
      }

      render() {
        const { open, close, collection, t } = this.props
        const { showLoading } = this.state
        const {
          REACT_APP_COLLECTION_NAME: collectionName,
          REACT_APP_THINGS_NAME: thingsName,
        } = process.env

        return (
          <GenericDialog
            open={open}
            close={close}
            title={t(
              "Delete " + (collectionName?.toLowerCase() || "collection")
            )}
            textButton={t`Close`}
            textButtonFunction={close}
            containedButton={t`Delete`}
            containedButtonFunction={this.deleteCollectionMutation}
            containedButtonLoading={showLoading}
            containedButtonDisabled={showLoading}
            isContainedButtonRed
          >
            {t`Be careful, ` +
              collection?.name +
              " " +
              t`will be deleted permanently` +
              ". " +
              t(
                `Note that by deleting a ${
                  collectionName?.toLowerCase() || "collection"
                }, all of its ${
                  thingsName?.toLowerCase() || "things"
                } will be unpaired`
              ) +
              "."}
          </GenericDialog>
        )
      }
    }
  )
)
