import { graphql } from "@apollo/react-hoc";
import { isRoleAtLeast } from "@igloocloud/igloosharedui";
import Create from "@mui/icons-material/Create";
import Delete from "@mui/icons-material/Delete";
import Info from "@mui/icons-material/Info";
import MoreVert from "@mui/icons-material/MoreVert";
import Notifications from "@mui/icons-material/Notifications";
import NotificationsOff from "@mui/icons-material/NotificationsOff";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Share from "@mui/icons-material/Share";
import ButtonBase from "@mui/material/ButtonBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CollectionInfo from "./CollectionInfo";
import CustomizeCollection from "./CustomizeCollection";
import DeleteCollection from "./DeleteCollection";
import LeaveCollection from "./LeaveCollection";
import ShareCollection from "./ShareCollection";

const {
  REACT_APP_SHOW_NOTIFICATIONS: showNotifications,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
  REACT_APP_ERROR_COLOR: errorColor,
} = process.env;

export default graphql(
  gql`
    mutation ToggleQuietMode($id: ID!, $muted: Boolean) {
      updateCollection(id: $id, muted: $muted) {
        id
        muted
      }
    }
  `,
  {
    name: "ToggleQuietMode",
  }
)(
  withTranslation()(
    class CollectionCard extends Component {
      constructor(props) {
        super(props);

        const importAll = (require) =>
          require.keys().reduce((acc, next) => {
            acc[next.replace("./", "")] = require(next);
            return acc;
          }, {});

        this.images = Object.values(
          importAll(
            require.context(
              "../../styles/assets/collectionCards",
              false,
              /\.(png|jpe?g|svg)$/
            )
          )
        );

        this.state = {
          deleteOpen: false,
          renameOpen: false,
          shareOpen: false,
          anchorEl: null,
          backStackIndex: 0,
        };
      }

      toggleFavorite = (favorite) =>
        this.props.ToggleFavorite({
          variables: {
            id: this.props.collection.id,
            favorite,
          },
          optimisticResponse: {
            __typename: "Mutation",
            collection: {
              id: this.props.collection.id,
              favorite,
              __typename: "Collection",
            },
          },
        });

      toggleQuietMode = (muted) =>
        this.props.ToggleQuietMode({
          variables: {
            id: this.props.collection.id,
            muted,
          },
          optimisticResponse: {
            __typename: "Mutation",
            collection: {
              id: this.props.collection.id,
              muted,
              __typename: "Collection",
            },
          },
        });

      handleBackButton = () => {
        if (this.state.backStackIndex === window.backStack) {
          this.handleMenuClose();
        }
      };

      handleMenuClose = () => {
        this.setState({ anchorEl: null });

        if (window.cordova) {
          this.setState({ backStackIndex: 0 });

          --window.backStack;

          document.removeEventListener("backbutton", this.handleBackButton);
        }
      };

      render() {
        const { collection, t } = this.props;

        return (
          <>
            <Paper
              style={{
                backgroundColor,
                width: "100%",
                height: "100%",
              }}
              tabIndex="-1"
              elevation={5}
            >
              <div style={{ padding: "8px", textAlign: "right" }}>
                <IconButton
                  onClick={(event) =>
                    this.setState({ anchorEl: event.currentTarget })
                  }
                  style={{ zIndex: 10, color: textColor }}
                  size="large"
                >
                  {/*makes the IconButton appear over the ButtonBase*/}
                  <MoreVert />
                </IconButton>
              </div>
              <Link
                to={"/?collection=" + this.props.collection.id}
                style={{
                  textDecoration: "none",
                  color: "unset",
                }}
              >
                <ButtonBase
                  focusRipple
                  style={{
                    borderRadius: "4px",
                    textAlign: "left",
                    marginTop: "-64px",
                    zIndex: 0, //makes the ButtonBase appear under the IconButton, making it clickable
                    width: "100%",
                  }}
                >
                  <div style={{ height: "192px", width: "100%" }}>
                    <Toolbar
                      style={{
                        height: "64px",
                        paddingLeft: "0",
                        paddingRight: "0",
                      }}
                    >
                      <div
                        style={{
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                          paddingLeft: "16px",
                          width: "calc(100% - 16px)",
                        }}
                      >
                        <Typography
                          variant="h6"
                          className="notSelectable"
                          style={{
                            color: textColor,
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            lineHeight: "64px",
                            width: "calc(100% - 56px)",
                          }}
                        >
                          {this.props.collection.name}
                        </Typography>
                      </div>
                    </Toolbar>
                    <img
                      src={
                        this.images[collection.picture % this.images.length]
                          .default
                      }
                      alt="Collection"
                      className="notSelectable"
                      draggable="false"
                      style={{
                        width: "100%",
                        height: "128px",
                        borderBottomLeftRadius: "4px",
                        borderBottomRightRadius: "4px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </ButtonBase>
              </Link>
            </Paper>
            <Menu
              id="collection-card-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.anchorEl}
              onEnter={() => {
                if (window.cordova) {
                  this.setState({ backStackIndex: ++window.backStack });

                  document.addEventListener(
                    "backbutton",
                    this.handleBackButton
                  );
                }
              }}
              onClose={() => this.handleMenuClose()}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              PaperProps={{
                style: {
                  backgroundColor,
                },
              }}
            >
              <MenuItem
                style={{ color: textOnBackgroundColor }}
                onClick={() => {
                  this.handleMenuClose();

                  this.setState({ infoOpen: true });
                }}
              >
                <ListItemIcon>
                  <Info />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  {t`Information`}
                </Typography>
              </MenuItem>
              <Divider />
              <MenuItem
                style={{ color: textOnBackgroundColor }}
                onClick={() => {
                  this.handleMenuClose();

                  this.setState({ shareOpen: true });
                }}
                disabled={
                  !(
                    this.props.userData.user &&
                    this.props.userData.user.emailIsVerified
                  )
                }
              >
                <ListItemIcon>
                  <Share />
                </ListItemIcon>
                <Typography variant="inherit" noWrap>
                  {t`Share`}
                </Typography>
              </MenuItem>
              {!isRoleAtLeast(this.props.collection.myRole, "OWNER") && (
                <MenuItem
                  style={{ color: textOnBackgroundColor }}
                  onClick={() => {
                    this.handleMenuClose();

                    this.setState({ leaveOpen: true });
                  }}
                >
                  <ListItemIcon>
                    <RemoveCircle />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {t`Leave`}
                  </Typography>
                </MenuItem>
              )}
              {showNotifications && (
                <>
                  <Divider />
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    onClick={() => {
                      this.handleMenuClose();

                      this.toggleQuietMode(
                        this.props.collection.muted ? false : true
                      );
                    }}
                    disabled={
                      this.props.userData.user &&
                      this.props.userData.user.quietMode
                    }
                  >
                    <ListItemIcon>
                      {this.props.collection.muted ? (
                        <Notifications />
                      ) : (
                        <NotificationsOff />
                      )}
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {this.props.collection.muted ? t`Unmute` : t`Mute`}
                    </Typography>
                  </MenuItem>
                </>
              )}
              {isRoleAtLeast(this.props.collection.myRole, "ADMIN") && (
                <>
                  <Divider />
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    onClick={() => {
                      this.handleMenuClose();

                      this.setState({ renameOpen: true });
                    }}
                  >
                    <ListItemIcon>
                      <Create />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {t`Customize`}
                    </Typography>
                  </MenuItem>
                </>
              )}
              {isRoleAtLeast(this.props.collection.myRole, "ADMIN") && (
                <MenuItem
                  style={{ color: textOnBackgroundColor }}
                  onClick={() => {
                    this.handleMenuClose();

                    this.setState({ deleteOpen: true });
                  }}
                >
                  <ListItemIcon>
                    <Delete style={{ color: errorColor }} />
                  </ListItemIcon>
                  <Typography
                    variant="inherit"
                    noWrap
                    style={{ color: errorColor }}
                  >
                    {t`Delete`}
                  </Typography>
                </MenuItem>
              )}
            </Menu>
            <CollectionInfo
              open={this.state.infoOpen}
              close={() => this.setState({ infoOpen: false })}
              collection={this.props.collection}
            />
            <CustomizeCollection
              open={this.state.renameOpen}
              close={() => this.setState({ renameOpen: false })}
              collection={this.props.collection}
            />
            <DeleteCollection
              open={this.state.deleteOpen}
              close={() => this.setState({ deleteOpen: false })}
              collection={this.props.collection}
            />
            <ShareCollection
              open={this.state.shareOpen}
              close={() => this.setState({ shareOpen: false })}
              collection={this.props.collection}
              userData={this.props.userData}
              client={this.props.client}
            />
            <LeaveCollection
              open={this.state.leaveOpen}
              close={() => this.setState({ leaveOpen: false })}
              collection={this.props.collection}
              userData={this.props.userData}
            />
          </>
        );
      }
    }
  )
);
