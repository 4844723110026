import React, { Component } from "react"
import { graphql } from "@apollo/react-hoc"
import gql from "graphql-tag"
import tinyColor from "tinycolor2"
import IsOnline from "is-online-component"
import DialogContent from "@mui/material/DialogContent"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import IconButton from "@mui/material/IconButton"
import {
  CenteredSpinner,
  ErrorScreen,
  getNotchHeight,
} from "@igloocloud/igloosharedui"
import Done from "@mui/icons-material/Done"
import Close from "@mui/icons-material/Close"
import LinearProgress from "@mui/material/LinearProgress"
import { GenericDialog } from "@igloocloud/igloosharedui"
import Igloo, { User, Collection } from "@igloocloud/react-igloo"
import { withTranslation } from "react-i18next"

let dialogContent = null

export default withTranslation()(
  class PendingShares extends Component {
    constructor(props) {
      super(props)

      this.state = {
        hasReceivedOpen: false,
      }
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({ hasReceivedOpen: true })
      }
    }

    render() {
      const { t } = this.props

      return (
        <Igloo
          server={
            localStorage.getItem("server")
              ? (localStorage.getItem("serverUnsecure") === "true"
                  ? "http://"
                  : "https://") + localStorage.getItem("server")
              : `https://v1.igloo.ooo`
          }
          bearer={
            localStorage.getItem("accountList") &&
            localStorage.getItem("userId") &&
            JSON.parse(localStorage.getItem("accountList")).filter(
              (account) => account.id === localStorage.getItem("userId")
            )[0]
              ? JSON.parse(localStorage.getItem("accountList")).filter(
                  (account) => account.id === localStorage.getItem("userId")
                )[0].token
              : ""
          }
        >
          <GenericDialog
            title={t`Pending share requests`}
            textButton={t`Close`}
            textButtonFunction={this.props.close}
            noHorizontalPadding
            noDialogContent
            {...this.props}
          >
            {({ fullScreen }) =>
              this.state.hasReceivedOpen && (
                <PendingSharesContent {...this.props} fullScreen={fullScreen} />
              )
            }
          </GenericDialog>
        </Igloo>
      )
    }
  }
)

const PendingSharesContent = graphql(
  gql`
    query ($limit: NaturalNumber!, $offset: NaturalNumber) {
      user {
        id
        pendingShareCount
        pendingShares(limit: $limit, offset: $offset) {
          id
          recipient {
            id
            profileIconColor
            name
            email
          }
          sender {
            id
            name
          }
          collection {
            id
            name
          }
        }
      }
    }
  `,
  {
    name: "pendingSharesData",
    options: { variables: { offset: 0, limit: 20 } },
  }
)(
  graphql(
    gql`
      mutation AcceptPendingShare($id: ID!) {
        acceptPendingShare(id: $id) {
          id
        }
      }
    `,
    {
      name: "AcceptPendingShare",
    }
  )(
    graphql(
      gql`
        mutation DeclinePendingShare($id: ID!) {
          declinePendingShare(id: $id)
        }
      `,
      {
        name: "DeclinePendingShare",
      }
    )(
      class PendingSharesDialogContent extends Component {
        constructor(props) {
          super(props)

          this.state = { fetchMoreLoading: false }
        }

        AcceptPendingShare = (id) =>
          this.props.AcceptPendingShare({
            variables: {
              id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              pendingShares: {
                id,
                __typename: "PendingShares",
              },
            },
          })

        DeclinePendingShare = (id) =>
          this.props.DeclinePendingShare({
            variables: {
              id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              pendingShares: {
                id,
                __typename: "PendingShares",
              },
            },
          })

        componentDidMount() {
          this.props.pendingSharesData.refetch()

          const pendingShareCreatedSubscription = gql`
            subscription {
              pendingShareCreated {
                id
                recipient {
                  id
                  profileIconColor
                  name
                  email
                }
                sender {
                  id
                  name
                }
                collection {
                  id
                  name
                }
              }
            }
          `

          this.props.pendingSharesData.subscribeToMore({
            document: pendingShareCreatedSubscription,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev
              }

              const newPendingShares = [
                ...prev.user.pendingShares,
                subscriptionData.data.pendingShareCreated,
              ]

              return {
                user: {
                  ...prev.user,
                  pendingShares: newPendingShares,
                },
              }
            },
          })

          this.props.pendingSharesData.subscribeToMore({
            document: gql`
              subscription {
                pendingShareUpdated {
                  id
                  recipient {
                    id
                    profileIconColor
                    name
                    email
                  }
                  sender {
                    id
                    name
                  }
                  collection {
                    id
                    name
                  }
                }
              }
            `,
          })

          const pendingShareDeclinedSubscription = gql`
            subscription {
              pendingShareDeclined
            }
          `

          this.props.pendingSharesData.subscribeToMore({
            document: pendingShareDeclinedSubscription,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev
              }

              const newPendingShares = prev.user.pendingShares.filter(
                (pendingShare) =>
                  pendingShare.id !== subscriptionData.data.pendingShareDeclined
              )

              return {
                user: {
                  ...prev.user,
                  pendingShares: newPendingShares,
                },
              }
            },
          })

          const pendingShareRevokedSubscription = gql`
            subscription {
              pendingShareRevoked
            }
          `

          this.props.pendingSharesData.subscribeToMore({
            document: pendingShareRevokedSubscription,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev
              }

              const newPendingShares = prev.user.pendingShares.filter(
                (pendingShare) =>
                  pendingShare.id !== subscriptionData.data.pendingShareRevoked
              )

              return {
                user: {
                  ...prev.user,
                  pendingShares: newPendingShares,
                },
              }
            },
          })

          const pendingShareAcceptedSubscription = gql`
            subscription {
              pendingShareAccepted {
                id
              }
            }
          `

          this.props.pendingSharesData.subscribeToMore({
            document: pendingShareAcceptedSubscription,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) {
                return prev
              }

              const newPendingShares = prev.user.pendingShares.filter(
                (pendingShare) =>
                  pendingShare.id !==
                  subscriptionData.data.pendingShareAccepted.id
              )

              return {
                user: {
                  ...prev.user,
                  pendingShares: newPendingShares,
                },
              }
            },
          })
        }

        queryMore = async () => {
          if (
            !this.queryMore.locked &&
            this.props.pendingSharesData.user.pendingShareCount >
              this.props.pendingSharesData.user.pendingShares.length
          ) {
            this.queryMore.locked = true

            try {
              this.setState({ fetchMoreLoading: true })
              await this.props.pendingSharesData.fetchMore({
                variables: {
                  offset:
                    this.props.pendingSharesData.user.pendingShares.length,
                  limit:
                    this.props.pendingSharesData.user.pendingShareCount -
                      this.props.pendingSharesData.user.pendingShares.length >=
                    20
                      ? 20
                      : this.props.pendingSharesData.user.pendingShareCount %
                        20,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev
                  }

                  const newShares = [
                    ...prev.user.pendingShares,
                    ...fetchMoreResult.user.pendingShares,
                  ]

                  return {
                    user: {
                      ...prev.user,
                      pendingShares: newShares,
                    },
                  }
                },
              })
            } finally {
              this.setState(() => {
                this.queryMore.locked = false

                return { fetchMoreLoading: false }
              })
            }
          }
        }

        componentWillReceiveProps(nextProps) {
          if (
            nextProps.open &&
            !nextProps.pendingSharesData.user?.pendingShares[0]
          ) {
            this.props.close()
          }
        }

        render() {
          const {
            pendingSharesData: { error, loading, user, refetch },
            fullScreen,
            t,
          } = this.props

          const textColor = process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR

          if (loading)
            dialogContent = (
              <div style={{ height: "100%" }}>
                <CenteredSpinner />
              </div>
            )

          if (error)
            dialogContent = (
              <div style={{ height: "100%" }}>
                <ErrorScreen
                  refetch={() =>
                    refetch({
                      variables: {
                        limit: 20,
                        offset: 0,
                      },
                    })
                  }
                  error={error}
                />
              </div>
            )

          if (user)
            dialogContent = (
              <DialogContent
                style={{ padding: 0 }}
                onScroll={(event) => {
                  if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 600
                  )
                    this.queryMore()
                }}
              >
                <IsOnline
                  onChange={(online) => {
                    if (online) {
                      this.props.pendingSharesData.refetch()
                    }
                  }}
                />
                <List
                  style={{
                    width: "100%",
                    textOverflow: "ellipsis",
                    padding: 0,
                  }}
                >
                  {user.pendingShares.map((pendingShare) => (
                    <Collection
                      id={pendingShare.collection.id}
                      key={pendingShare.collection.id}
                      fields={["name"]}
                    >
                      {({ collectionData }) => (
                        <User
                          id={pendingShare.sender.id}
                          fields={["name"]}
                          privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                        >
                          {({ userData }) => (
                            <ListItem
                              style={{
                                paddingLeft:
                                  "calc(24px + " +
                                  (fullScreen
                                    ? getNotchHeight("left")
                                    : "0px") +
                                  ")",
                                paddingRight:
                                  "calc(24px + " +
                                  (fullScreen
                                    ? getNotchHeight("right")
                                    : "0px") +
                                  ")",
                              }}
                              key={pendingShare.id}
                            >
                              <ListItemText
                                primary={
                                  <font
                                    style={{
                                      color: textColor,
                                    }}
                                  >
                                    {collectionData
                                      ? collectionData.name
                                      : pendingShare.collection.name}
                                  </font>
                                }
                                secondary={
                                  <font
                                    style={{
                                      color: tinyColor(textColor)
                                        .setAlpha(0.54)
                                        .toRgbString(),
                                    }}
                                  >
                                    {t`Sent by ` +
                                      (userData
                                        ? userData.name
                                        : pendingShare.sender.name)}
                                  </font>
                                }
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              />
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={() =>
                                    this.AcceptPendingShare(pendingShare.id)
                                  }
                                  disabled={this.props.unverified}
                                  size="large">
                                  <Done />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    this.DeclinePendingShare(pendingShare.id)
                                  }
                                  size="large">
                                  <Close />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )}
                        </User>
                      )}
                    </Collection>
                  ))}
                </List>
                {this.state.fetchMoreLoading && <LinearProgress />}
              </DialogContent>
            )

          return dialogContent
        }
      }
    )
  )
)
