import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import { HashRouter, BrowserRouter } from "react-router-dom"
import { I18nextProvider } from "react-i18next"
import { LocalizationProvider } from "@mui/lab"
import DateAdapter from "@mui/lab/AdapterMoment"
import i18n from "./i18n"

const HybridRouter = window.cordova ? HashRouter : BrowserRouter

const startApp = () => {
  ReactDOM.render(
    <LocalizationProvider dateAdapter={DateAdapter}>
      <I18nextProvider i18n={i18n}>
        <HybridRouter>
          <App />
        </HybridRouter>
      </I18nextProvider>
    </LocalizationProvider>,
    document.getElementById("root")
  )
}

if (window.cordova) {
  document.addEventListener("deviceready", startApp, false)
} else {
  startApp()
}
