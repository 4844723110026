import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { GenericDialog, isNullish } from "@igloocloud/igloosharedui";
import { DeleteOutlined } from "@mui/icons-material";
import {
  Button,
  Collapse,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import {
  Action,
  ActionType,
  Comparison,
  Condition,
  FloatComparison,
  IntervalUnit,
  Props,
} from "./FlowManifestDialog.types";
import tinyColor from "tinycolor2";

const AndButton = styled(Button)({
  "&.MuiButton-outlinedInherit": {
    borderColor: tinyColor(process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR)
      .setAlpha(0.26)
      .toHex8String(),
    "&:hover": {
      borderColor: tinyColor(
        process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR
      )
        .setAlpha(0.87)
        .toHex8String(),
    },
  },
});

export default ({ open, close, mode, thingId }: Props) => {
  const [name, setName] = useState<string>("");
  const [nameEmpty, setNameEmpty] = useState<boolean>(false);
  const [conditions, setConditions] = useState<Condition[]>([
    { localId: Date.now() },
  ]);
  const [actions, setActions] = useState<Action[]>([{ localId: Date.now() }]);
  const [intervalDuration, setIntervalDuration] = useState<number>(null);
  const [intervalDurationEmpty, setIntervalDurationEmpty] =
    useState<boolean>(null);
  const [intervalUnit, setIntervalUnit] = useState<IntervalUnit>("hours");

  const { t }: { t: (string) => string } = useTranslation();

  const [getVariables, { loading, error, data }] = useLazyQuery(
    gql`
      query ($id: ID!, $limit: NaturalNumber!, $offset: NaturalNumber) {
        thing(id: $id) {
          id
          variableCount
          variables(limit: $limit, offset: $offset, sortBy: index) {
            __typename
            id
            name
            ... on StringVariable {
              stringAllowedValues: allowedValues
            }
            ... on FloatVariable {
              floatAllowedValues: allowedValues
              min
              max
            }
            ... on FloatSeriesVariable {
              minThreshold
              maxThreshold
              visualization
            }
          }
        }
      }
    `,
    { variables: { id: thingId, limit: 20, offset: 0 } }
  );
  const [createFlow] = useMutation(gql`
    mutation createFlow(
      $thingId: ID!
      $name: String!
      $minInterval: Int!
      $manifest: Json!
    ) {
      updateCollection(
        id: $id
        developerOnly: false
        name: $name
        minInterval: $minInterval
        manifest: $manifest
      ) {
        id
        muted
      }
    }
  `);
  const [updateFlow] = useMutation(gql`
    mutation updateFlow(
      $thingId: ID!
      $name: String!
      $minInterval: Int!
      $manifest: Json!
    ) {
      updateCollection(
        id: $id
        developerOnly: false
        name: $name
        minInterval: $minInterval
        manifest: $manifest
      ) {
        id
        muted
      }
    }
  `);

  useEffect(() => {
    if (open) {
      setName("");
      setNameEmpty(false);
      setConditions([{ localId: Date.now() }]);
      setActions([{ localId: Date.now() }]);
      setIntervalDuration(null);
      setIntervalDurationEmpty(false);
      setIntervalUnit("hours");

      getVariables();
    }
  }, [open]);

  const {
    REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
  }: {
    REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: string;
  } = process.env;

  return (
    <GenericDialog
      title={mode === "create" ? t`Create flow` : t`Edit flow`}
      open={open}
      close={close}
      containedButton={mode === "create" ? t`Create` : t`Edit`}
      containedButtonFunction={() =>
        mode === "create" ? createFlow() : updateFlow()
      }
      containedButtonDisabled={
        !name ||
        !conditions.find(
          ({ id, comparison, value }) =>
            data?.thing.variables.find(
              ({ id }) => id === id?.replace("threshold-", "")
            ) &&
            (id &&
            (data?.thing.variables.find(
              ({ id }) => id === id?.replace("threshold-", "")
            ).__typename === "BooleanVariable" ||
              data?.thing.variables.find(
                ({ id }) => id === id?.replace("threshold-", "")
              ).__typename === "FileVariable")
              ? value
              : data?.thing.variables.find(
                  ({ id }) => id === id?.replace("threshold-", "")
                ).__typename === "ImpulseVariable" ||
                (comparison && value))
        ) ||
        !actions.find(
          ({ type, content }) =>
            //TODO: add conditions for variable actions
            type && (type === "sendNotification" ? content : null)
        ) ||
        !intervalDuration
      }
      textButton={t`Close`}
      textButtonFunction={close}
      disableMaxHeight
    >
      <TextField
        value={name}
        onChange={(event) => {
          setName(event.target.value);
          setNameEmpty(event.target.value === "");
        }}
        error={nameEmpty}
        label={t`Name`}
        variant="outlined"
        required
        style={{
          width: "100%",
          marginBottom: "24px",
        }}
        InputLabelProps={name ? { shrink: true } : {}}
      />
      <TransitionGroup>
        {conditions.map(
          ({ id, comparison, value, valueEmpty, localId }, index) => (
            <Collapse key={"condition-" + localId}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  height: "48px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    width:
                      conditions.length === 1 ? "100%" : "calc(100% - 48px)",
                    margin: 0,
                  }}
                >
                  {index === 0 ? t`If` : t`And`}
                </Typography>
                {conditions.length === 1 ? null : (
                  <IconButton
                    onClick={() =>
                      setConditions((conditions) =>
                        conditions.filter(
                          (_, indexToRemove) => indexToRemove !== index
                        )
                      )
                    }
                    size="large"
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
              <TextField
                value={id}
                onChange={(event) => {
                  setConditions((conditions) => {
                    const newConditions = conditions;

                    newConditions[
                      conditions.findIndex(
                        ({ localId: idToFind }) => idToFind === localId
                      )
                    ].id = event.target.value;

                    return newConditions;
                  });
                }}
                label={t`Variable`}
                variant="outlined"
                select
                required
                style={{
                  width: "100%",
                  marginBottom: "24px",
                }}
                InputLabelProps={id ? { shrink: true } : {}}
              >
                {data?.thing.variables.map(
                  ({
                    id,
                    name,
                    __typename,
                    visualization,
                    minThreshold,
                    maxThreshold,
                  }) => [
                    <MenuItem
                      style={{ color: textOnMainBackgroundColor }}
                      value={id}
                      key={"create-flow-dialog-variable-list-" + id}
                    >
                      {(__typename === "FloatSeriesVariable"
                        ? t`The last node of` + " "
                        : "") +
                        name +
                        " " +
                        (__typename === "ImpulseVariable"
                          ? t`triggers`
                          : t`is`)}
                    </MenuItem>,
                    __typename === "FloatSeriesVariable" &&
                      visualization !== "windDirection" &&
                      (!isNullish(minThreshold) ||
                        !isNullish(maxThreshold)) && (
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value={"threshold-" + id}
                          key={"create-flow-dialog-variable-list-" + id}
                        >
                          {t`The last node of` +
                            " " +
                            name +
                            " " +
                            t`is outside the thresholds`}
                        </MenuItem>
                      ),
                  ]
                )}
              </TextField>
              <Collapse key={"condition-options-" + localId} in={!!id}>
                {data?.thing.variables.find(
                  ({ id }) => id === id.replace("threshold-", "")
                ) &&
                  (data?.thing.variables.find(
                    ({ id }) => id === id?.replace("threshold-", "")
                  ).__typename === "FloatVariable" ||
                  (data?.thing.variables.find(
                    ({ id }) => id === id?.replace("threshold-", "")
                  ).__typename === "FloatSeriesVariable" &&
                    !id.startsWith("threshold-")) ? (
                    <>
                      <TextField
                        value={comparison}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = conditions;

                            newConditions[
                              conditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              )
                            ].comparison = event.target
                              .value as FloatComparison;

                            return newConditions;
                          })
                        }
                        label={t`Operator`}
                        variant="outlined"
                        select
                        required
                        style={{
                          width: "calc(100% - 160px)",
                          marginBottom: "24px",
                          marginRight: "16px",
                        }}
                        InputLabelProps={comparison ? { shrink: true } : {}}
                      >
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="equal"
                          key="equal"
                        >
                          {t`Equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="notEqual"
                          key="notEqual"
                        >
                          {t`Not equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="greaterThan"
                          key="greaterThan"
                        >
                          {t`Greater than`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="greaterThanOrEqual"
                          key="greaterThanOrEqual"
                        >
                          {t`Greater than or equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="lessThan"
                          key="lessThan"
                        >
                          {t`Less than`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="lessThanOrEqual"
                          key="lessThanOrEqual"
                        >
                          {t`Less than or equal to`}
                        </MenuItem>
                      </TextField>
                      <TextField
                        label={t`Value`}
                        type="number"
                        value={value}
                        variant="outlined"
                        required
                        error={valueEmpty}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = conditions;
                            const conditionIndex: number =
                              newConditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              );

                            newConditions[conditionIndex].value =
                              event.target.value;

                            newConditions[conditionIndex].valueEmpty =
                              event.target.value.replace(/\s/g, "") === "";

                            return newConditions;
                          })
                        }
                        style={{
                          width: "144px",
                        }}
                        InputLabelProps={value ? { shrink: true } : {}}
                      />
                    </>
                  ) : id.startsWith("threshold-") &&
                    !isNullish(
                      data?.thing.variables.find(
                        ({ id }) => id === id?.replace("threshold-", "")
                      ).minThreshold
                    ) &&
                    !isNullish(
                      data?.thing.variables.find(
                        ({ id }) => id === id?.replace("threshold-", "")
                      ).maxThreshold
                    ) ? (
                    <TextField
                      value={value}
                      onChange={(event) =>
                        setConditions((conditions) => {
                          const newConditions = conditions;

                          newConditions[
                            conditions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].value = event.target.value;

                          return newConditions;
                        })
                      }
                      label={t`Threshold`}
                      variant="outlined"
                      select
                      required
                      style={{
                        width: "100%",
                        marginBottom: "24px",
                      }}
                      InputLabelProps={value ? { shrink: true } : {}}
                    >
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="minThreshold"
                        key={localId + "-min-threshold"}
                      >
                        {t`Lower`}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="maxThreshold"
                        key={localId + "-max-threshold"}
                      >
                        {t`Upper`}
                      </MenuItem>
                    </TextField>
                  ) : data?.thing.variables.find(
                      ({ id }) => id === id?.replace("threshold-", "")
                    ).__typename === "StringVariable" ? (
                    <>
                      <TextField
                        value={comparison}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = conditions;

                            newConditions[
                              conditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              )
                            ].comparison = event.target
                              .value as FloatComparison;

                            return conditions;
                          })
                        }
                        label={t`Operator`}
                        variant="outlined"
                        select
                        required
                        style={{
                          width: "144px",
                          marginBottom: "24px",
                          marginRight: "16px",
                        }}
                        InputLabelProps={comparison ? { shrink: true } : {}}
                      >
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="equal"
                          key="equal"
                        >
                          {t`Equal to`}
                        </MenuItem>
                        <MenuItem
                          style={{
                            color: textOnMainBackgroundColor,
                          }}
                          value="notEqual"
                          key="notEqual"
                        >
                          {t`Not equal to`}
                        </MenuItem>
                      </TextField>
                      <TextField
                        label={t`Value`}
                        value={value}
                        variant="outlined"
                        required
                        error={valueEmpty}
                        onChange={(event) =>
                          setConditions((conditions) => {
                            const newConditions = conditions;
                            const conditionIndex: number =
                              newConditions.findIndex(
                                ({ localId: idToFind }) => idToFind === localId
                              );

                            newConditions[conditionIndex].value =
                              event.target.value;

                            newConditions[conditionIndex].valueEmpty =
                              event.target.value.replace(/\s/g, "") === "";

                            return newConditions;
                          })
                        }
                        style={{
                          width: "calc(100% - 160px)",
                        }}
                        InputLabelProps={value ? { shrink: true } : {}}
                      />
                    </>
                  ) : data?.thing.variables.find(
                      ({ id }) => id === id?.replace("threshold-", "")
                    ).__typename === "BooleanVariable" ? (
                    <TextField
                      value={value}
                      onChange={(event) => {
                        setConditions((conditions) => {
                          const newConditions = conditions;

                          newConditions[
                            conditions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].value = event.target.value;

                          return newConditions;
                        });
                      }}
                      label={t`Value`}
                      variant="outlined"
                      select
                      required
                      style={{
                        width: "100%",
                        marginBottom: "24px",
                      }}
                      InputLabelProps={value ? { shrink: true } : {}}
                    >
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isTrue"
                        key="isTrue"
                      >
                        {t`True`}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isFalse"
                        key="isFalse"
                      >
                        {t`False`}
                      </MenuItem>
                    </TextField>
                  ) : data?.thing.variables.find(
                      ({ id }) => id === id?.replace("threshold-", "")
                    ).__typename === "FileVariable" ? (
                    <TextField
                      value={value}
                      onChange={(event) =>
                        setConditions((conditions) => {
                          const newConditions = conditions;

                          newConditions[
                            conditions.findIndex(
                              ({ localId: idToFind }) => idToFind === localId
                            )
                          ].value = event.target.value;
                          return newConditions;
                        })
                      }
                      label={t`Value`}
                      variant="outlined"
                      select
                      required
                      style={{
                        width: "100%",
                        marginBottom: "24px",
                      }}
                      InputLabelProps={value ? { shrink: true } : {}}
                    >
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isEmpty"
                        key="isEmpty"
                      >
                        {t`Empty`}
                      </MenuItem>
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value="isNotEmpty"
                        key="isNotEmpty"
                      >
                        {t`Not empty`}
                      </MenuItem>
                    </TextField>
                  ) : null)}
              </Collapse>
            </Collapse>
          )
        )}
        <AndButton
          color="inherit"
          variant="outlined"
          fullWidth
          onClick={() =>
            setConditions((conditions) => [
              ...conditions,
              { localId: Date.now() },
            ])
          }
          style={{ marginBottom: "16px" }}
        >
          {t`And` + "..."}
        </AndButton>
        {actions.map(
          (
            { localId, type, content, contentEmpty, value, valueEmpty },
            index
          ) => (
            <Collapse key={"action-" + localId}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "16px",
                  height: "48px",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    width:
                      conditions.length === 1 ? "100%" : "calc(100% - 48px)",
                    margin: 0,
                  }}
                >
                  {index === 0 ? t`Then` : t`And`}
                </Typography>
                {actions.length === 1 ? null : (
                  <IconButton
                    onClick={() =>
                      setActions((actions) =>
                        actions.filter(
                          (_, indexToRemove) => indexToRemove !== index
                        )
                      )
                    }
                    size="large"
                  >
                    <DeleteOutlined />
                  </IconButton>
                )}
              </div>
              <TextField
                value={type}
                onChange={(event) =>
                  setActions((actions) => {
                    const newActions = actions;

                    newActions[
                      actions.findIndex(
                        ({ localId: idToFind }) => idToFind === localId
                      )
                    ].type = event.target.value as ActionType;

                    return newActions;
                  })
                }
                label={t`Type`}
                variant="outlined"
                select
                required
                style={{
                  width: "100%",
                  marginBottom: "24px",
                }}
                InputLabelProps={type ? { shrink: true } : {}}
              >
                <MenuItem
                  style={{ color: textOnMainBackgroundColor }}
                  value="notification"
                  key={"create-flow-dialog-action-list-notification"}
                >
                  {t`Send a notification`}
                </MenuItem>
                {data?.thing.variables.map(({ id, name, __typename }) => [
                  <MenuItem
                    style={{ color: textOnMainBackgroundColor }}
                    value={"update-" + id}
                    key={"create-flow-dialog-action-list-update-" + id}
                  >
                    {t`Set` + " " + name + " " + t`to`}
                  </MenuItem>,
                  __typename === "FloatVariable" ? (
                    <MenuItem
                      style={{
                        color: textOnMainBackgroundColor,
                      }}
                      value={"increment-" + id}
                      key={"create-flow-dialog-action-list-increment-" + id}
                    >
                      {t`Increase` + " " + name}
                    </MenuItem>
                  ) : (
                    __typename === "BooleanVariable" && (
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value={"negate-" + id}
                        key={"create-flow-dialog-action-list-negate-" + id}
                      >
                        {t`Negate` + " " + name}
                      </MenuItem>
                    )
                  ),
                ])}
              </TextField>
              <Collapse
                key={"action-options-" + localId}
                in={type && !type.startsWith("negate-")}
              >
                {type === "sendNotification" ? (
                  <TextField
                    key={"action-notification-text-" + localId}
                    value={content}
                    onChange={(event) =>
                      setActions((actions) => {
                        const newActions = actions;

                        newActions[
                          actions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].content = event.target.value;

                        newActions[
                          conditions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].contentEmpty =
                          event.target.value.replace(/\s/g, "") === "";

                        return newActions;
                      })
                    }
                    error={contentEmpty}
                    label={t`Text`}
                    variant="outlined"
                    required
                    style={{
                      width: "100%",
                      marginBottom: "24px",
                    }}
                    InputLabelProps={content ? { shrink: true } : {}}
                  />
                ) : data?.thing.variables.find(
                    ({ id: idToFind }) =>
                      idToFind === type?.substring(type.indexOf("-") + 1)
                  )?.__typename === "BooleanVariable" ? (
                  <TextField
                    key={"action-boolean-value-" + localId}
                    value={value}
                    onChange={(event) =>
                      setConditions((conditions) => {
                        const newConditions = conditions;

                        newConditions[
                          conditions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].value = event.target.value;

                        return newConditions;
                      })
                    }
                    label={t`Value`}
                    variant="outlined"
                    select
                    required
                    style={{
                      width: "100%",
                      marginBottom: "24px",
                    }}
                    InputLabelProps={value ? { shrink: true } : {}}
                  >
                    <MenuItem
                      style={{ color: textOnMainBackgroundColor }}
                      value="isTrue"
                      key="isTrue"
                    >
                      {t`True`}
                    </MenuItem>
                    <MenuItem
                      style={{ color: textOnMainBackgroundColor }}
                      value="isFalse"
                      key="isFalse"
                    >
                      {t`False`}
                    </MenuItem>
                  </TextField>
                ) : data?.thing.variables.find(
                    ({ id: idToFind }) =>
                      idToFind === type?.substring(type.indexOf("-") + 1)
                  )?.stringAllowedValues ||
                  data?.thing.variables.find(
                    ({ id: idToFind }) =>
                      idToFind === type?.substring(type.indexOf("-") + 1)
                  )?.floatAllowedValues ? (
                  <TextField
                    key={"action-allowed-values-" + localId}
                    value={value}
                    onChange={(event) => {
                      setConditions((conditions) => {
                        const newConditions = conditions;

                        newConditions[
                          conditions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].value = event.target.value;

                        return newConditions;
                      });
                    }}
                    label={t`Value`}
                    variant="outlined"
                    select
                    required
                    style={{
                      width: "100%",
                      marginBottom: "24px",
                    }}
                    InputLabelProps={value ? { shrink: true } : {}}
                  >
                    {(
                      data?.thing.variables.find(
                        ({ id: idToFind }) =>
                          idToFind === type?.substring(type.indexOf("-") + 1)
                      )?.stringAllowedValues ||
                      data?.thing.variables.find(
                        ({ id: idToFind }) =>
                          idToFind === type?.substring(type.indexOf("-") + 1)
                      )?.floatAllowedValues
                    ).map((value) => (
                      <MenuItem
                        style={{
                          color: textOnMainBackgroundColor,
                        }}
                        value={value}
                        key={"action-allowed-values-" + localId + "-" + value}
                      >
                        {value}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : data?.thing.variables.find(
                    ({ id: idToFind }) =>
                      idToFind === type?.substring(type.indexOf("-") + 1)
                  )?.__typename === "FloatVariable" ? (
                  <TextField
                    key={"action-float-value-" + localId}
                    label={t`Value`}
                    type="number"
                    value={value}
                    variant="outlined"
                    required
                    error={valueEmpty}
                    onChange={(event) =>
                      setActions((actions) => {
                        const { min, max }: { min: number; max: number } =
                          data?.thing.variables.find(
                            ({ id: idToFind }) =>
                              idToFind ===
                              type?.substring(type.indexOf("-") + 1)
                          );
                        const newActions = actions;

                        newActions[
                          newActions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].value = event.target.value;

                        newActions[
                          conditions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].valueEmpty =
                          event.target.value.replace(/\s/g, "") === "" ||
                          (type.startsWith("update-") &&
                            max &&
                            parseFloat(event.target.value) > max) ||
                          (min && parseFloat(event.target.value) < min);

                        return newActions;
                      })
                    }
                    style={{
                      width: "100%",
                      marginBottom: "24px",
                    }}
                    InputLabelProps={value ? { shrink: true } : {}}
                  />
                ) : (
                  <TextField
                    key={"action-float-value-" + localId}
                    label={t`Value`}
                    type="text"
                    value={value}
                    variant="outlined"
                    required
                    error={valueEmpty}
                    onChange={(event) =>
                      setActions((actions) => {
                        const newActions = actions;

                        newActions[
                          newActions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].value = event.target.value;

                        newActions[
                          conditions.findIndex(
                            ({ localId: idToFind }) => idToFind === localId
                          )
                        ].valueEmpty =
                          event.target.value.replace(/\s/g, "") === "";

                        return newActions;
                      })
                    }
                    style={{
                      width: "100%",
                      marginBottom: "24px",
                    }}
                    InputLabelProps={value ? { shrink: true } : {}}
                  />
                )}
              </Collapse>
            </Collapse>
          )
        )}
        <AndButton
          color="inherit"
          variant="outlined"
          fullWidth
          onClick={() =>
            setActions((actions) => [...actions, { localId: Date.now() }])
          }
          style={{ marginBottom: "16px" }}
        >
          {t`And` + "..."}
        </AndButton>
      </TransitionGroup>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "16px",
          height: "48px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            width: conditions.length === 1 ? "100%" : "calc(100% - 48px)",
            margin: 0,
          }}
        >
          {t`At most every`}
        </Typography>
      </div>
      <TextField
        label={t`Duration`}
        type="number"
        value={intervalDuration}
        variant="outlined"
        required
        error={intervalDurationEmpty}
        onChange={(event) => {
          setIntervalDuration(parseFloat(event.target.value));
          setIntervalDurationEmpty(
            event.target.value.replace(/\s/g, "") === ""
          );
        }}
        style={{
          width: "calc(50% - 8px)",
        }}
        InputLabelProps={intervalDuration ? { shrink: true } : {}}
      />
      <TextField
        value={intervalUnit}
        onChange={(event) =>
          setIntervalUnit(event.target.value as IntervalUnit)
        }
        label={t`Unit`}
        variant="outlined"
        select
        required
        style={{
          width: "calc(50% - 8px)",
          marginLeft: "16px",
          marginBottom: "16px",
        }}
        InputLabelProps={intervalUnit ? { shrink: true } : {}}
      >
        <MenuItem
          style={{ color: textOnMainBackgroundColor }}
          value="minutes"
          key="interval-unit-minutes"
        >
          {t`Minutes`}
        </MenuItem>
        <MenuItem
          style={{ color: textOnMainBackgroundColor }}
          value="hours"
          key="interval-unit-hours"
        >
          {t`Hours`}
        </MenuItem>
        <MenuItem
          style={{ color: textOnMainBackgroundColor }}
          value="days"
          key="interval-unit-days"
        >
          {t`Days`}
        </MenuItem>
        <MenuItem
          style={{ color: textOnMainBackgroundColor }}
          value="months"
          key="interval-unit-months"
        >
          {t`Months`}
        </MenuItem>
      </TextField>
    </GenericDialog>
  );
};
