import React, { Fragment } from "react";
import { MobileDateRangePicker, DateRangePickerDay } from "@mui/lab";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme, adaptV4Theme, styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { withTranslation } from "react-i18next";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import tinyColor from "tinycolor2";

export default withTranslation()(
  ({
    t,
    startDate,
    endDate,
    user,
    setDates,
    style,
    disabled,
    refetchSeries,
    seriesCount,
  }) => {
    const {
      REACT_APP_MAIN_COLOR: mainColor,
      REACT_APP_TEXT_ON_MAIN_COLOR: textOnMainColor,
      REACT_APP_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS: lightContrastColor,
      REACT_APP_TEXT_ON_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS:
        textOnLightContrastColor,
    } = process.env;

    const CustomDateRangePickerDay = styled(DateRangePickerDay)(
      ({ _, isHighlighting, outsideCurrentMonth }) => ({
        ...(isHighlighting &&
          !outsideCurrentMonth && {
            backgroundColor: tinyColor(lightContrastColor || mainColor)
              .setAlpha(0.7)
              .toHex8String(),
            color: textOnLightContrastColor || textOnMainColor,
          }),
      })
    );

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider
          theme={createTheme(
            adaptV4Theme({
              palette: {
                primary: {
                  main: lightContrastColor || mainColor,
                },
              },
            })
          )}
        >
          <MobileDateRangePicker
            disableHighlightToday
            allowSameDateSelection
            renderInput={(startProps, endProps) => (
              <Fragment>
                <TextField
                  {...startProps}
                  style={{ width: "calc(50% - 8px)", marginRight: "16px" }}
                  label={t`From`}
                  disabled={!seriesCount}
                />
                <TextField
                  {...endProps}
                  style={{ width: "calc(50% - 8px)" }}
                  label={t`To`}
                  disabled={!seriesCount}
                />
              </Fragment>
            )}
            value={[startDate, endDate]}
            onChange={([startDate, endDate]) => {
              localStorage.setItem(
                "dataLabDates",
                localStorage.getItem("dataLabDates") &&
                  JSON.parse(localStorage.getItem("dataLabDates"))[0]
                  ? JSON.stringify([
                      ...JSON.parse(
                        localStorage.getItem("dataLabDates")
                      ).filter(
                        ({ id }) => id !== localStorage.getItem("userId")
                      ),
                      {
                        id: localStorage.getItem("userId"),
                        selectedVariables:
                          JSON.parse(localStorage.getItem("dataLabDates")).find(
                            ({ id }) => id === localStorage.getItem("userId")
                          )?.selectedVariables || null,
                        startDate: moment(startDate).startOf("day").toString(),
                        endDate:
                          endDate && !moment(endDate).isSame(new Date(), "day")
                            ? moment(endDate).endOf("day").toString()
                            : "",
                      },
                    ])
                  : JSON.stringify([
                      {
                        id: localStorage.getItem("userId"),
                        startDate: moment(startDate).startOf("day").toString(),
                        endDate:
                          endDate && !moment(endDate).isSame(new Date(), "day")
                            ? moment(endDate).endOf("day").toString()
                            : "",
                      },
                    ])
              );

              setDates(
                moment(startDate).startOf("day"),
                moment(endDate).endOf("day")
              );

              refetchSeries(
                moment(startDate).startOf("day"),
                moment(endDate).endOf("day")
              );
            }}
            inputVariant="outlined"
            inputFormat={
              user
                ? user.dateFormat === "DMY"
                  ? "DD/MM/YYYY"
                  : user.dateFormat === "MDY"
                  ? "MM/DD/YYYY"
                  : user.dateFormat === "YMD"
                  ? "YYYY/MM/DD"
                  : "YYYY/DD/MM"
                : null
            }
            style={style}
            cancelText={t`Close`}
            okText={t`Confirm`}
            DialogProps={{
              className: "notSelectable",
              style: { textTransform: "capitalize" },
            }}
            invalidDateMessage={t`Invalid date format`}
            className="notSelectable"
            disabled={disabled}
            showToolbar={false}
            renderDay={(_, dateRangePickerDayProps) => (
              <CustomDateRangePickerDay {...dateRangePickerDayProps} />
            )}
          />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
);
