import React, { Component } from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import { graphql } from "@apollo/react-hoc"
import gql from "graphql-tag"
import { withTranslation } from "react-i18next"

export default graphql(
  gql`
    mutation LeaveCollection($id: ID!) {
      leaveCollection(id: $id)
    }
  `,
  {
    name: "LeaveCollection",
  }
)(
  withTranslation()(
    class LeaveCollection extends Component {
      constructor(props) {
        super(props)
        this.state = {
          showLoading: false,
        }
      }

      leaveCollection = async () => {
        this.setState({ showLoading: true })

        await this.props.LeaveCollection({
          variables: {
            id: this.props.collection.id,
          },
          optimisticResponse: {
            __typename: "Mutation",
            stopSharing: {
              id: this.props.collection.id,
              __typename: "Collection",
            },
          },
        })

        this.setState({ showLoading: false })

        this.props.close()
      }

      render() {
        const { open, close, collection, t } = this.props
        const { showLoading } = this.state
        const { REACT_APP_COLLECTION_NAME: collectionName } = process.env

        return (
          <GenericDialog
            open={open}
            close={close}
            title={t(
              "Leave " + (collectionName?.toLowerCase() || "collection")
            )}
            textButton={t`Close`}
            textButtonFunction={close}
            containedButton={t`Leave`}
            containedButtonFunction={this.leaveCollection}
            containedButtonLoading={showLoading}
            containedButtonDisabled={showLoading}
          >
            {t`Are you sure you want to leave` + " " + collection.name + "?"}
          </GenericDialog>
        )
      }
    }
  )
)
