import React, { Component } from "react";
import {
  SettingsDialog,
  getNotchHeight,
  theme,
} from "@igloocloud/igloosharedui";
import Zoom from "@mui/material/Zoom";
import Fab from "@mui/material/Fab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Box from "@mui/material/Box";
import Add from "@mui/icons-material/Add";
import Close from "@mui/icons-material/Close";
import Home from "@mui/icons-material/Home";
import Assignment from "@mui/icons-material/Assignment";
import { withTranslation } from "react-i18next";
import SwipeableRoutes from "react-swipeable-routes";
import { Route, withRouter } from "react-router-dom";
import tinyColor from "tinycolor2";
import CollectionsHeader from "./components/collections/CollectionsHeader";
import CollectionsBody from "./components/collections/CollectionsBody";
import CreateCollection from "./components/collections/CreateCollection";
import CreateReport from "./components/reports/CreateReport";
import Reports from "./Reports";
import DataLab from "./DataLab";

export default withTranslation()(
  withRouter(
    class Collections extends Component {
      constructor(props) {
        super(props);

        this.state = {
          settingsOpen: false,
          snackbarOpen: false,
          createReportOpen: false,
          createCollectionOpen: false,
        };
      }

      searchMore = async (searchText) => {
        if (
          !this.searchMore.locked &&
          this.props.userData.user.collectionCount >
            this.props.userData.user.collections.length
        ) {
          this.searchMore.locked = true;

          try {
            this.setState({ searchMoreLoading: true });

            await this.props.userData.fetchMore({
              variables: {
                filter: {
                  name: { regexMatch: "(?i)" + searchText },
                },
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const newCollections = [
                  ...prev.user.collections,
                  ...fetchMoreResult.user.collections,
                ].sort((a, b) =>
                  a.name.toLowerCase() > b.name.toLowerCase()
                    ? 1
                    : a.name.toLowerCase() < b.name.toLowerCase()
                    ? -1
                    : 0
                );

                return {
                  user: {
                    ...prev.user,
                    collections: newCollections,
                  },
                };
              },
            });
          } finally {
            this.setState(() => {
              this.searchMore.locked = false;

              return { searchMoreLoading: false };
            });
          }
        }
      };

      render() {
        const {
          mobile,
          t,
          userData: { user },
          client,
          history,
        } = this.props;
        const { snackbarOpen, createCollectionOpen, createReportOpen } =
          this.state;
        const {
          REACT_APP_MAIN_COLOR: mainColor,
          REACT_APP_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS: lightContrastColor,
          REACT_APP_MAIN_BACKGROUND_COLOR: mainBackgroundColor,
          REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
          REACT_APP_ENABLE_DATA_LAB: enableDataLab,
          REACT_APP_ENABLE_REPORTS: enableReports,
          REACT_APP_COLLECTION_NAME: collectionName,
          REACT_APP_HOME_ICON: homeIcon,
          REACT_APP_HOME_NAME: homeName,
        } = process.env;
        const transitionDuration = {
          enter: theme(process.env).transitions.duration.enteringScreen,
          exit: theme(process.env).transitions.duration.leavingScreen,
        };

        const showTabs = enableDataLab || enableReports;
        const collectionsHeader = (
          <CollectionsHeader
            logOut={this.props.logOut}
            changeBearer={this.props.changeBearer}
            changeAccount={this.props.changeAccount}
            isOpen={this.state.settingsOpen}
            setOpen={(settingsOpen) => this.setState({ settingsOpen })}
            user={this.props.userData}
            mobile={this.props.mobile}
            userData={this.props.userData}
            searchCollections={this.props.searchCollections}
            searchText={this.props.collectionsSearchText}
            searchMore={this.searchMore}
          />
        );

        const collectionRoute = (
          <Route path="/" exact>
            <CollectionsBody
              userData={this.props.userData}
              searchCollections={this.props.searchCollections}
              searchText={this.props.collectionsSearchText}
              snackBarHidden={this.props.snackBarHidden}
              client={this.props.client}
              mobile={mobile}
              searchMoreLoading={this.state.searchMoreLoading}
            />
          </Route>
        );

        const dataLabRoute = (
          <Route path="/data">
            <DataLab
              client={client}
              userData={this.props.userData}
              mobile={mobile}
              setSnackbarOpen={(snackbarOpen) =>
                this.setState({ snackbarOpen })
              }
            />
          </Route>
        );

        const reportRoute = (
          <Route path="/reports">
            <Reports mobile={mobile} />
          </Route>
        );

        const routes =
          enableReports && enableDataLab
            ? [collectionRoute, dataLabRoute, reportRoute]
            : enableReports
            ? [collectionRoute, reportRoute]
            : enableDataLab
            ? [collectionRoute, dataLabRoute]
            : collectionRoute;

        return (
          <>
            {mobile
              ? showTabs && (
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="fullWidth"
                      TabIndicatorProps={{
                        style: {
                          width: `${100 / routes.length}%`,
                          left: `${
                            ((window.location.pathname === "/"
                              ? 0
                              : window.location.pathname === "/data"
                              ? 1
                              : 2) *
                              100) /
                            routes.length
                          }%`,
                        },
                      }}
                    >
                      <Tab
                        icon={
                          homeIcon ? (
                            <div
                              dangerouslySetInnerHTML={{ __html: homeIcon }}
                              style={{ margin: 0 }}
                            />
                          ) : (
                            <Home />
                          )
                        }
                        label={t(homeName || "Home")}
                        style={
                          window.location.pathname === "/"
                            ? { color: lightContrastColor || mainColor }
                            : {
                                color: tinyColor(
                                  textOnMainBackgroundColor
                                ).setAlpha(
                                  tinyColor.isReadable(
                                    lightContrastColor || mainColor,
                                    textOnMainBackgroundColor
                                  )
                                    ? 0.54
                                    : 0.3
                                ),
                              }
                        }
                        onClick={() => history.push("/")}
                      />
                      {enableDataLab && (
                        <Tab
                          icon={
                            <SvgIcon>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                enable-background="new 0 0 24 24"
                                height="24px"
                                viewBox="0 0 24 24"
                                width="24px"
                              >
                                <g>
                                  <rect fill="none" height="24" width="24" />
                                </g>
                                <g>
                                  <g>
                                    <path d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z" />
                                    <polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93" />
                                    <polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9" />
                                  </g>
                                </g>
                              </svg>
                            </SvgIcon>
                          }
                          label={t`Data Lab`}
                          style={
                            window.location.pathname === "/data"
                              ? { color: lightContrastColor || mainColor }
                              : {
                                  color: tinyColor(
                                    textOnMainBackgroundColor
                                  ).setAlpha(
                                    tinyColor.isReadable(
                                      lightContrastColor || mainColor,
                                      textOnMainBackgroundColor
                                    )
                                      ? 0.54
                                      : 0.3
                                  ),
                                }
                          }
                          onClick={() => history.push("/data")}
                        />
                      )}
                      {enableReports && (
                        <Tab
                          icon={<Assignment />}
                          label={t`Reports`}
                          style={
                            window.location.pathname === "/reports"
                              ? { color: lightContrastColor || mainColor }
                              : {
                                  color: tinyColor(
                                    textOnMainBackgroundColor
                                  ).setAlpha(
                                    tinyColor.isReadable(
                                      lightContrastColor || mainColor,
                                      textOnMainBackgroundColor
                                    )
                                      ? 0.54
                                      : 0.3
                                  ),
                                }
                          }
                          onClick={() => history.push("/reports")}
                        />
                      )}
                    </Tabs>
                  </Box>
                )
              : collectionsHeader}
            <div
              style={{
                display: "flex",
                height: mobile
                  ? showTabs
                    ? "calc(100% - 136px)"
                    : "calc(100% - 64px)"
                  : "100%",
                backgroundColor: mainBackgroundColor,
              }}
            >
              <SwipeableRoutes
                style={{
                  width: "100%",
                  height: mobile
                    ? "calc(100% - env(safe-area-inset-bottom))"
                    : "100%",
                  backgroundColor: mainBackgroundColor,
                }}
                containerStyle={{ height: "100%" }}
                disabled
              >
                {routes}
              </SwipeableRoutes>
            </div>
            {mobile && collectionsHeader}
            <SettingsDialog
              isOpen={this.state.settingsOpen}
              setOpen={(settingsOpen) => this.setState({ settingsOpen })}
              user={this.props.userData.user}
              forceUpdate={this.props.forceUpdate}
              logOut={this.props.logOut}
              client={this.props.client}
            />
            {mobile ? (
              <Zoom
                in={
                  window.location.pathname === "/" &&
                  user &&
                  (user.emailIsVerified
                    ? user.collections.length < 100
                    : !user.collections.length)
                }
                timeout={transitionDuration}
                style={{
                  transitionDelay: `${
                    window.location.pathname === "/" &&
                    user?.flags.reportSendingEnabled
                      ? transitionDuration.exit
                      : 0
                  }ms`,
                }}
                unmountOnExit
              >
                <Fab
                  color="secondary"
                  style={{
                    position: "absolute",
                    bottom: "calc(36px + env(safe-area-inset-bottom))",
                    left:
                      "calc(50% - 28px + " +
                      getNotchHeight("left") +
                      "/2 - " +
                      getNotchHeight("right") +
                      "/2)",
                    zIndex: 1200,
                  }}
                  onClick={() =>
                    this.setState({
                      createCollectionOpen: true,
                    })
                  }
                >
                  <Add />
                </Fab>
              </Zoom>
            ) : (
              <Zoom
                in={
                  window.location.pathname === "/" &&
                  user &&
                  (user.emailIsVerified
                    ? user.collections.length < 100
                    : !user.collections.length)
                }
                timeout={transitionDuration}
                style={{
                  transitionDelay: `${
                    window.location.pathname === "/"
                      ? transitionDuration.exit &&
                        user?.flags.reportSendingEnabled
                      : 0
                  }ms`,
                }}
                unmountOnExit
              >
                <Fab
                  variant="extended"
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      createCollectionOpen: true,
                    })
                  }
                  style={{
                    position: "absolute",
                    right: "calc(16px + " + getNotchHeight("right") + ")",
                    bottom: "calc(16px + env(safe-area-inset-bottom))",
                    transition:
                      "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, left 0s linear, right 0s linear, top 0s linear, bottom 0s linear",
                    zIndex: 20,
                  }}
                >
                  <Add
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  {t(collectionName || "Collection")}
                </Fab>
              </Zoom>
            )}
            {mobile ? (
              <Zoom
                in={
                  window.location.pathname === "/reports" &&
                  user?.flags.reportSendingEnabled
                }
                timeout={transitionDuration}
                style={{
                  transitionDelay: `${
                    window.location.pathname === "/reports"
                      ? transitionDuration.exit
                      : 0
                  }ms`,
                }}
                unmountOnExit
              >
                <Fab
                  color="secondary"
                  style={{
                    position: "absolute",
                    bottom: "calc(36px + env(safe-area-inset-bottom))",
                    left:
                      "calc(50% - 28px + " +
                      getNotchHeight("left") +
                      "/2 - " +
                      getNotchHeight("right") +
                      "/2)",
                    zIndex: 1200,
                  }}
                  onClick={() =>
                    this.setState({
                      createReportOpen: true,
                    })
                  }
                >
                  <Add />
                </Fab>
              </Zoom>
            ) : (
              <Zoom
                in={
                  window.location.pathname === "/reports" &&
                  user?.flags.reportSendingEnabled
                }
                timeout={transitionDuration}
                style={{
                  transitionDelay: `${
                    window.location.pathname === "/reports"
                      ? transitionDuration.exit
                      : 0
                  }ms`,
                }}
                unmountOnExit
              >
                <Fab
                  variant="extended"
                  color="secondary"
                  onClick={() =>
                    this.setState({
                      createReportOpen: true,
                    })
                  }
                  style={{
                    position: "absolute",
                    right: "calc(16px + " + getNotchHeight("right") + ")",
                    bottom: "calc(16px + env(safe-area-inset-bottom))",
                    transition:
                      "all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, left 0s linear, right 0s linear, top 0s linear, bottom 0s linear",
                    zIndex: 20,
                  }}
                >
                  <Add
                    style={{
                      marginRight: "8px",
                    }}
                  />
                  {t(collectionName || "Report")}
                </Fab>
              </Zoom>
            )}
            <Snackbar
              open={snackbarOpen}
              onClose={(_, reason) => {
                if (reason === "clickaway") {
                  return;
                }

                this.setState({ snackbarOpen: false });
              }}
              message={t`No data in this date range`}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              style={
                mobile
                  ? { top: "88px", left: "16px", right: "16px", zIndex: 1200 }
                  : { top: "80px", zIndex: 1200 }
              }
              action={
                <IconButton
                  onClick={() => this.setState({ snackbarOpen: false })}
                  style={{ color: "white" }}
                >
                  <Close />
                </IconButton>
              }
              autoHideDuration={5000}
              className="notSelectable"
            />
            <CreateCollection
              open={createCollectionOpen}
              close={() =>
                this.setState({
                  createCollectionOpen: false,
                })
              }
            />
            {user && (
              <CreateReport
                open={createReportOpen}
                close={() =>
                  this.setState({
                    createReportOpen: false,
                  })
                }
                collections={user?.collections}
              />
            )}
          </>
        );
      }
    }
  )
);
