import { graphql } from "@apollo/react-hoc";
import {
  CenteredSpinner,
  ErrorScreen,
  GenericDialog,
  getNotchHeight,
  isRoleAtLeast,
} from "@igloocloud/igloosharedui";
import Igloo, { User } from "@igloocloud/react-igloo";
import { RemoveCircleOutline, SwapHoriz } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import MoreVert from "@mui/icons-material/MoreVert";
import PersonAdd from "@mui/icons-material/PersonAdd";
import RemoveCircle from "@mui/icons-material/RemoveCircle";
import Avatar from "@mui/material/Avatar";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import IsOnline from "is-online-component";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import tinyColor from "tinycolor2";
import ChangeOwner from "./ChangeOwner";
import ChangeRole from "./ChangeRole";
import InviteUser from "./InviteUser";
import RevokeInvite from "./RevokeInvite";
import RevokeTransfer from "./RevokeTransfer";
import StopSharing from "./StopSharing";

let dialogContent = null;

const errorColor = process.env.REACT_APP_ERROR_COLOR;
const backgroundColor = process.env.REACT_APP_MAIN_BACKGROUND_COLOR;
const textOnBackgroundColor =
  process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR;
const profileIconColors = JSON.parse(process.env.REACT_APP_PROFILE_ICON_COLORS);

export default graphql(
  gql`
    mutation StopSharing($email: String!, $collectionId: ID!) {
      stopSharingCollection(email: $email, collectionId: $collectionId) {
        id
      }
    }
  `,
  {
    name: "StopSharing",
  }
)(
  graphql(
    gql`
      mutation ChangeRole($email: String, $collectionId: ID!, $newRole: Role!) {
        changeRole(
          email: $email
          collectionId: $collectionId
          newRole: $newRole
        ) {
          id
        }
      }
    `,
    {
      name: "ChangeRole",
    }
  )(
    withTranslation()(
      class ShareCollection extends Component {
        constructor(props) {
          super(props);

          this.state = {
            anchorEl: null,
            hasReceivedOpen: false,
            inviteUserOpen: false,
            changeRoleOpen: false,
            stopSharingOpen: false,
            changeOwnerOpen: false,
            revokeTransferOpen: false,
            selectedUserForChangeRoleDialog: "",
            backStackIndex: 0,
            backStackIndex2: 0,
          };
        }

        componentWillReceiveProps(nextProps) {
          if (nextProps.open !== this.props.open && nextProps.open) {
            this.setState({ hasReceivedOpen: true });
          }
        }

        stopSharing = async () => {
          this.setState({ showStopSharingLoading: true });

          await this.props.StopSharing({
            variables: {
              collectionId: this.props.collection.id,
              email: this.state.menuTarget.email,
            },
            optimisticResponse: {
              __typename: "Mutation",
              stopSharingCollection: {
                id: this.props.collection.id,
                email: this.state.menuTarget.email,
                __typename: "Collection",
              },
            },
          });

          this.setState({
            stopSharingOpen: false,
            showStopSharingLoading: false,
          });
        };

        changeRole = (role) => {
          this.props.ChangeRole({
            variables: {
              newRole: role.toUpperCase(),
              collectionId: this.props.collection.id,
              email: this.state.menuTarget.email,
            },
            optimisticResponse: {
              __typename: "Mutation",
              shareCollection: {
                id: this.props.collection.id,
                email: this.state.menuTarget.email,
                newRole: role.toUpperCase(),
                __typename: "Collection",
              },
            },
          });
        };

        handleBackButton = () => {
          if (this.state.backStackIndex === window.backStack) {
            this.handleMenuClose();
          }
        };

        handleMenuClose = () => {
          this.setState({ anchorEl: null });

          if (window.cordova) {
            this.setState({ backStackIndex: 0 });

            --window.backStack;

            document.removeEventListener("backbutton", this.handleBackButton);
          }
        };

        handleBackButton2 = () => {
          if (this.state.backStackIndex2 === window.backStack) {
            this.handleMenuClose2();
          }
        };

        handleMenuClose2 = () => {
          this.setState({ anchorEl2: null });

          if (window.cordova) {
            this.setState({ backStackIndex2: 0 });

            --window.backStack;

            document.removeEventListener("backbutton", this.handleBackButton2);
          }
        };

        render() {
          const { t } = this.props;
          const { REACT_APP_COLLECTION_NAME: collectionName } = process.env;

          return (
            <Igloo
              server={
                localStorage.getItem("server")
                  ? (localStorage.getItem("serverUnsecure") === "true"
                      ? "http://"
                      : "https://") + localStorage.getItem("server")
                  : `https://v1.igloo.ooo`
              }
              bearer={
                localStorage.getItem("accountList") &&
                localStorage.getItem("userId") &&
                JSON.parse(localStorage.getItem("accountList")).filter(
                  (account) => account.id === localStorage.getItem("userId")
                )[0]
                  ? JSON.parse(localStorage.getItem("accountList")).filter(
                      (account) => account.id === localStorage.getItem("userId")
                    )[0].token
                  : ""
              }
            >
              <GenericDialog
                open={this.props.open}
                hidden={
                  this.state.inviteUserOpen ||
                  this.state.changeRoleOpen ||
                  this.state.stopSharingOpen ||
                  this.state.changeOwnerOpen ||
                  this.state.revokeInviteOpen ||
                  this.state.revokeTransferOpen
                }
                close={this.props.close}
                title={t(
                  "Share " + (collectionName?.toLowerCase() || "collection")
                )}
                textButton={t`Close`}
                textButtonFunction={this.props.close}
                noDialogContent
              >
                {this.state.hasReceivedOpen && (
                  <ShareContent
                    collection={this.props.collection}
                    userData={this.props.userData}
                    client={this.props.client}
                    openInviteUser={() =>
                      this.setState({ inviteUserOpen: true })
                    }
                    openChangeRole={() =>
                      this.setState({ changeRoleOpen: true, pending: false })
                    }
                    openStopSharing={() =>
                      this.setState({ stopSharingOpen: true })
                    }
                    openChangeOwner={() =>
                      this.setState({ changeOwnerOpen: true })
                    }
                    openRevokeTransfer={() =>
                      this.setState({ revokeTransferOpen: true })
                    }
                    openMenu={(anchorEl) => this.setState({ anchorEl })}
                    openPendingMenu={(anchorEl2) =>
                      this.setState({ anchorEl2 })
                    }
                    setMenuTarget={(menuTarget) =>
                      this.setState({ menuTarget })
                    }
                    setSelectedUser={(selectedUserForChangeRoleDialog) =>
                      this.setState({ selectedUserForChangeRoleDialog })
                    }
                    t={t}
                  />
                )}
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={this.state.anchorEl}
                  onClose={() => this.handleMenuClose()}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: { backgroundColor },
                  }}
                  TransitionProps={{
                    onEnter: () => {
                      if (window.cordova) {
                        this.setState({ backStackIndex: ++window.backStack });

                        document.addEventListener(
                          "backbutton",
                          this.handleBackButton
                        );
                      }
                    },
                  }}
                >
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    onClick={() => {
                      this.handleMenuClose();

                      this.setState({ changeRoleOpen: true });
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {t`Change role`}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    onClick={() => {
                      this.handleMenuClose();

                      this.setState({ stopSharingOpen: true });
                    }}
                  >
                    <ListItemIcon>
                      <RemoveCircle style={{ color: errorColor }} />
                    </ListItemIcon>
                    <Typography
                      variant="inherit"
                      noWrap
                      style={{ color: errorColor }}
                    >
                      {t`Stop sharing`}
                    </Typography>
                  </MenuItem>
                </Menu>
                <Menu
                  anchorEl={this.state.anchorEl2}
                  open={this.state.anchorEl2}
                  onClose={() => this.handleMenuClose2()}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: { backgroundColor },
                  }}
                  TransitionProps={{
                    onEnter: () => {
                      if (window.cordova) {
                        this.setState({ backStackIndex2: ++window.backStack });

                        document.addEventListener(
                          "backbutton",
                          this.handleBackButton2
                        );
                      }
                    },
                  }}
                >
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    onClick={() => {
                      this.handleMenuClose2();

                      this.setState({ changeRoleOpen: true, pending: true });
                    }}
                  >
                    <ListItemIcon>
                      <Edit />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {t`Change role`}
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    onClick={() => {
                      this.handleMenuClose2();

                      this.setState({ revokeInviteOpen: true });
                    }}
                  >
                    <ListItemIcon>
                      <RemoveCircle style={{ color: errorColor }} />
                    </ListItemIcon>
                    <Typography
                      variant="inherit"
                      noWrap
                      style={{ color: errorColor }}
                    >
                      {t`Revoke invite`}
                    </Typography>
                  </MenuItem>
                </Menu>
              </GenericDialog>
              <RevokeInvite
                open={this.state.revokeInviteOpen}
                close={() => this.setState({ revokeInviteOpen: false })}
                menuTarget={this.state.menuTarget}
              />
              <StopSharing
                open={this.state.stopSharingOpen}
                close={() => this.setState({ stopSharingOpen: false })}
                stopSharing={this.stopSharing}
                menuTarget={this.state.menuTarget}
                showLoading={this.state.showStopSharingLoading}
              />
              <ChangeRole
                open={this.state.changeRoleOpen}
                close={() => this.setState({ changeRoleOpen: false })}
                changeRole={this.changeRole}
                selectedUserType={this.state.selectedUserForChangeRoleDialog}
                menuTarget={this.state.menuTarget}
                pending={this.state.pending}
              />
              <ChangeOwner
                open={this.state.changeOwnerOpen}
                close={() => this.setState({ changeOwnerOpen: false })}
                client={this.props.client}
                collectionId={this.props.collection.id}
              />
              <InviteUser
                open={this.state.inviteUserOpen}
                close={() => this.setState({ inviteUserOpen: false })}
                client={this.props.client}
                collectionId={this.props.collection.id}
              />
              <RevokeTransfer
                open={this.state.revokeTransferOpen}
                close={() => this.setState({ revokeTransferOpen: false })}
                menuTarget={this.state.menuTarget}
              />
            </Igloo>
          );
        }
      }
    )
  )
);

const ShareContent = graphql(
  gql`
    query (
      $id: ID!
      $editorOffset: NaturalNumber
      $editorLimit: NaturalNumber!
      $pendingEditorOffset: NaturalNumber
      $pendingEditorLimit: NaturalNumber!
      $viewerOffset: NaturalNumber
      $viewerLimit: NaturalNumber!
      $pendingViewerOffset: NaturalNumber
      $pendingViewerLimit: NaturalNumber!
    ) {
      collection(id: $id) {
        id
        myRole
        pendingTransfer {
          id
          recipient {
            id
            profileIconColor
            name
            email
          }
        }
        pendingAdminShareCount: pendingShareCount(filter: { role: ADMIN })
        pendingAdminShares: pendingShares(
          offset: $pendingEditorOffset
          limit: $pendingEditorLimit
          filter: { role: ADMIN }
        ) {
          id
          role
          recipient {
            id
            profileIconColor
            name
            email
          }
        }
        pendingEditorShareCount: pendingShareCount(filter: { role: EDITOR })
        pendingEditorShares: pendingShares(
          offset: $pendingEditorOffset
          limit: $pendingEditorLimit
          filter: { role: EDITOR }
        ) {
          id
          role
          recipient {
            id
            profileIconColor
            name
            email
          }
        }
        pendingViewerShareCount: pendingShareCount(filter: { role: VIEWER })
        pendingViewerShares: pendingShares(
          offset: $pendingViewerOffset
          limit: $pendingViewerLimit
          filter: { role: VIEWER }
        ) {
          id
          role
          recipient {
            id
            profileIconColor
            name
            email
          }
        }
        owner {
          id
          email
          name
          profileIconColor
        }
        adminCount
        admins(offset: $editorOffset, limit: $editorLimit) {
          id
          email
          name
          profileIconColor
        }
        editorCount
        editors(offset: $editorOffset, limit: $editorLimit) {
          id
          email
          name
          profileIconColor
        }
        viewerCount
        viewers(offset: $viewerOffset, limit: $viewerLimit) {
          id
          email
          name
          profileIconColor
        }
      }
    }
  `,
  {
    name: "shareData",
    options: ({ collection }) => ({
      variables: {
        editorOffset: 0,
        editorLimit: 20,
        pendingEditorOffset: 0,
        pendingEditorLimit: 20,
        viewerOffset: 0,
        viewerLimit: 20,
        pendingViewerOffset: 0,
        pendingViewerLimit: 20,
        id: collection.id,
      },
    }),
  }
)(
  class ShareDialogContent extends Component {
    constructor(props) {
      super(props);

      this.state = { fetchMoreLoading: false };
    }

    componentDidMount() {
      this.props.shareData.refetch();

      this.props.shareData.subscribeToMore({
        document: gql`
          subscription {
            pendingShareDeclined
          }
        `,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          return {
            collection: {
              ...prev.collection,
              pendingEditorShares: prev.collection.pendingEditorShares.filter(
                (editor) =>
                  editor.id !== subscriptionData.data.pendingShareDeclined
              ),
              pendingViewerShares: prev.collection.pendingViewerShares.filter(
                (viewer) =>
                  viewer.id !== subscriptionData.data.pendingShareDeclined
              ),
            },
          };
        },
      });

      this.props.shareData.subscribeToMore({
        document: gql`
          subscription {
            collectionUpdated {
              id
              pendingTransfer {
                id
                recipient {
                  id
                  profileIconColor
                  name
                  email
                }
              }
            }
          }
        `,
      });

      this.props.shareData.subscribeToMore({
        document: gql`
          subscription {
            pendingShareCreated {
              id
              role
              recipient {
                id
                profileIconColor
                name
                email
              }
            }
          }
        `,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          if (subscriptionData.data.pendingShare.role === "EDITOR") {
            const newEditorShares = [
              ...prev.collection.pendingEditorShares,
              subscriptionData.data.pendingShare,
            ];

            return {
              collection: {
                ...prev.collection,
                pendingEditorShares: newEditorShares,
              },
            };
          }

          if (subscriptionData.data.pendingShare.role === "VIEWER") {
            const newViewerShares = [
              ...prev.collection.pendingViewerShares,
              subscriptionData.data.pendingShare,
            ];

            return {
              collection: {
                ...prev.collection,
                pendingViewerShares: newViewerShares,
              },
            };
          }
        },
      });

      this.props.shareData.subscribeToMore({
        document: gql`
          subscription {
            pendingShareRevoked
          }
        `,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          return {
            collection: {
              ...prev.collection,
              editors: prev.collection.editors.filter(
                (editor) =>
                  editor.id !== subscriptionData.data.pendingShareRevoked
              ),
              viewers: prev.collection.viewers.filter(
                (viewer) =>
                  viewer.id !== subscriptionData.data.pendingShareRevoked
              ),
            },
          };
        },
      });

      this.props.shareData.subscribeToMore({
        document: gql`
          subscription {
            userLeftCollection {
              user {
                id
              }
              collection {
                id
              }
            }
          }
        `,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          if (
            subscriptionData.data.userLeftCollection.collection.id !==
            prev.collection.id
          ) {
            return prev;
          }

          return {
            collection: {
              ...prev.collection,
              editors: prev.collection.editors.filter(
                (editor) =>
                  editor.id !== subscriptionData.data.userLeftCollection.user.id
              ),
              viewer: prev.collection.viewers.filter(
                (viewer) =>
                  viewer.id !== subscriptionData.data.userLeftCollection.user.id
              ),
            },
          };
        },
      });

      const pendingShareAcceptedSubscription = gql`
        subscription {
          pendingShareAccepted {
            id
            role
            recipient {
              id
              profileIconColor
              name
              email
            }
          }
        }
      `;

      this.props.shareData.subscribeToMore({
        document: pendingShareAcceptedSubscription,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev;
          }

          return {
            collection: {
              ...prev.collection,
              editors:
                subscriptionData.data.pendingShareAccepted.role === "EDITOR"
                  ? [
                      ...prev.collection.editors,
                      subscriptionData.data.pendingShareAccepted.recipient,
                    ]
                  : prev.collection.editors,
              pendingEditorShares: prev.collection.pendingEditorShares.filter(
                (editor) =>
                  editor.id !== subscriptionData.data.pendingShareAccepted.id
              ),
              viewers:
                subscriptionData.data.pendingShareAccepted.role === "VIEWER"
                  ? [
                      ...prev.collection.viewers,
                      subscriptionData.data.pendingShareAccepted.recipient,
                    ]
                  : prev.collection.viewers,
              pendingViewerShares: prev.collection.pendingViewerShares.filter(
                (viewer) =>
                  viewer.id !== subscriptionData.data.pendingShareAccepted.id
              ),
            },
          };
        },
      });
    }

    queryMore = async () => {
      if (!this.queryMore.locked) {
        //EDITORS
        if (
          this.props.shareData.collection.editorCount >
          this.props.shareData.collection.editors.length
        ) {
          this.queryMore.locked = true;

          try {
            this.setState({ fetchMoreLoading: true });
            await this.props.shareData.fetchMore({
              variables: {
                offset: this.props.shareData.collection.editors.length,
                limit:
                  this.props.shareData.collection.editorCount -
                    this.props.shareData.collection.editors.length >=
                  20
                    ? 20
                    : this.props.shareData.collection.editorCount % 20,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const newEditors = [
                  ...prev.collection.editors,
                  ...fetchMoreResult.collection.editors,
                ];

                return {
                  collection: {
                    ...prev.collection,
                    editors: newEditors,
                  },
                };
              },
            });
          } finally {
            this.setState(() => {
              this.queryMore.locked = false;

              return { fetchMoreLoading: false };
            });
          }
        } else {
          //PENDING EDITORS
          if (
            this.props.shareData.collection.pendingEditorShareCount >
            this.props.shareData.collection.pendingEditorShares.length
          ) {
            this.queryMore.locked = true;

            try {
              this.setState({ fetchMoreLoading: true });
              await this.props.shareData.fetchMore({
                variables: {
                  offset:
                    this.props.shareData.collection.pendingEditorShares.length,
                  limit:
                    this.props.shareData.collection.pendingEditorShareCount -
                      this.props.shareData.collection.pendingEditorShares
                        .length >=
                    20
                      ? 20
                      : this.props.shareData.collection
                          .pendingEditorShareCount % 20,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) {
                    return prev;
                  }

                  const newPendingEditorShares = [
                    ...prev.collection.pendingEditorShares,
                    ...fetchMoreResult.collection.pendingEditorShares,
                  ];

                  return {
                    collection: {
                      ...prev.collection,
                      editors: newPendingEditorShares,
                    },
                  };
                },
              });
            } finally {
              this.setState(() => {
                this.queryMore.locked = false;

                return { fetchMoreLoading: false };
              });
            }
          } else {
            //VIEWERS
            if (
              this.props.shareData.collection.viewerCount >
              this.props.shareData.collection.viewers.length
            ) {
              this.queryMore.locked = true;

              try {
                this.setState({ fetchMoreLoading: true });
                await this.props.shareData.fetchMore({
                  variables: {
                    offset: this.props.shareData.collection.viewers.length,
                    limit:
                      this.props.shareData.collection.viewerCount -
                        this.props.shareData.collection.viewers.length >=
                      20
                        ? 20
                        : this.props.shareData.collection.viewerCount % 20,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) {
                      return prev;
                    }

                    const newViewers = [
                      ...prev.collection.viewers,
                      ...fetchMoreResult.collection.viewers,
                    ];

                    return {
                      collection: {
                        ...prev.collection,
                        editors: newViewers,
                      },
                    };
                  },
                });
              } finally {
                this.setState(() => {
                  this.queryMore.locked = false;

                  return { fetchMoreLoading: false };
                });
              }
            } else {
              if (
                this.props.shareData.collection.pendingViewerShareCount >
                this.props.shareData.collection.pendingViewerShares.length
              ) {
                this.queryMore.locked = true;

                try {
                  this.setState({ fetchMoreLoading: true });
                  await this.props.shareData.fetchMore({
                    variables: {
                      offset:
                        this.props.shareData.collection.pendingViewerShares
                          .length,
                      limit:
                        this.props.shareData.collection
                          .pendingViewerShareCount -
                          this.props.shareData.collection.pendingViewerShares
                            .length >=
                        20
                          ? 20
                          : this.props.shareData.collection
                              .pendingViewerShareCount % 20,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return prev;
                      }

                      const newPendingViewerShares = [
                        ...prev.collection.pendingViewerShares,
                        ...fetchMoreResult.collection.pendingViewerShares,
                      ];

                      return {
                        collection: {
                          ...prev.collection,
                          editors: newPendingViewerShares,
                        },
                      };
                    },
                  });
                } finally {
                  this.setState(() => {
                    this.queryMore.locked = false;

                    return { fetchMoreLoading: false };
                  });
                }
              }
            }
          }
        }
      }
    };

    getInitials = (string) => {
      if (string) {
        let names = string.trim().split(" "),
          initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      }
    };

    render() {
      const {
        shareData: { loading, error, collection, refetch },
        fullScreen,
        t,
      } = this.props;

      const {
        REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
        REACT_APP_OWNER_NAME: ownerName,
        REACT_APP_ADMINS_NAME: adminsName,
        REACT_APP_EDITORS_NAME: editorsName,
        REACT_APP_VIEWERS_NAME: viewersName,
      } = process.env;

      const subheaderStyle = {
        paddingLeft: fullScreen
          ? "calc(16px + " + getNotchHeight("left") + ")"
          : "16px",
        paddingRight: fullScreen
          ? "calc(16px + " + getNotchHeight("right") + ")"
          : "16px",
      };

      const listItemStyle = {
        paddingLeft: fullScreen
          ? "calc(24px + " + getNotchHeight("left") + ")"
          : "24px",
        paddingRight: fullScreen
          ? "calc(24px + " + getNotchHeight("right") + ")"
          : "24px",
      };

      if (loading)
        dialogContent = (
          <div style={{ height: "100%" }}>
            <CenteredSpinner />
          </div>
        );

      if (error)
        dialogContent = (
          <div style={{ height: "100%" }}>
            <ErrorScreen
              refetch={() =>
                refetch({
                  editorOffset: 0,
                  editorLimit: 20,
                  pendingEditorOffset: 0,
                  pendingEditorLimit: 20,
                  viewerOffset: 0,
                  viewerLimit: 20,
                  pendingViewerOffset: 0,
                  pendingViewerLimit: 20,
                  id: collection.id,
                })
              }
              error={error}
            />
          </div>
        );

      if (collection)
        dialogContent = (
          <DialogContent
            style={{ padding: 0 }}
            onScroll={(event) => {
              if (
                event.target.scrollTop + event.target.clientHeight >=
                event.target.scrollHeight - 600
              )
                this.queryMore();
            }}
          >
            <IsOnline
              onChange={(online) => {
                if (online) {
                  this.props.shareData.refetch();
                }
              }}
            />
            <List subheader={<li />} style={{ height: "100%", padding: "0" }}>
              {isRoleAtLeast(collection.myRole, "ADMIN") && (
                <ListItem
                  button
                  onClick={() => this.props.openInviteUser()}
                  style={listItemStyle}
                >
                  <ListItemAvatar>
                    <Avatar
                      style={{
                        backgroundColor: "transparent",
                      }}
                    >
                      <PersonAdd
                        style={{
                          color: textColor,
                        }}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <font
                        style={{
                          color: textColor,
                        }}
                      >
                        {t`Send an invite`}
                      </font>
                    }
                  />
                </ListItem>
              )}
              <li key="Owner">
                <ul style={{ padding: "0" }}>
                  <ListSubheader
                    style={{
                      color: tinyColor(textOnBackgroundColor)
                        .setAlpha(0.54)
                        .toRgbString(),
                      backgroundColor,
                      cursor: "default",
                      ...subheaderStyle,
                    }}
                    className="notSelectable defaultCursor"
                  >
                    {t`Owner`}
                  </ListSubheader>
                  <User
                    id={collection.owner.id}
                    fields={["profileIconColor", "name", "email"]}
                    onDelete={() => refetch()}
                    privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                  >
                    {({ data }) => (
                      <ListItem key={collection.owner.id} style={listItemStyle}>
                        <ListItemAvatar>
                          <Avatar
                            style={{
                              backgroundColor:
                                profileIconColors[
                                  (data
                                    ? data.profileIconColor
                                    : collection.owner.profileIconColor) %
                                    profileIconColors.length
                                ][0],
                              color:
                                profileIconColors[
                                  (data
                                    ? data.profileIconColor
                                    : collection.owner.profileIconColor) %
                                    profileIconColors.length
                                ][1],
                            }}
                          >
                            {this.getInitials(
                              data ? data.name : collection.owner.name
                            )}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <font
                              style={{
                                color: textColor,
                              }}
                            >
                              {this.props.userData.user.email ===
                              (data?.email || collection.owner.email)
                                ? t`You`
                                : data
                                ? data.name
                                : collection.owner.name}
                            </font>
                          }
                          secondary={
                            <font
                              style={{
                                color: tinyColor(textOnBackgroundColor)
                                  .setAlpha(0.72)
                                  .toRgbString(),
                              }}
                            >
                              {this.props.userData.user.email ===
                              ((data && data.email) || collection.owner.email)
                                ? ""
                                : data
                                ? data.email
                                : collection.owner.email}
                            </font>
                          }
                        />
                        {this.props.userData.user.email ===
                          ((data && data.email) || collection.owner.email) && (
                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={this.props.openChangeOwner}
                              size="large"
                            >
                              <SwapHoriz />
                            </IconButton>
                          </ListItemSecondaryAction>
                        )}
                      </ListItem>
                    )}
                  </User>
                  {isRoleAtLeast(collection.myRole, "ADMIN") &&
                    collection.pendingTransfer && (
                      <User
                        id={collection.pendingTransfer.recipient.id}
                        fields={["profileIconColor", "name", "email"]}
                        onDelete={() => refetch()}
                        privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                      >
                        {({ data }) => (
                          <ListItem
                            key={collection.pendingTransfer.id}
                            style={listItemStyle}
                          >
                            <ListItemAvatar>
                              <Avatar
                                style={{
                                  backgroundColor:
                                    profileIconColors[
                                      (data
                                        ? data.profileIconColor
                                        : collection.pendingTransfer.recipient
                                            .profileIconColor) %
                                        profileIconColors.length
                                    ][0],
                                  color:
                                    profileIconColors[
                                      (data
                                        ? data.profileIconColor
                                        : collection.pendingTransfer.recipient
                                            .profileIconColor) %
                                        profileIconColors.length
                                    ][1],
                                }}
                              >
                                {this.getInitials(
                                  data
                                    ? data.name
                                    : collection.pendingTransfer.recipient.name
                                )}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={
                                <font
                                  style={{
                                    color: textColor,
                                  }}
                                >
                                  {this.props.userData.user.email ===
                                  (data?.email ||
                                    collection.pendingTransfer.recipient.email)
                                    ? t`You`
                                    : data
                                    ? data.name
                                    : collection.pendingTransfer.recipient.name}
                                  <font
                                    style={{
                                      color: tinyColor(textOnBackgroundColor)
                                        .setAlpha(0.72)
                                        .toRgbString(),
                                    }}
                                  >
                                    {" (" + t`pending` + ")"}
                                  </font>
                                </font>
                              }
                              secondary={
                                <font
                                  style={{
                                    color: tinyColor(textOnBackgroundColor)
                                      .setAlpha(0.72)
                                      .toRgbString(),
                                  }}
                                >
                                  {data
                                    ? data.email
                                    : collection.pendingTransfer.recipient
                                        .email}
                                </font>
                              }
                            />
                            {(collection.myRole === "EDITOR" ||
                              collection.myRole === "OWNER") && (
                              <ListItemSecondaryAction>
                                <IconButton
                                  onClick={(event) => {
                                    this.props.setMenuTarget(
                                      collection.pendingTransfer
                                    );
                                    this.props.openRevokeTransfer();
                                  }}
                                  size="large"
                                >
                                  <RemoveCircleOutline />
                                </IconButton>
                              </ListItemSecondaryAction>
                            )}
                          </ListItem>
                        )}
                      </User>
                    )}
                </ul>
              </li>
              {((collection.admins && collection.admins[0]) ||
                (collection.pendingAdminShares &&
                  collection.pendingAdminShares[0])) && (
                <li>
                  <ul style={{ padding: "0" }}>
                    <ListSubheader
                      style={{
                        color: tinyColor(textOnBackgroundColor)
                          .setAlpha(0.54)
                          .toRgbString(),
                        backgroundColor,
                        cursor: "default",
                        ...subheaderStyle,
                      }}
                    >
                      {t(adminsName || "Admins")}
                    </ListSubheader>
                    {collection.admins &&
                      collection.admins[0] &&
                      collection.admins.map((item) => (
                        <User
                          id={item.id}
                          key={item.id}
                          fields={["profileIconColor", "name", "email"]}
                          onDelete={() => refetch()}
                          privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                        >
                          {({ data }) => (
                            <ListItem key={item.id} style={listItemStyle}>
                              <ListItemAvatar>
                                <Avatar
                                  style={{
                                    backgroundColor:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.profileIconColor) %
                                          profileIconColors.length
                                      ][0],
                                    color:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.profileIconColor) %
                                          profileIconColors.length
                                      ][1],
                                  }}
                                >
                                  {this.getInitials(
                                    data ? data.name : item.name
                                  )}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <font
                                    style={{
                                      color: textColor,
                                    }}
                                  >
                                    {this.props.userData.user.email ===
                                    ((data && data.email) || item.email)
                                      ? t`You`
                                      : (data && data.name) || item.name}
                                  </font>
                                }
                                secondary={
                                  <font
                                    style={{
                                      color: tinyColor(textOnBackgroundColor)
                                        .setAlpha(0.72)
                                        .toRgbString(),
                                    }}
                                  >
                                    {this.props.userData.user.email ===
                                    ((data && data.email) || item.email)
                                      ? ""
                                      : (data && data.email) || item.email}
                                  </font>
                                }
                              />
                              {isRoleAtLeast(collection.myRole, "ADMIN") &&
                                this.props.userData.user.email !==
                                  ((data && data.email) || item.email) && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={(event) => {
                                        this.props.openMenu(
                                          event.currentTarget
                                        );
                                        this.props.setMenuTarget(item);
                                        this.props.setSelectedUser("editor");
                                      }}
                                      size="large"
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                            </ListItem>
                          )}
                        </User>
                      ))}
                    {collection.pendingAdminShares &&
                      collection.pendingAdminShares[0] &&
                      collection.pendingAdminShares.map((item) => (
                        <User
                          key={"pending-admin-share-" + item.id}
                          id={item.recipient.id}
                          fields={["profileIconColor", "name", "email"]}
                          onDelete={() => {
                            refetch();
                          }}
                          privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                        >
                          {({ data }) => (
                            <ListItem key={item.id} style={listItemStyle}>
                              <ListItemAvatar>
                                <Avatar
                                  style={{
                                    backgroundColor:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.recipient.profileIconColor) %
                                          profileIconColors.length
                                      ][0],
                                    color:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.recipient.profileIconColor) %
                                          profileIconColors.length
                                      ][1],
                                  }}
                                >
                                  {this.getInitials(
                                    data ? data.name : item.recipient.name
                                  )}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <font
                                    style={{
                                      color: textColor,
                                    }}
                                  >
                                    {data ? data.name : item.recipient.name}
                                    <font
                                      style={{
                                        color: tinyColor(textOnBackgroundColor)
                                          .setAlpha(0.72)
                                          .toRgbString(),
                                      }}
                                    >
                                      {" (" + t`pending` + ")"}
                                    </font>
                                  </font>
                                }
                                secondary={
                                  <font
                                    style={{
                                      color: tinyColor(textOnBackgroundColor)
                                        .setAlpha(0.72)
                                        .toRgbString(),
                                    }}
                                  >
                                    {data ? data.email : item.recipient.email}
                                  </font>
                                }
                              />
                              {isRoleAtLeast(collection.myRole, "ADMIN") && (
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={(event) => {
                                      this.props.setMenuTarget(item);
                                      this.props.setSelectedUser("editor");
                                      this.props.openPendingMenu(
                                        event.currentTarget
                                      );
                                    }}
                                    size="large"
                                  >
                                    <MoreVert />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                          )}
                        </User>
                      ))}
                  </ul>
                </li>
              )}
              {((collection.editors && collection.editors[0]) ||
                (collection.pendingEditorShares &&
                  collection.pendingEditorShares[0])) && (
                <li>
                  <ul style={{ padding: "0" }}>
                    <ListSubheader
                      style={{
                        color: tinyColor(textOnBackgroundColor)
                          .setAlpha(0.54)
                          .toRgbString(),
                        backgroundColor,
                        cursor: "default",
                        ...subheaderStyle,
                      }}
                    >
                      {t(editorsName || "Editors")}
                    </ListSubheader>
                    {collection.editors &&
                      collection.editors[0] &&
                      collection.editors.map((item) => (
                        <User
                          id={item.id}
                          key={item.id}
                          fields={["profileIconColor", "name", "email"]}
                          onDelete={() => refetch()}
                          privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                        >
                          {({ data }) => (
                            <ListItem key={item.id} style={listItemStyle}>
                              <ListItemAvatar>
                                <Avatar
                                  style={{
                                    backgroundColor:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.profileIconColor) %
                                          profileIconColors.length
                                      ][0],
                                    color:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.profileIconColor) %
                                          profileIconColors.length
                                      ][1],
                                  }}
                                >
                                  {this.getInitials(
                                    data ? data.name : item.name
                                  )}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <font
                                    style={{
                                      color: textColor,
                                    }}
                                  >
                                    {this.props.userData.user.email ===
                                    ((data && data.email) || item.email)
                                      ? t`You`
                                      : (data && data.name) || item.name}
                                  </font>
                                }
                                secondary={
                                  <font
                                    style={{
                                      color: tinyColor(textOnBackgroundColor)
                                        .setAlpha(0.72)
                                        .toRgbString(),
                                    }}
                                  >
                                    {this.props.userData.user.email ===
                                    ((data && data.email) || item.email)
                                      ? ""
                                      : (data && data.email) || item.email}
                                  </font>
                                }
                              />
                              {isRoleAtLeast(collection.myRole, "ADMIN") &&
                                this.props.userData.user.email !==
                                  ((data && data.email) || item.email) && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={(event) => {
                                        this.props.openMenu(
                                          event.currentTarget
                                        );
                                        this.props.setMenuTarget(item);
                                        this.props.setSelectedUser("editor");
                                      }}
                                      size="large"
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                            </ListItem>
                          )}
                        </User>
                      ))}
                  </ul>
                </li>
              )}
              {((collection.viewers && collection.viewers[0]) ||
                (collection.pendingViewerShares &&
                  collection.pendingViewerShares[0])) && (
                <li>
                  <ul style={{ padding: "0" }}>
                    <ListSubheader
                      style={{
                        color: tinyColor(textOnBackgroundColor)
                          .setAlpha(0.54)
                          .toRgbString(),
                        backgroundColor,
                        cursor: "default",
                        ...subheaderStyle,
                      }}
                    >
                      {t(viewersName || "Viewers")}
                    </ListSubheader>
                    {collection.viewers &&
                      collection.viewers[0] &&
                      collection.viewers.map((item) => (
                        <User
                          key={item.id}
                          id={item.id}
                          fields={["profileIconColor", "name", "email"]}
                          onDelete={() => refetch()}
                          privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                        >
                          {({ data }) => (
                            <ListItem key={item.id} style={listItemStyle}>
                              <ListItemAvatar>
                                <Avatar
                                  style={{
                                    backgroundColor:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.profileIconColor) %
                                          profileIconColors.length
                                      ][0],
                                    color:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.profileIconColor) %
                                          profileIconColors.length
                                      ][1],
                                  }}
                                >
                                  {this.getInitials(
                                    data ? data.name : item.name
                                  )}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <font
                                    style={{
                                      color: textColor,
                                    }}
                                  >
                                    {this.props.userData.user.email ===
                                    ((data && data.email) || item.email)
                                      ? t`You`
                                      : (data && data.name) || item.name}
                                  </font>
                                }
                                secondary={
                                  <font
                                    style={{
                                      color: tinyColor(textOnBackgroundColor)
                                        .setAlpha(0.72)
                                        .toRgbString(),
                                    }}
                                  >
                                    {this.props.userData.user.email ===
                                    ((data && data.email) || item.email)
                                      ? ""
                                      : (data && data.email) || item.email}
                                  </font>
                                }
                              />
                              {isRoleAtLeast(collection.myRole, "ADMIN") &&
                                this.props.userData.user.email !==
                                  ((data && data.email) || item.email) && (
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={(event) => {
                                        this.props.openMenu(
                                          event.currentTarget
                                        );
                                        this.props.setMenuTarget(item);
                                        this.props.setSelectedUser("viewer");
                                      }}
                                      size="large"
                                    >
                                      <MoreVert />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                )}
                            </ListItem>
                          )}
                        </User>
                      ))}
                    {collection.pendingViewerShares &&
                      collection.pendingViewerShares[0] &&
                      collection.pendingViewerShares.map((item) => (
                        <User
                          key={"pending-viewer-share-" + item.id}
                          id={item.recipient.id}
                          fields={["profileIconColor", "name", "email"]}
                          onDelete={() => {
                            refetch();
                          }}
                          privateCloud={process.env.REACT_APP_PRIVATE_CLOUD}
                        >
                          {({ data }) => (
                            <ListItem key={item.id} style={listItemStyle}>
                              <ListItemAvatar>
                                <Avatar
                                  style={{
                                    backgroundColor:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.recipient.profileIconColor) %
                                          profileIconColors.length
                                      ][0],
                                    color:
                                      profileIconColors[
                                        (data
                                          ? data.profileIconColor
                                          : item.recipient.profileIconColor) %
                                          profileIconColors.length
                                      ][1],
                                  }}
                                >
                                  {this.getInitials(
                                    data ? data.name : item.recipient.name
                                  )}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={
                                  <font
                                    style={{
                                      color: textColor,
                                    }}
                                  >
                                    {data ? data.name : item.recipient.name}
                                    <font
                                      style={{
                                        color: tinyColor(textOnBackgroundColor)
                                          .setAlpha(0.72)
                                          .toRgbString(),
                                      }}
                                    >
                                      {" (" + t`pending` + ")"}
                                    </font>
                                  </font>
                                }
                                secondary={
                                  <font
                                    style={{
                                      color: tinyColor(textOnBackgroundColor)
                                        .setAlpha(0.72)
                                        .toRgbString(),
                                    }}
                                  >
                                    {data ? data.email : item.recipient.email}
                                  </font>
                                }
                              />
                              {isRoleAtLeast(collection.myRole, "ADMIN") && (
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={(event) => {
                                      this.props.setMenuTarget(item);
                                      this.props.setSelectedUser("viewer");
                                      this.props.openPendingMenu(
                                        event.currentTarget
                                      );
                                    }}
                                    size="large"
                                  >
                                    <MoreVert />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              )}
                            </ListItem>
                          )}
                        </User>
                      ))}
                  </ul>
                </li>
              )}
            </List>
            {this.state.fetchMoreLoading && <LinearProgress />}
          </DialogContent>
        );

      return dialogContent;
    }
  }
);
