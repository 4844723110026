import React, { Component } from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import { graphql } from "@apollo/react-hoc"
import gql from "graphql-tag"
import { withTranslation } from "react-i18next"

export default graphql(
  gql`
    mutation RevokePendingTransfer($id: ID!) {
      revokePendingTransfer(id: $id)
    }
  `,
  {
    name: "RevokePendingTransfer",
  }
)(
  withTranslation()(
    class RevokeTransfer extends Component {
      constructor(props) {
        super(props)

        this.state = { showLoading: false }
      }

      revokeInvite = async () => {
        this.setState({ showLoading: true })

        try {
          await this.props.RevokePendingTransfer({
            variables: {
              id: this.props.menuTarget.id,
            },
            optimisticResponse: {
              __typename: "Mutation",
              revokePendingTransfer: {
                id: this.props.menuTarget.id,
                __typename: "PendingTransfer",
              },
            },
          })

          this.setState({ showLoading: false })

          this.props.close()
        } catch (e) {
          this.setState({ showLoading: false })
        }
      }

      render() {
        const { t } = this.props

        return (
          <GenericDialog
            open={this.props.open}
            close={this.props.close}
            title={t`Revoke invite`}
            textButton={t`Close`}
            textButtonFunction={this.props.close}
            isContainedButtonRed
            containedButton={t`Revoke`}
            containedButtonFunction={this.revokeInvite}
            containedButtonLoading={this.state.showLoading}
            containedButtonDisabled={this.state.showLoading}
          >
            {t`Are you sure you want to revoke your invite to ` +
              (this.props.menuTarget &&
                this.props.menuTarget.recipient &&
                this.props.menuTarget.recipient.name) +
              "?"}
          </GenericDialog>
        )
      }
    }
  )
)
