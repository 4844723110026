import React, { Component } from "react"
import { ApolloClient } from "apollo-client"
import { HttpLink } from "apollo-link-http"
import { ApolloLink } from "apollo-link"
import { onError } from "apollo-link-error"
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory"
import { ApolloProvider } from "@apollo/client"
import { WebSocketLink } from "apollo-link-ws"
import { split } from "apollo-link"
import { getMainDefinition } from "apollo-utilities"
import introspectionQueryResultData from "./fragmentTypes.json"
import UserFetcher from "./UserFetcher"

export default class AuthenticatedApp extends Component {
  constructor(props) {
    super(props)

    const bearer = props.bearer
    const wsLink = new WebSocketLink({
      uri: localStorage.getItem("server")
        ? (localStorage.getItem("serverUnsecure") === "true"
            ? "ws://"
            : "wss://") +
          localStorage.getItem("server") +
          "/subscriptions"
        : `wss://v1.igloo.ooo/subscriptions`,
      options: {
        reconnect: true,
        connectionParams: {
          Authorization: "Bearer " + bearer,
        },
      },
    })

    const httpLink = new HttpLink({
      uri: localStorage.getItem("server")
        ? (localStorage.getItem("serverUnsecure") === "true"
            ? "http://"
            : "https://") +
          localStorage.getItem("server") +
          "/graphql"
        : `https://v1.igloo.ooo/graphql`,
      headers: {
        Authorization: "Bearer " + bearer,
      },
    })

    const errorLink = onError(({ networkError, graphQLErrors }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach((error) => {
          if (error.message === "This user doesn't exist anymore") {
            this.props.logOut(true)
          }
        })
      }

      if (
        networkError &&
        (networkError.message ===
          "The token is invalid, expired, revoked or malformed" ||
          networkError.message === "Token expired" ||
          networkError.message === "Malformed JWT" ||
          networkError.message ===
            "User doesn't exist. Use `signUp` to create one")
      )
        this.props.logOut()
    })

    const requestLink = split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query)
        return kind === "OperationDefinition" && operation === "subscription"
      },
      wsLink,
      httpLink
    )

    const link = ApolloLink.from([errorLink, requestLink])

    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData,
    })

    this.client = new ApolloClient({
      // By default, this client will send queries to the
      //  `/graphql` endpoint on the same address
      link,
      cache: new InMemoryCache({ fragmentMatcher }),
    })
  }

  componentDidMount() {
    if (window.cordova) {
      window.StatusBar.backgroundColorByHexString("#00000000")
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bearer !== this.props.bearer) {
      const bearer = nextProps.bearer
      const wsLink = new WebSocketLink({
        uri: localStorage.getItem("server")
          ? (localStorage.getItem("serverUnsecure") === "true"
              ? "ws://"
              : "wss://") +
            localStorage.getItem("server") +
            "/subscriptions"
          : `wss://v1.igloo.ooo/subscriptions`,
        options: {
          reconnect: true,
          connectionParams: {
            Authorization: "Bearer " + bearer,
          },
        },
      })

      const httpLink = new HttpLink({
        uri: localStorage.getItem("server")
          ? (localStorage.getItem("serverUnsecure") === "true"
              ? "http://"
              : "https://") +
            localStorage.getItem("server") +
            "/graphql"
          : `https://v1.igloo.ooo/graphql`,
        headers: {
          Authorization: "Bearer " + bearer,
        },
      })

      const errorLink = onError(({ networkError }) => {
        if (
          networkError &&
          (networkError.message ===
            "The token is invalid, expired, revoked or malformed" ||
            networkError.message === "This user doesn't exist anymore" ||
            networkError.message === "Token expired")
        )
          this.props.logOut()
      })

      const link = ApolloLink.from([errorLink, wsLink, httpLink])

      const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData,
      })

      this.client = new ApolloClient({
        // By default, this client will send queries to the
        //  `/graphql` endpoint on the same address
        link,
        cache: new InMemoryCache({ fragmentMatcher }),
      })
    }
  }

  render() {
    return (
      <ApolloProvider client={this.client}>
        <UserFetcher
          isMobile={this.props.isMobile}
          logOut={this.props.logOut}
          changeAccount={this.props.changeAccount}
          changeBearer={this.props.changeBearer}
          forceUpdate={this.props.forceUpdate}
          client={this.client}
          changeEmailBearer={this.props.changeEmailBearer}
          changeAuthenticationBearer={this.props.changeAuthenticationBearer}
          deleteUserBearer={this.props.deleteUserBearer}
          manageAccessTokensBearer={this.props.manageAccessTokensBearer}
        />
      </ApolloProvider>
    )
  }
}
