import { Checkbox, Chip, MenuItem, TextField } from "@mui/material";
import tinyColor from "tinycolor2";
import { Thing } from "../../CreateReport.types";
import { Props } from "./ChipInput.types";

const menuItemStyle = (
  mainBackgroundColor: string,
  textOnMainBackgroundColor: string
) => ({
  "&.Mui-focusVisible": {
    backgroundColor: mainBackgroundColor,
  },
  "&.Mui-selected": {
    backgroundColor: mainBackgroundColor,
    "&.Mui-focusVisible": {
      backgroundColor: mainBackgroundColor,
    },
    "&:hover": {
      backgroundColor: tinyColor(textOnMainBackgroundColor)
        .setAlpha(0.04)
        .toHex8String(),
    },
  },
});

export default ({
  setBlocks,
  mainBackgroundColor,
  textOnMainBackgroundColor,
  things,
  variables,
  variableIds,
  localId,
  label,
}: Props) => (
  <TextField
    required
    variant="outlined"
    fullWidth
    label={label}
    select
    InputLabelProps={{
      style: {
        backgroundColor: mainBackgroundColor,
      },
    }}
    SelectProps={{
      SelectDisplayProps: {
        style: {
          height: "56px",
          padding: "0",
        },
      },
      IconComponent: null,
      multiple: true,
      renderValue: (selected: string[]) => (
        <div
          style={{
            padding: "12px",
            maxWidth: "100%",
            overflow: "scroll",
          }}
        >
          {things
            .filter(({ variables }: Thing) =>
              variables.some(({ id }) => selected.includes(id))
            )
            .map(({ id, name, variables }: Thing, thingIndex: number) => (
              <>
                <Chip
                  key={"create-report-thing-series-chip-" + id}
                  label={name}
                  style={{
                    cursor: "pointer",
                    marginRight: "8px",
                  }}
                />
                {variables
                  .filter(({ id }) => selected.includes(id))
                  .map(({ id, name }, variableIndex: number) => (
                    <Chip
                      variant="outlined"
                      key={"create-report-series-chip-" + id}
                      label={name}
                      style={{
                        cursor: "pointer",
                        marginRight:
                          thingIndex === things.length - 1 &&
                          variableIndex === variables.length - 1
                            ? 0
                            : "8px",
                      }}
                    />
                  ))}
              </>
            ))}
        </div>
      ),
    }}
    value={variableIds}
    onChange={(event: any) =>
      setBlocks((blocks) =>
        blocks.map((block) =>
          block.localId === localId
            ? {
                ...block,
                variableIds: event.target.value.some((id) =>
                  things
                    .find(({ id: idToFind }: { id: string }) => idToFind === id)
                    ?.variables.every(({ id }) => variableIds.includes(id))
                )
                  ? (() => {
                      const thing = things.find(
                        ({ id: idToFind }: { id: string }) =>
                          event.target.value.includes(idToFind)
                      );

                      return event.target.value.filter(
                        (id: string) =>
                          !thing.variables.find(
                            ({ id: idToFind }: { id: string }) =>
                              idToFind === id
                          ) && id !== thing.id
                      );
                    })()
                  : event.target.value
                      .flatMap((id: string) =>
                        variables.find(
                          ({ id: idToFind }: { id: string }) => idToFind === id
                        )
                          ? id
                          : things
                              .find(
                                ({ id: idToFind }: { id: string }) =>
                                  idToFind === id
                              )
                              .variables.map(({ id }) => id)
                      )
                      .filter(
                        (id, position, array) => array.indexOf(id) === position
                      ),
              }
            : block
        )
      )
    }
  >
    {things.map(({ id, name, variables }) => [
      <MenuItem
        value={id}
        sx={menuItemStyle(mainBackgroundColor, textOnMainBackgroundColor)}
        key={"create-report-dialog-series-picker-thing-" + id}
      >
        <Checkbox
          disableRipple
          checked={variables.every(({ id }) => variableIds.includes(id))}
          indeterminate={
            variables.some(({ id }) => variableIds.includes(id)) &&
            !variables.every(({ id }) => variableIds.includes(id))
          }
        />
        {name}
      </MenuItem>,
      ...variables.map(({ id, name }) => (
        <MenuItem
          value={id}
          sx={{
            paddingLeft: "32px",
            ...menuItemStyle(mainBackgroundColor, textOnMainBackgroundColor),
          }}
        >
          <Checkbox disableRipple checked={variableIds.indexOf(id) > -1} />
          {name}
        </MenuItem>
      )),
    ])}
  </TextField>
);
