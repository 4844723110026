import React, { Component } from "react";
import { graphql } from "@apollo/react-hoc";
import gql from "graphql-tag";
import { GenericDialog, isEmailValid } from "@igloocloud/igloosharedui";
import Igloo from "@igloocloud/igloo.js";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Clear from "@mui/icons-material/Clear";
import MenuItem from "@mui/material/MenuItem";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme, adaptV4Theme } from "@mui/material/styles";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";

import { withTranslation } from "react-i18next";

const {
  REACT_APP_COLLECTION_NAME: collectionName,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
} = process.env;

export default graphql(
  gql`
    mutation shareCollection(
      $collectionId: ID!
      $email: String!
      $role: Role!
    ) {
      shareCollection(collectionId: $collectionId, email: $email, role: $role) {
        id
      }
    }
  `,
  {
    name: "shareCollection",
  }
)(
  withTranslation()(
    class InviteUser extends Component {
      constructor(props) {
        super(props);

        this.state = {
          email: "",
          auth: "editor",
          selectOpen: false,
          backStackIndex: 0,
        };
        this.client = new Igloo(
          localStorage.getItem("accountList") &&
          localStorage.getItem("userId") &&
          JSON.parse(localStorage.getItem("accountList")).filter(
            (account) => account.id === localStorage.getItem("userId")
          )[0]
            ? JSON.parse(localStorage.getItem("accountList")).filter(
                (account) => account.id === localStorage.getItem("userId")
              )[0].token
            : "",
          localStorage.getItem("server")
            ? (localStorage.getItem("serverUnsecure") === "true"
                ? "http://"
                : "https://") +
              localStorage.getItem("server") +
              "/graphql"
            : `https://v1.igloo.ooo/graphql`
        );
      }

      inviteUser = async () => {
        const { t } = this.props;
        const { email, auth } = this.state;

        if (isEmailValid(email)) {
          try {
            this.setState({ showLoading: true });

            await this.props.shareCollection({
              variables: {
                collectionId: this.props.collectionId,
                email,
                role: auth.toUpperCase(),
              },
              optimisticResponse: {
                __typename: "Mutation",
                pendingShare: {
                  collection: { id: this.props.collectionId },
                  recipient: {
                    email,
                    role: auth.toUpperCase(),
                  },
                  __typename: "PendingShare",
                },
              },
            });

            this.props.close();
          } catch (e) {
            if (
              e.message ===
              "GraphQL error: This user already has a role on this collection"
            ) {
              this.setState({
                emailError: t(
                  (collectionName || "Collection") + " already shared"
                ),
              });
            } else if (
              e.message ===
              "GraphQL error: This account doesn't exist, check the email passed"
            ) {
              this.setState({ emailError: t`This account doesn't exist` });
            } else if (
              e.message ===
              "GraphQL error: You can't share an collection with yourself"
            ) {
              this.setState({
                emailError: t`This is you`,
              });
            } else if (
              e.message === "GraphQL error: There is already a pending share"
            ) {
              this.setState({
                emailError: t(
                  (collectionName || "Collection") + " already shared"
                ),
              });
            } else if (
              e.message === "GraphQL error: There is already a pending transfer"
            ) {
              this.setState({
                emailError: t`This user already has a pending transfer`,
              });
            } else {
              this.setState({
                emailError: t`Error`,
              });
            }
          }

          this.setState({ showLoading: false });
        }
      };

      componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open && nextProps.open) {
          this.setState({
            emailEmpty: false,
            emailError: false,
            email: "",
            auth: "admin",
          });
        }
      }

      handleBackButton = () => {
        if (window.backStack === this.state.backStackIndex) {
          this.handleMenuClose();
        }
      };

      handleMenuClose = () => {
        this.setState({ selectOpen: false });

        if (window.cordova) {
          document.removeEventListener("backbutton", this.handleBackButton);

          this.setState({ backStackIndex: 0 });
          --window.backStack;
        }
      };

      render() {
        const { open, close, t } = this.props;
        const { showLoading, auth, email, emailError, emailEmpty, selectOpen } =
          this.state;
        const {
          REACT_APP_ADMIN_NAME: adminName,
          REACT_APP_EDITOR_NAME: editorName,
          REACT_APP_VIEWER_NAME: viewerName,
        } = process.env;

        return (
          <GenericDialog
            open={open}
            close={close}
            title={t`Send an invite`}
            textButton={t`Close`}
            textButtonFunction={close}
            containedButton={t`Invite`}
            containedButtonFunction={this.inviteUser}
            containedButtonDisabled={
              !email.replace(/\s/g, "").length ||
              emailError ||
              showLoading ||
              (!isEmailValid(email) && email)
            }
            containedButtonLoading={showLoading}
          >
            <StyledEngineProvider injectFirst>
              <ThemeProvider
                theme={createTheme(
                  adaptV4Theme({
                    overrides: {
                      MuiMenu: {
                        list: {
                          padding: 0,
                          backgroundColor,
                        },
                      },
                      MuiSelect: {
                        select: { color: textOnBackgroundColor },
                        icon: { color: textOnBackgroundColor },
                      },
                    },
                  })
                )}
              >
                <TextField
                  value={auth}
                  onChange={(event) =>
                    this.setState({
                      selectOpen: false,
                      auth: event.target.value,
                    })
                  }
                  variant="outlined"
                  select
                  required
                  style={{
                    width: "100%",
                    marginBottom: "24px",
                  }}
                  label={t`Role`}
                  InputLabelProps={auth ? { shrink: true } : {}}
                  SelectProps={{
                    open: selectOpen,
                    onOpen: () => {
                      this.setState({ selectOpen: true });

                      if (window.cordova) {
                        document.addEventListener(
                          "backbutton",
                          this.handleBackButton
                        );

                        this.setState({ backStackIndex: ++window.backStack });
                      }
                    },
                    onClose: this.handleMenuClose,
                  }}
                >
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    value="admin"
                  >
                    {t(adminName || "Admin")}
                  </MenuItem>
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    value="editor"
                  >
                    {t(editorName || "Editor")}
                  </MenuItem>
                  <MenuItem
                    style={{ color: textOnBackgroundColor }}
                    value="viewer"
                  >
                    {t(viewerName || "Viewer")}
                  </MenuItem>
                </TextField>
              </ThemeProvider>
            </StyledEngineProvider>
            <TextField
              id="invite-user-email"
              label={t`Email`}
              type="email"
              value={email}
              variant="outlined"
              error={
                emailEmpty || emailError || (!isEmailValid(email) && email)
              }
              helperText={
                emailEmpty
                  ? t`This field is required`
                  : !isEmailValid(email) && email
                  ? t`Enter a valid email`
                  : emailError || " "
              }
              onChange={(event) =>
                this.setState({
                  email: event.target.value,
                  emailEmpty: event.target.value.replace(/\s/g, "") === "",
                  emailError: "",
                })
              }
              onKeyPress={(event) => {
                if (event.key === "Enter" && !emailEmpty) this.inviteUser();
              }}
              style={{
                width: "100%",
              }}
              InputLabelProps={email ? { shrink: true } : {}}
              InputProps={{
                endAdornment: email && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        this.setState({
                          email: "",
                          emailEmpty: true,
                          emailError: "",
                        })
                      }
                      tabIndex="-1"
                      size="large"
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </GenericDialog>
        );
      }
    }
  )
);
