import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import Clear from "@mui/icons-material/Clear";
import CreateNewFolder from "@mui/icons-material/CreateNewFolder";
import Folder from "@mui/icons-material/Folder";
import { GenericDialog, getNotchHeight } from "@igloocloud/igloosharedui";
import { graphql } from "@apollo/react-hoc";
import gql from "graphql-tag";

const groups = ["Room 1", "Room 2"];

export default graphql(
  gql`
    mutation assignGroup($id: ID!, $group: String) {
      updateThing(id: $id, group: $group) {
        id
        group
      }
    }
  `
)(
  withTranslation()(
    class GroupDialog extends Component {
      state = {
        fetchMoreLoading: false,
        createGroupOpen: false,
        showLoading: false,
        group: "",
        groupEmpty: null,
        groupError: null,
      };

      assignGroup = async (id, group) => {
        const { mutate } = this.props;
        this.setState({ showLoading: true });

        try {
          await mutate({
            variables: {
              id,
              group,
            },
            optimisticResponse: {
              __typegroup: "Mutation",
              collection: {
                __typename: "thing",
                id,
                group,
              },
            },
          });

          this.setState({ createGroupOpen: false });
        } catch (e) {
          this.setState({ groupError: "Error" });
        } finally {
          this.setState({ showLoading: false });
        }
      };

      render() {
        const { open, close, t, thingGroup, fullScreen, id } = this.props;
        const {
          fetchMoreLoading,
          createGroupOpen,
          group,
          groupEmpty,
          groupError,
          showLoading,
        } = this.state;
        const { REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor } =
          process.env;

        const listItemStyle = {
          paddingLeft:
            "calc(16px + " +
            (fullScreen ? getNotchHeight("left") : "0px") +
            ")",
          paddingRight:
            "calc(16px + " +
            (fullScreen ? getNotchHeight("right") : "0px") +
            ")",
        };

        return (
          <>
            <GenericDialog
              title={thingGroup ? t`Change group` : t`Add to a group`}
              open={open && !createGroupOpen}
              close={close}
              textButton={t`Close`}
              textButtonFunction={close}
              noHorizontalPadding
              noDialogContent
            >
              <DialogContent
                style={{ padding: 0 }}
                onScroll={(event) => {
                  if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 600
                  )
                    this.queryMore();
                }}
              >
                <List
                  style={{
                    width: "100%",
                    textOverflow: "ellipsis",
                    padding: 0,
                  }}
                >
                  <ListItem
                    button
                    style={listItemStyle}
                    onClick={() =>
                      this.setState({
                        createGroupOpen: true,
                        group: "",
                        groupEmpty: null,
                        groupError: null,
                      })
                    }
                  >
                    <ListItemIcon>
                      <CreateNewFolder />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <font style={{ color: textColor }}>{t`New group`}</font>
                      }
                    />
                  </ListItem>
                  <ListItem
                    style={listItemStyle}
                    button
                    selected={!thingGroup}
                    onClick={() => this.assignGroup(id, "")}
                  >
                    <ListItemIcon>
                      <SvgIcon>
                        <svg width="24" height="24" viewBox="0 0 24 24">
                          <path d="M10,4L12,6H20A2,2 0 0,1 22,8V18A2,2 0 0,1 20,20H4C2.89,20 2,19.1 2,18V6C2,4.89 2.89,4 4,4H10M12.46,10.88L14.59,13L12.46,15.12L13.88,16.54L16,14.41L18.12,16.54L19.54,15.12L17.41,13L19.54,10.88L18.12,9.46L16,11.59L13.88,9.46L12.46,10.88Z" />
                        </svg>
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <font style={{ color: textColor }}>{t`No group`}</font>
                      }
                    />
                  </ListItem>
                  {groups.map((group) => (
                    <ListItem
                      key={"group-dialog-list-" + group}
                      style={listItemStyle}
                      button
                      selected={group === thingGroup}
                      onClick={() => this.assignGroup(id, group)}
                    >
                      <ListItemIcon>
                        <Folder />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <font style={{ color: textColor }}>{group}</font>
                        }
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          marginRight: "16px",
                        }}
                      />
                    </ListItem>
                  ))}
                </List>
                {fetchMoreLoading && <LinearProgress />}
              </DialogContent>
            </GenericDialog>
            <GenericDialog
              title={t`Create group`}
              open={createGroupOpen}
              close={() => this.setState({ createGroupOpen: false })}
              containedButtonDisabled={!group || showLoading}
              containedButton={t`Create`}
              containedButtonFunction={() => this.assignGroup(id, group)}
              containedButtonLoading={showLoading}
              textButton={t`Close`}
              textButtonFunction={() =>
                this.setState({ createGroupOpen: false })
              }
            >
              <TextField
                label={t`Group`}
                value={group}
                variant="outlined"
                error={groupEmpty || groupError || group.length > 256}
                helperText={
                  group.length > 256
                    ? t`Use fewer characters`
                    : groupEmpty
                    ? t`This field is required`
                    : groupError || " "
                }
                onChange={(event) =>
                  this.setState({
                    group: event.target.value,
                    groupEmpty: event.target.value.replace(/\s/g, "") === "",
                    groupError: "",
                  })
                }
                onKeyPress={(event) => {
                  if (event.key === "Enter" && group && group.length <= 256)
                    this.assignGroup(id, group);
                }}
                style={{
                  width: "100%",
                  marginBottom: "16px",
                }}
                InputLabelProps={group ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: group && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            group: "",
                            groupEmpty: true,
                            groupError: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GenericDialog>
          </>
        );
      }
    }
  )
);
