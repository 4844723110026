import { graphql } from "@apollo/react-hoc";
import { GenericDialog, isRoleAtLeast } from "@igloocloud/igloosharedui";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import gql from "graphql-tag";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

export default graphql(
  gql`
    mutation ChangeCollection($id: ID!, $collectionId: ID!) {
      moveThing(thingId: $id, newCollectionId: $collectionId) {
        id
      }
    }
  `,
  {
    name: "ChangeCollection",
  }
)(
  withTranslation()(
    class ChangeCollection extends Component {
      state = { newCollection: "" };

      changeCollection = (value) => {
        this.props.ChangeCollection({
          variables: {
            id: this.props.thing.id,
            collectionId: value,
          },
          optimisticResponse: {
            __typename: "Mutation",
            thing: {
              __typename: this.props.thing.__typename,
              id: this.props.thing.id,
              collectionId: value,
            },
          },
        });
      };

      componentWillReceiveProps(nextProps) {
        if (nextProps.thingCollection) {
          this.setState({
            newCollection: this.props.thingCollection,
          });
        }
      }

      render() {
        const { open, close, collections, t } = this.props;
        const { REACT_APP_THING_NAME: thingName } = process.env;

        return (
          <GenericDialog
            open={open}
            close={close}
            title={t(`Move ${thingName ? thingName.toLowerCase() : "thing"}`)}
            textButton={t`Close`}
            textButtonFunction={close}
          >
            <RadioGroup
              onChange={(_, value) => {
                this.setState({ newCollection: value });
                this.changeCollection(value);
              }}
              value={this.state.newCollection}
            >
              {collections?.map((collection) => (
                <FormControlLabel
                  control={<Radio color="primary" />}
                  value={collection.id}
                  label={collection.name}
                  className="notSelectable"
                  disabled={!isRoleAtLeast(collection.myRole, "OWNER")}
                  key={collection.id}
                />
              ))}
            </RadioGroup>
          </GenericDialog>
        );
      }
    }
  )
);
