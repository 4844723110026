import React, { Component } from "react"
import Typography from "@mui/material/Typography"
import Paper from "@mui/material/Paper"
import ButtonBase from "@mui/material/ButtonBase"
import {
  CenteredSpinner,
  ErrorScreen,
  getNotchHeight,
} from "@igloocloud/igloosharedui"
import CollectionCard from "./CollectionCard"
import Helmet from "react-helmet"
import PendingShares from "./PendingShares"
import PendingTransfers from "./PendingTransfers"
import Share from "@mui/icons-material/Share"
import People from "@mui/icons-material/People"
import LinearProgress from "@mui/material/LinearProgress"
import tinyColor from "tinycolor2"
import { withTranslation } from "react-i18next"

const {
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnBackgroundColor,
} = process.env

export default withTranslation()(
  class CollectionsBody extends Component {
    constructor(props) {
      super(props)

      this.state = {
        createOpen: false,
        pendingSharesOpen: false,
        pendingTransfersOpen: false,
      }
    }

    componentWillReceiveProps(nextProps) {
      if (this.props.userData.user && nextProps.userData.user) {
        if (
          nextProps.userData.user.pendingShareCount !==
            this.props.userData.user.pendingShareCount &&
          !nextProps.userData.user.pendingShareCount
        ) {
          this.setState({
            pendingSharesOpen: false,
          })
        }

        if (
          nextProps.userData.user.pendingTransferCount !==
            this.props.userData.user.pendingTransferCount &&
          !nextProps.userData.user.pendingTransferCount
        ) {
          this.setState({
            pendingTransfersOpen: false,
          })
        }
      }
    }

    queryMore = async () => {
      if (
        !this.queryMore.locked &&
        this.props.userData.user.collectionCount >
          this.props.userData.user.collections.length
      ) {
        this.queryMore.locked = true

        try {
          this.setState({ fetchMoreLoading: true })
          await this.props.userData.fetchMore({
            variables: {
              offset: this.props.userData.user.collections.length,
              limit:
                this.props.userData.user.collectionCount -
                  this.props.userData.user.collections.length >=
                20
                  ? 20
                  : this.props.userData.user.collectionCount % 20,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) {
                return prev
              }

              const newCollections = [
                ...prev.user.collections,
                ...fetchMoreResult.user.collections,
              ].sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase()
                  ? 1
                  : a.name.toLowerCase() < b.name.toLowerCase()
                  ? -1
                  : 0
              )

              return {
                user: {
                  ...prev.user,
                  collections: newCollections,
                },
              }
            },
          })
        } finally {
          this.setState(() => {
            this.queryMore.locked = false

            return { fetchMoreLoading: false }
          })
        }
      }
    }

    componentDidMount() {
      if (this.props.mobile && window.cordova) {
        if (
          tinyColor(
            process.env.REACT_APP_MAIN_BACKGROUND_COLOR
          ).getBrightness() <= 128
        ) {
          window.StatusBar.styleLightContent()
        } else {
          window.StatusBar.styleDefault()
        }
      }
    }

    render() {
      const {
        userData: { error, loading, user, refetch },
        t,
      } = this.props
      const { fetchMoreLoading } = this.state
      const collectionsName =
        process.env.REACT_APP_COLLECTIONS_NAME || "Collections"

      let yourCollectionsList = ""

      let errorScreen = (
        <ErrorScreen
          refetch={() =>
            refetch({
              variables: {
                limit: 20,
                offset: 0,
                filter: {},
              },
            })
          }
          error={error}
        />
      )

      if (user) {
        yourCollectionsList = (
          <div
            justify="center"
            className="collectionBodyGrid notSelectable defaultCursor"
            style={{
              margin: "16px",
              paddingLeft: getNotchHeight("left"),
              paddingRight: getNotchHeight("right"),
            }}
          >
            {!!user.pendingTransferCount && (
              <ButtonBase
                focusRipple
                key="pendingTransfers"
                style={{ borderRadius: "4px" }}
                onClick={() =>
                  this.setState({
                    pendingTransfersOpen: true,
                  })
                }
              >
                <Paper
                  style={{
                    backgroundColor,
                    width: "100%",
                    height: "192px",
                    cursor: "pointer",
                    color: textOnBackgroundColor,
                  }}
                  elevation={5}
                >
                  <div
                    style={{
                      paddingTop: "47px",
                      paddingBottom: "47px",
                    }}
                  >
                    <People style={{ fontSize: "64px" }} />
                    <br />
                    <Typography
                      variant="h5"
                      style={{
                        textAlign: "center",
                        color: textOnBackgroundColor,
                      }}
                    >
                      {user.pendingTransferCount > 99
                        ? "99+ " + t`transfer requests`
                        : user.pendingTransferCount +
                          (user.pendingTransferCount === 1
                            ? " " + t`transfer request`
                            : " " + t`transfer requests`)}
                    </Typography>
                  </div>
                </Paper>
              </ButtonBase>
            )}
            {!!user.pendingShareCount && (
              <ButtonBase
                focusRipple
                key="pendingShares"
                style={{ borderRadius: "4px" }}
                onClick={() =>
                  this.setState({
                    pendingSharesOpen: true,
                  })
                }
              >
                <Paper
                  style={{
                    backgroundColor,
                    width: "100%",
                    height: "192px",
                    cursor: "pointer",
                    color: textOnBackgroundColor,
                  }}
                  elevation={5}
                >
                  <div
                    style={{
                      paddingTop: "47px",
                      paddingBottom: "47px",
                    }}
                  >
                    <Share
                      style={{
                        fontSize: "48px",
                        marginBottom: "8px",
                        marginTop: "8px",
                      }}
                    />
                    <br />
                    <Typography
                      variant="h5"
                      style={{
                        color: textOnBackgroundColor,
                        textAlign: "center",
                      }}
                    >
                      {user.pendingShareCount > 99
                        ? "99+ " + t`sharing requests`
                        : user.pendingShareCount +
                          (user.pendingShareCount === 1
                            ? " " + t`sharing request`
                            : " " + t`sharing requests`)}
                    </Typography>
                  </div>
                </Paper>
              </ButtonBase>
            )}
            {user.collections.map((collection) => (
              <CollectionCard
                userData={this.props.userData}
                collection={collection}
                client={this.props.client}
                key={collection.id}
              />
            ))}
          </div>
        )
      }

      return (
        <>
          <Helmet>
            <title>{process.env.REACT_APP_NAME}</title>
          </Helmet>
          {this.props.mobile ? (
            <div
              style={{
                height: "100%",
                backgroundColor,
              }}
            >
              {error && errorScreen}
              {loading && (
                <div
                  style={{
                    overflowY: "auto",
                  }}
                >
                  <CenteredSpinner
                    style={{
                      paddingTop: "32px",
                    }}
                  />
                </div>
              )}
              {user && (
                <div
                  style={{
                    overflowY: "auto",
                  }}
                  onScroll={(event) => {
                    if (
                      event.target.scrollTop + event.target.clientHeight >=
                      event.target.scrollHeight - 600
                    )
                      this.queryMore()
                  }}
                >
                  {user.collections.length !== 0 ||
                  user.pendingTransferCount ||
                  user.pendingShareCount ? (
                    yourCollectionsList
                  ) : (
                    <Typography
                      variant="h5"
                      className="notSelectable defaultCursor"
                      style={{
                        textAlign: "center",
                        margin: "32px",
                        color: textOnBackgroundColor,
                      }}
                    >
                      {t("No " + collectionsName.toLowerCase())}
                    </Typography>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div
              style={{
                backgroundColor,
                height: "calc(100% - 64px - " + getNotchHeight("top") + ")",
              }}
            >
              <div
                style={{
                  width: "100vw",
                  height: "100%",
                  backgroundColor,
                  overflowY: "auto",
                  overscrollBehaviorY: "none",
                }}
                onScroll={(event) => {
                  if (
                    event.target.scrollTop + event.target.clientHeight >=
                    event.target.scrollHeight - 600
                  )
                    this.queryMore()
                }}
              >
                {error && errorScreen}
                {loading && (
                  <div
                    style={{
                      overflowY: "auto",
                      height:
                        "calc(100% - 64px - " + getNotchHeight("top") + ")",
                    }}
                  >
                    <CenteredSpinner
                      style={{
                        paddingTop: "32px",
                      }}
                    />
                  </div>
                )}
                {user &&
                  (user.collections.length !== 0 ||
                  user.pendingTransferCount ||
                  user.pendingShareCount ? (
                    yourCollectionsList
                  ) : (
                    <Typography
                      variant="h5"
                      className="notSelectable defaultCursor"
                      style={{
                        textAlign: "center",
                        marginTop: "32px",
                        marginBottom: "32px",
                        color: textOnBackgroundColor,
                      }}
                    >
                      {t("No " + collectionsName.toLowerCase())}
                    </Typography>
                  ))}
              </div>
            </div>
          )}
          <PendingShares
            open={this.state.pendingSharesOpen}
            close={() =>
              this.setState({
                pendingSharesOpen: false,
              })
            }
            pendingShareCount={
              this.props.userData.user &&
              this.props.userData.user.pendingShareCount
            }
            unverified={
              !(
                this.props.userData.user &&
                this.props.userData.user.emailIsVerified
              )
            }
          />
          <PendingTransfers
            open={this.state.pendingTransfersOpen}
            close={() =>
              this.setState({
                pendingTransfersOpen: false,
              })
            }
            unverified={
              !(
                this.props.userData.user &&
                this.props.userData.user.emailIsVerified
              )
            }
          />
          {fetchMoreLoading && (
            <LinearProgress
              style={
                this.props.mobile
                  ? { position: "absolute", top: 0, width: "100%" }
                  : { marginTop: "-4px" }
              }
            />
          )}
        </>
      )
    }
  }
)
