import React from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import moment from "moment"
import Moment from "react-moment"
import { withTranslation } from "react-i18next"

const { REACT_APP_THING_NAME: thingName } = process.env

export default withTranslation()(({ open, close, thing, t }) => (
  <GenericDialog
    open={open}
    close={close}
    title={t(`${thingName || "Thing"} information`)}
    textButton={t`Close`}
    textButtonFunction={close}
    selectable
    typographyStyle={{ overflowX: "hidden", textOverflow: "ellipsis" }}
  >
    <b>{t`Type` + ": "}</b>
    {thing.type}
    {thing.firmware && (
      <>
        <br />
        <b>{t`Firmware` + ": "}</b>
        {thing.firmware}
      </>
    )}
    <br />
    <b>{t`Created` + ": "}</b>
    <Moment fromNow>
      {moment.utc(thing.createdAt.split(".")[0], "YYYY-MM-DDTh:mm:ss")}
    </Moment>
    <br />
    <b>{t`Last seen` + ": "}</b>
    {thing.online ? (
      t`now`
    ) : (
      <Moment fromNow>
        {moment.utc(thing.updatedAt.split(".")[0], "YYYY-MM-DDTh:mm:ss")}
      </Moment>
    )}
    <br />
    <b>{t`ID` + ": "}</b> {thing.id}
  </GenericDialog>
))
