import React, { Component } from "react";
import AppBar from "@mui/material/AppBar";
import logo from "../../styles/assets/logo.svg";
import {
  AccountPopover,
  getNotchHeight,
  debounce,
} from "@igloocloud/igloosharedui";
import { Redirect } from "react-router-dom";
import tinyColor from "tinycolor2";
import { withTranslation } from "react-i18next";
import Home from "@mui/icons-material/Home";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SvgIcon from "@mui/material/SvgIcon";
import Assignment from "@mui/icons-material/Assignment";
import { Link } from "react-router-dom";

const { REACT_APP_MAIN_COLOR: mainColor } = process.env;

export default withTranslation()(
  class CollectionsHeader extends Component {
    constructor(props) {
      super(props);

      this.state = {
        redirectTo: "",
        lessThan900: null,
      };
    }

    updateDimensions = () => {
      if (window.innerWidth < 900) {
        this.setState({ lessThan900: true });
      } else {
        this.setState({ lessThan900: false });
      }
    };

    componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", debounce(this.updateDimensions));

      if (!this.props.mobile && window.cordova) {
        if (tinyColor(mainColor).getBrightness() <= 128) {
          window.StatusBar.styleLightContent();
        } else {
          window.StatusBar.styleDefault();
        }
      }
    }

    componentWillUnmount() {
      window.removeEventListener("resize", debounce(this.updateDimensions));
    }

    render() {
      const { mobile, t } = this.props;
      const { lessThan900 } = this.state;
      const {
        REACT_APP_TEXT_ON_MAIN_COLOR: textColor,
        REACT_APP_ENABLE_DATA_LAB: enableDataLab,
        REACT_APP_ENABLE_REPORTS: enableReports,
        REACT_APP_HOME_ICON: homeIcon,
        REACT_APP_HOME_NAME: homeName,
      } = process.env;

      const tabStyle = (selected) => ({
        color: selected ? textColor : tinyColor(textColor).setAlpha(0.54),
        textTransform: "capitalize",
        opacity: selected ? 1 : 0.54,
        height: "64px",
        minHeight: "unset",
        minWidth:
          lessThan900 && enableDataLab && enableReports ? "128px" : "192px",
      });

      const showTabs = enableDataLab || enableReports;

      return (
        <AppBar
          position="sticky"
          style={{
            paddingTop: mobile ? 0 : getNotchHeight("top"),
            paddingLeft: getNotchHeight("left"),
            paddingRight: getNotchHeight("right"),
            paddingBottom: mobile ? "env(safe-area-inset-bottom)" : "",
            boxShadow: mobile
              ? "0px -2px 4px -1px rgba(0,0,0,0.2), 0px -4px 5px 0px rgba(0,0,0,0.14), 0px -1px 10px 0px rgba(0,0,0,0.12)"
              : null,
          }}
        >
          <div
            className="sidebarHeader notSelectable"
            style={{
              color: process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR,
              display: "flex",
              alignItems: "center",
              height: "64px",
              justifyContent: "space-between",
            }}
          >
            <div style={{ width: "72px", height: "56px" }}>
              <img
                src={logo}
                alt="Igloo logo"
                className="notSelectable"
                draggable="false"
                style={{ marginLeft: "16px", height: "100%" }}
              />
            </div>
            {!mobile && showTabs && (
              <Tabs
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }}
              >
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "unset",
                  }}
                >
                  <Tab
                    icon={
                      homeIcon ? (
                        <SvgIcon>
                          <svg dangerouslySetInnerHTML={{ __html: homeIcon }} />
                        </SvgIcon>
                      ) : (
                        <Home />
                      )
                    }
                    label={t(homeName || "Home")}
                    style={tabStyle(window.location.pathname === "/")}
                  />
                </Link>
                {enableDataLab && (
                  <Link
                    to="/data"
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}
                  >
                    <Tab
                      icon={
                        <SvgIcon>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            enable-background="new 0 0 24 24"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                          >
                            <g>
                              <rect fill="none" height="24" width="24" />
                            </g>
                            <g>
                              <g>
                                <path d="M21,8c-1.45,0-2.26,1.44-1.93,2.51l-3.55,3.56c-0.3-0.09-0.74-0.09-1.04,0l-2.55-2.55C12.27,10.45,11.46,9,10,9 c-1.45,0-2.27,1.44-1.93,2.52l-4.56,4.55C2.44,15.74,1,16.55,1,18c0,1.1,0.9,2,2,2c1.45,0,2.26-1.44,1.93-2.51l4.55-4.56 c0.3,0.09,0.74,0.09,1.04,0l2.55,2.55C12.73,16.55,13.54,18,15,18c1.45,0,2.27-1.44,1.93-2.52l3.56-3.55 C21.56,12.26,23,11.45,23,10C23,8.9,22.1,8,21,8z" />
                                <polygon points="15,9 15.94,6.93 18,6 15.94,5.07 15,3 14.08,5.07 12,6 14.08,6.93" />
                                <polygon points="3.5,11 4,9 6,8.5 4,8 3.5,6 3,8 1,8.5 3,9" />
                              </g>
                            </g>
                          </svg>
                        </SvgIcon>
                      }
                      label={t`Data Lab`}
                      style={tabStyle(
                        window.location.pathname.startsWith("/data")
                      )}
                      to="/data"
                    />
                  </Link>
                )}
                {enableReports && (
                  <Link
                    to="/reports"
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}
                  >
                    <Tab
                      icon={<Assignment />}
                      label={t`Reports`}
                      style={tabStyle(
                        window.location.pathname.startsWith("/reports")
                      )}
                      to="/reports"
                    />
                  </Link>
                )}
              </Tabs>
            )}
            <div
              style={
                this.props.mobile
                  ? {
                      padding: "0",
                      marginLeft: "auto",
                      marginRight: "12px",
                      float: "right",
                    }
                  : {
                      padding: "0",
                      marginLeft: "20px", //makes the button 72px wide, thus keeping the search bar centered
                      marginRight: "12px",
                      float: "right",
                    }
              }
            >
              <AccountPopover
                logOut={this.props.logOut}
                changeAccount={this.props.changeAccount}
                changeBearer={this.props.changeBearer}
                setOpen={this.props.setOpen}
                isOpen={this.props.isOpen}
                user={this.props.userData}
                setRedirect={(redirectTo) => this.setState({ redirectTo })}
              />
            </div>
          </div>
          {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
        </AppBar>
      );
    }
  }
);
