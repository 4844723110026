import { graphql } from "@apollo/react-hoc";
import {
  getNotchHeight,
  isRoleAtLeast,
  NotificationDrawer,
} from "@igloocloud/igloosharedui";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Create from "@mui/icons-material/Create";
import Folder from "@mui/icons-material/Folder";
import Info from "@mui/icons-material/Info";
import LinkOff from "@mui/icons-material/LinkOff";
import MoreVert from "@mui/icons-material/MoreVert";
import Notifications from "@mui/icons-material/Notifications";
import NotificationsNone from "@mui/icons-material/NotificationsNone";
import NotificationsOff from "@mui/icons-material/NotificationsOff";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import Visibility from "@mui/icons-material/Visibility";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Typography from "@mui/material/Typography";
import gql from "graphql-tag";
import querystringify from "querystringify";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import tinyColor from "tinycolor2";
import ChangeCollection from "./things/ChangeCollection";
import FlowsDialog from "./things/flows/FlowsDialog";
import GroupDialog from "./things/GroupDialog";
import ManageCards from "./things/ManageCards";
import RenameThing from "./things/RenameThing";
import ThingInfo from "./things/ThingInfo";
import UnpairThing from "./things/UnpairThing";

const {
  REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
  REACT_APP_TEXT_ON_SECONDARY_COLOR: textOnSecondaryColor,
  REACT_APP_SHOW_NOTIFICATIONS: showNotifications,
} = process.env;

export default withTranslation()(
  graphql(
    gql`
      mutation ToggleQuietMode($id: ID!, $muted: Boolean) {
        updateThing(id: $id, muted: $muted) {
          id
          muted
        }
      }
    `,
    {
      name: "ToggleQuietMode",
    }
  )(
    graphql(
      gql`
        mutation ToggleStarred($id: ID!, $starred: Boolean) {
          updateThing(id: $id, starred: $starred) {
            id
            starred
          }
        }
      `,
      {
        name: "ToggleStarred",
      }
    )(
      class MainBodyHeader extends Component {
        state = {
          infoOpen: false,
          unpairOpen: false,
          renameOpen: false,
          changeCollectionOpen: false,
          goToThings: false,
          drawerOpen: false,
          manageCardsOpen: false,
          groupDialogOpen: false,
          backStackIndex: 0,
        };

        handleMenuOpen = (event) => {
          if (window.cordova && this.props.isMobile) {
            if (
              tinyColor.mix(backgroundColor, "#00000088").getBrightness() <= 128
            ) {
              window.StatusBar.styleLightContent();
            } else {
              window.StatusBar.styleDefault();
            }
          }

          this.setState({ anchorEl: event ? event.currentTarget : true });
        };

        handleBackButton = () => {
          if (this.state.backStackIndex === window.backStack) {
            this.handleMenuClose();
          }
        };

        handleMenuClose = () => {
          if (window.cordova && this.props.isMobile) {
            if (tinyColor(backgroundColor).getBrightness() <= 128) {
              window.StatusBar.styleLightContent();
            } else {
              window.StatusBar.styleDefault();
            }
          }

          this.setState({ anchorEl: null });

          if (window.cordova) {
            this.setState({ backStackIndex: 0 });

            --window.backStack;

            document.removeEventListener("backbutton", this.handleBackButton);
          }
        };

        render() {
          const {
            data: { thing },
            isMobile,
            t,
          } = this.props;
          const { drawerOpen, groupDialogOpen } = this.state;
          const {
            REACT_APP_SIDEBAR_FOLDERS_ENABLED: foldersEnabled,
            REACT_APP_THING_NAME: thingName,
            REACT_APP_ENABLE_FLOWS: flowsEnabled,
          } = process.env;

          const notchStyle = {
            paddingLeft:
              "calc(16px + " +
              (isMobile ? getNotchHeight("left") : "0px") +
              ")",
            paddingRight:
              "calc(16px + " +
              (isMobile ? getNotchHeight("right") : "0px") +
              ")",
          };

          const mainColor = isMobile
            ? process.env.REACT_APP_MAIN_COLOR
            : process.env.REACT_APP_LIGHT_COLOR;
          const textOnMainColor = isMobile
            ? process.env.REACT_APP_TEXT_ON_MAIN_COLOR
            : process.env.REACT_APP_TEXT_ON_LIGHT_COLOR;
          const textOnBackgroundColor =
            process.env.REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR;
          const errorColor = process.env.REACT_APP_ERROR_COLOR;

          const toggleQuietMode = (muted) => {
            this.props.ToggleQuietMode({
              variables: {
                id: thing.id,
                muted,
              },
              optimisticResponse: {
                __typename: "Mutation",
                thing: {
                  id: thing.id,
                  muted,
                  __typename: "Thing",
                },
              },
            });
          };

          const toggleStarred = (starred) => {
            this.props.ToggleStarred({
              variables: {
                id: thing.id,
                starred,
              },
              optimisticResponse: {
                __typename: "Mutation",
                thing: {
                  id: thing.id,
                  starred,
                  __typename: "Thing",
                },
              },
            });
          };

          let content = () =>
            thing && (
              <>
                <MenuItem
                  onClick={() => {
                    this.handleMenuClose();

                    this.setState({ infoOpen: true });
                  }}
                  style={{ color: textOnBackgroundColor, ...notchStyle }}
                >
                  <ListItemIcon>
                    <Info />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {t`Information`}
                  </Typography>
                </MenuItem>
                <Divider className="notSelectable" />
                {showNotifications &&
                  (thing.muted ? (
                    <MenuItem
                      className="notSelectable"
                      style={{ color: textOnBackgroundColor, ...notchStyle }}
                      onClick={() => {
                        toggleQuietMode(false);

                        this.handleMenuClose();
                      }}
                      disabled={
                        this.props.userData.user &&
                        this.props.userData.user.quietMode
                      }
                    >
                      <ListItemIcon>
                        <Notifications />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t`Unmute`}
                      </Typography>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      className="notSelectable"
                      style={{ color: textOnBackgroundColor, ...notchStyle }}
                      onClick={() => {
                        toggleQuietMode(true);
                        this.handleMenuClose();
                      }}
                      disabled={
                        this.props.userData.user &&
                        this.props.userData.user.quietMode
                      }
                    >
                      <ListItemIcon>
                        <NotificationsOff />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t`Mute`}
                      </Typography>
                    </MenuItem>
                  ))}
                {foldersEnabled ? (
                  <MenuItem
                    className="notSelectable"
                    style={{ color: textOnBackgroundColor, ...notchStyle }}
                    onClick={() => {
                      this.setState({ groupDialogOpen: true });

                      this.handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <SvgIcon>
                        {thing.group ? (
                          <Folder />
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#000000"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
                          </svg>
                        )}
                      </SvgIcon>
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {thing.group ? t`Change group` : t`Add to a group`}
                    </Typography>
                  </MenuItem>
                ) : (
                  <MenuItem
                    className="notSelectable"
                    style={{ color: textOnBackgroundColor, ...notchStyle }}
                    onClick={() => {
                      toggleStarred(!thing.starred);
                      this.handleMenuClose();
                    }}
                    disabled={
                      this.props.collectionData.collection &&
                      this.props.collectionData.collection.starredThings
                        .length >= 5 &&
                      !thing.starred
                    }
                  >
                    <ListItemIcon>
                      {thing.starred ? <StarBorder /> : <Star />}
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {thing.starred ? t`Unstar` : t`Star`}
                    </Typography>
                  </MenuItem>
                )}
                <Divider className="notSelectable" />
                <MenuItem
                  className="notSelectable"
                  style={{
                    color: textOnBackgroundColor,
                    ...notchStyle,
                    paddingBottom: !isRoleAtLeast(thing.myRole, "EDITOR")
                      ? isMobile
                        ? "calc(6px + env(safe-area-inset-bottom))"
                        : ""
                      : "",
                  }}
                  disabled={!thing.variableCount && !thing.hiddenVariableCount}
                  onClick={() => {
                    this.setState({ manageCardsOpen: true });
                    this.handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <Visibility />
                  </ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {t`Toggle cards`}
                  </Typography>
                </MenuItem>
                {isRoleAtLeast(thing.myRole, "ADMIN") && flowsEnabled && (
                  <MenuItem
                    className="notSelectable"
                    style={{
                      color: textOnBackgroundColor,
                      ...notchStyle,
                      paddingBottom: !isRoleAtLeast(thing.myRole, "EDITOR")
                        ? isMobile
                          ? "calc(6px + env(safe-area-inset-bottom))"
                          : ""
                        : "",
                    }}
                    onClick={() => {
                      this.setState({ manageFlowsOpen: true });
                      this.handleMenuClose();
                    }}
                    disabled={
                      !thing.variableCount && !thing.hiddenVariableCount
                    }
                  >
                    <ListItemIcon>
                      <SvgIcon>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17 7L22 12L17 17V13.25H12V10.75H17V7ZM8.25 10.75C7.5625 10.75 7 11.3125 7 12C7 12.6875 7.5625 13.25 8.25 13.25C8.9375 13.25 9.5 12.6875 9.5 12C9.5 11.3125 8.9375 10.75 8.25 10.75ZM3.25 10.75C2.5625 10.75 2 11.3125 2 12C2 12.6875 2.5625 13.25 3.25 13.25C3.9375 13.25 4.5 12.6875 4.5 12C4.5 11.3125 3.9375 10.75 3.25 10.75Z"
                            fill="black"
                          />
                        </svg>
                      </SvgIcon>
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {t`Manage flows`}
                    </Typography>
                  </MenuItem>
                )}
                <Divider className="notSelectable" />
                {isRoleAtLeast(thing.myRole, "OWNER") && (
                  <MenuItem
                    className="notSelectable"
                    style={{ color: textOnBackgroundColor, ...notchStyle }}
                    onClick={() => {
                      this.setState({ changeCollectionOpen: true });
                      this.handleMenuClose();
                    }}
                    disabled={
                      !(
                        this.props.collections &&
                        this.props.collections.length > 1
                      )
                    }
                  >
                    <ListItemIcon>
                      <SwapHoriz />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      {t(
                        "Move " +
                          (thingName ? thingName.toLowerCase() : "thing")
                      )}
                    </Typography>
                  </MenuItem>
                )}
                {isRoleAtLeast(thing.myRole, "ADMIN") && (
                  <>
                    <MenuItem
                      className="notSelectable"
                      style={{
                        color: textOnBackgroundColor,
                        ...notchStyle,
                      }}
                      onClick={() => {
                        this.setState({ renameOpen: true });
                        this.handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <Create />
                      </ListItemIcon>
                      <Typography variant="inherit" noWrap>
                        {t`Rename`}
                      </Typography>
                    </MenuItem>
                    <MenuItem
                      className="notSelectable"
                      style={{
                        color: textOnBackgroundColor,
                        ...notchStyle,
                        paddingBottom: isMobile
                          ? "calc(6px + env(safe-area-inset-bottom))"
                          : "",
                      }}
                      onClick={() => {
                        this.setState({ unpairOpen: true });
                        this.handleMenuClose();
                      }}
                    >
                      <ListItemIcon>
                        <LinkOff style={{ color: errorColor }} />
                      </ListItemIcon>
                      <Typography
                        variant="inherit"
                        noWrap
                        style={{ color: errorColor }}
                      >
                        {t`Unpair`}
                      </Typography>
                    </MenuItem>
                  </>
                )}
              </>
            );

          return (
            <>
              <div
                className="notSelectable"
                style={{
                  color: textOnMainColor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  height: "64px",
                  paddingTop: isMobile ? 0 : getNotchHeight("top"),
                  gridArea: isMobile ? "header" : "mainBodyHeader",
                  backgroundColor: mainColor,
                  zIndex: "1000",
                  paddingLeft: isMobile ? getNotchHeight("left") : 0,
                  paddingRight: getNotchHeight("right"),
                  paddingBottom: isMobile ? "env(safe-area-inset-bottom)" : 0,
                }}
              >
                {isMobile && (
                  <Link
                    to={
                      "/?collection=" +
                      querystringify.parse(
                        window.cordova &&
                          window.location.hash.split("?").length === 2
                          ? "?" + window.location.hash.split("?")[1]
                          : window.location.search
                      ).collection
                    }
                    style={{
                      textDecoration: "none",
                      color: "unset",
                    }}
                  >
                    <IconButton
                      style={{
                        color: textOnMainColor,
                        margin: "0 8px",
                      }}
                      size="large"
                    >
                      <ArrowBack />
                    </IconButton>
                  </Link>
                )}
                <Typography
                  variant="h5"
                  style={{
                    cursor: "default",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "64px",
                    width: "100%",
                    marginLeft: isMobile ? "" : "16px",
                  }}
                >
                  {thing?.name}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "8px",
                  }}
                >
                  {showNotifications && (
                    <IconButton
                      onClick={() => this.setState({ drawerOpen: true })}
                      style={{ color: textOnMainColor }}
                      size="large"
                    >
                      <Badge
                        badgeContent={
                          <font style={{ color: textOnSecondaryColor }}>
                            {thing?.notificationCount}
                          </font>
                        }
                        color="secondary"
                        invisible={!thing?.notificationCount}
                      >
                        {thing?.notificationCount ? (
                          <Notifications />
                        ) : (
                          <NotificationsNone />
                        )}
                      </Badge>
                    </IconButton>
                  )}
                  {showNotifications && (
                    <NotificationDrawer
                      open={drawerOpen}
                      setOpen={(drawerOpen) => this.setState({ drawerOpen })}
                      thingId={this.props.thingId}
                      notificationCount={thing?.notificationCount}
                    />
                  )}
                  <IconButton
                    onClick={this.handleMenuOpen}
                    disabled={!thing?.id}
                    style={{
                      color: tinyColor(textOnMainColor)
                        .setAlpha(thing?.id ? 1 : 0.54)
                        .toRgbString(),
                    }}
                    size="large"
                  >
                    <MoreVert />
                  </IconButton>
                </div>
              </div>
              {thing?.id && thing.createdAt && thing.updatedAt && (
                <ThingInfo
                  open={this.state.infoOpen}
                  close={() => this.setState({ infoOpen: false })}
                  thing={thing}
                />
              )}
              {thing?.id && this.props.userData && (
                <ChangeCollection
                  open={this.state.changeCollectionOpen}
                  close={() => this.setState({ changeCollectionOpen: false })}
                  userData={this.props.userData}
                  thing={thing}
                  thingCollection={thing.collection.id}
                  collections={this.props.collections}
                />
              )}
              {thing?.name && (
                <RenameThing
                  open={this.state.renameOpen}
                  close={() => this.setState({ renameOpen: false })}
                  thing={thing}
                />
              )}
              {thing?.id && (
                <ManageCards
                  open={this.state.manageCardsOpen}
                  close={() => this.setState({ manageCardsOpen: false })}
                  id={thing.id}
                />
              )}
              {thing && (
                <GroupDialog
                  open={groupDialogOpen}
                  close={() => this.setState({ groupDialogOpen: false })}
                  id={thing.id}
                  thingGroup={thing.group}
                />
              )}
              {thing && (
                <FlowsDialog
                  open={this.state.manageFlowsOpen}
                  close={() => this.setState({ manageFlowsOpen: false })}
                  id={thing.id}
                />
              )}
              <UnpairThing
                open={this.state.unpairOpen}
                close={() => this.setState({ unpairOpen: false })}
                client={this.props.client}
                thing={thing}
                deselectThing={() => this.setState({ goToThings: true })}
              />
              {thing &&
                (this.props.isMobile ? (
                  <SwipeableDrawer
                    variant="temporary"
                    anchor="bottom"
                    open={this.state.anchorEl}
                    onOpen={this.handleMenuOpen}
                    onClose={this.handleMenuClose}
                    disableBackdropTransition={false}
                    disableDiscovery
                    disableSwipeToOpen
                    PaperProps={{
                      style: {
                        backgroundColor,
                      },
                    }}
                  >
                    {content(false)}
                  </SwipeableDrawer>
                ) : (
                  <Menu
                    id="main-body-header-menu"
                    anchorEl={this.state.anchorEl}
                    open={this.state.anchorEl}
                    onClose={this.handleMenuClose}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    PaperProps={{
                      style: {
                        backgroundColor,
                      },
                    }}
                    TransitionProps={{
                      onEnter: () => {
                        if (window.cordova) {
                          this.setState({ backStackIndex: ++window.backStack });

                          document.addEventListener(
                            "backbutton",
                            this.handleBackButton
                          );
                        }
                      },
                    }}
                  >
                    {content(true)}
                  </Menu>
                ))}
              {this.state.goToThings && (
                <Redirect
                  to={
                    "/?collection=" +
                    querystringify.parse(
                      window.cordova &&
                        window.location.hash.split("?").length === 2
                        ? "?" + window.location.hash.split("?")[1]
                        : window.location.search
                    ).collection
                  }
                />
              )}
            </>
          );
        }
      }
    )
  )
);
