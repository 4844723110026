import React from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import { withTranslation } from "react-i18next"

const { REACT_APP_COLLECTION_NAME: collectionName } = process.env

export default withTranslation()(
  ({ t, open, close, stopSharing, showLoading, menuTarget }) => (
    <GenericDialog
      open={open}
      close={close}
      title={t`Stop sharing`}
      textButton={t`Close`}
      textButtonFunction={close}
      containedButton={t`Stop sharing`}
      containedButtonFunction={stopSharing}
      containedButtonLoading={showLoading}
      containedButtonDisabled={showLoading}
      isContainedButtonRed
    >
      {t(
        "Are you sure you want to stop sharing this " +
          (collectionName?.toLowerCase() || "collection") +
          " with "
      ) +
        menuTarget?.name +
        "?"}
    </GenericDialog>
  )
)
