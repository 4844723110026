import React, { Component } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import QrReader from "react-qr-scanner";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Clear from "@mui/icons-material/Clear";
import { graphql } from "@apollo/react-hoc";
import gql from "graphql-tag";
import Typography from "@mui/material/Typography";
import { Redirect } from "react-router-dom";
import { GenericDialog, getNotchHeight } from "@igloocloud/igloosharedui";
import { withTranslation } from "react-i18next";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

let prevThingDetailsOpen;

export default graphql(
  gql`
    mutation PairThing($pairCode: String!, $name: String!, $collectionId: ID!) {
      pairThing(pairCode: $pairCode, name: $name, collectionId: $collectionId) {
        id
      }
    }
  `,
  {
    name: "PairThing",
  }
)(
  withTranslation()(
    class PairThing extends Component {
      state = {
        qrOpen: false,
        manualCodeOpen: false,
        thingDetailsOpen: false,
        cameraPermission: true,
        qrLoaded: false,
        dialogClosing: false,
        redirect: "",
        name: "",
        code: "",
      };

      isPairCodeValid = (pairCode) =>
        /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}-[0-9a-f]{16}$/i.test(
          pairCode
        );

      pairThing = async (pairCode, name, collectionId) => {
        const { t } = this.props;
        const { REACT_APP_THING_NAME: thingName } = process.env;

        if (this.isPairCodeValid(pairCode)) {
          try {
            this.setState({ pairLoading: true });

            await this.props.PairThing({
              variables: {
                pairCode,
                name,
                collectionId,
              },
            });

            this.setState({
              manualCodeOpen: false,
              thingDetailsOpen: false,
              redirect: (
                "/?collection=" +
                collectionId +
                "&thing=" +
                pairCode
              ).substring(
                0,
                ("/?collection=" + collectionId + "&thing=" + pairCode).length -
                  17
              ),
            });

            this.props.close();
            this.setState({ dialogClosing: true });
          } catch (e) {
            if (
              e.message === "GraphQL error: pairCode is not correct" ||
              e.message === "GraphQL error: This ID is not valid" ||
              e.message === "GraphQL error: pairCode not valid"
            ) {
              this.setState({ codeError: t`Enter a valid code` });
            } else if (
              e.message ===
              "GraphQL error: The requested resource does not exist"
            ) {
              this.setState({
                codeError: t(
                  `This ${
                    thingName ? thingName.toLowerCase() : "thing"
                  } does not exist`
                ),
              });
            } else if (
              e.message === "GraphQL error: This thing has already been paired"
            ) {
              this.setState({
                codeError: t(
                  `${thingName ? thingName : "Thing"} already paired`
                ),
              });
            } else if (
              e.message ===
              "GraphQL error: This thing has a producer that is not allowed for users on this private cloud"
            ) {
              this.setState({
                codeError: t(
                  `You're not allowed to pair this ${
                    thingName ? thingName.toLowerCase() : "thing"
                  }`
                ),
              });
            } else {
              this.setState({ codeError: "Error" });
            }
          } finally {
            this.setState({ pairLoading: false });
          }
        }
      };

      componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open && nextProps.open) {
          this.setState({
            name: "",
            nameEmpty: "",
            code: "",
            codeEmpty: "",
            codeError: "",
            dialogClosing: false,
          });

          if (window.cordova) {
            window.cordova.plugins.diagnostic.requestCameraAuthorization({
              successCallback: (status) =>
                this.setState({
                  cameraPermission:
                    status ===
                    window.cordova.plugins.diagnostic.permissionStatus.GRANTED,
                }),
              errorCallback: () => this.setState({ cameraPermission: false }),
              externalStorage: false,
            });
          }
        }
      }

      isCameraAuthorized = () => {
        try {
          navigator.mediaDevices.enumerateDevices();

          return true;
        } catch (error) {
          return false;
        }
      };

      render() {
        const { open, close, t } = this.props;
        const {
          thingDetailsOpen,
          pairLoading,
          cameraPermission,
          qrError,
          qrLoaded,
          dialogClosing,
          code,
        } = this.state;
        const {
          REACT_APP_THING_NAME: thingName,
          REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
        } = process.env;

        if (
          this.state.redirect &&
          this.state.redirect !==
            (window.cordova && window.location.hash.split("?").length === 2
              ? "?" + window.location.hash.split("?")[1]
              : window.location.search)
        ) {
          const address = this.state.redirect;
          this.setState({ redirect: "" });
          return <Redirect push to={address} />;
        }

        if (prevThingDetailsOpen !== thingDetailsOpen && thingDetailsOpen) {
          this.setState({
            name: "",
            nameEmpty: false,
            codeError: "",
          });
        }

        prevThingDetailsOpen = thingDetailsOpen;

        return (
          <>
            <GenericDialog
              open={open}
              hidden={
                this.state.qrOpen ||
                this.state.manualCodeOpen ||
                this.state.thingDetailsOpen
              }
              close={() => {
                close();
                this.setState({ dialogClosing: true });
              }}
              title={t(
                `Pair a ${thingName ? thingName.toLowerCase() : "thing"}`
              )}
              textButton={t`Close`}
              textButtonFunction={() => {
                close();
                this.setState({ dialogClosing: true });
              }}
              noHorizontalPadding
            >
              {({ fullScreen }) => (
                <List>
                  <ListItem
                    button
                    style={{
                      paddingLeft:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("left") : "0px") +
                        ")",
                      paddingRight:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("right") : "0px") +
                        ")",
                    }}
                    onClick={() => {
                      if (window.cordova) {
                        window.cordova.plugins.diagnostic.requestCameraAuthorization(
                          {
                            successCallback: (status) => {
                              if (
                                status ===
                                window.cordova.plugins.diagnostic
                                  .permissionStatus.GRANTED
                              ) {
                                this.setState({
                                  qrOpen: true,
                                  qrError: false,
                                  qrLoaded: false,
                                });
                              } else {
                                this.setState({ cameraPermission: false });
                              }
                            },
                            errorCallback: () =>
                              this.setState({ cameraPermission: false }),
                            externalStorage: false,
                          }
                        );
                      } else {
                        this.setState({
                          qrOpen: true,
                          qrError: false,
                          qrLoaded: false,
                        });
                      }
                    }}
                    disabled={
                      (this.props.open &&
                        (window.cordova
                          ? !cameraPermission
                          : !this.isCameraAuthorized())) ||
                      (!cameraPermission && dialogClosing)
                    }
                  >
                    <ListItemIcon>
                      <SvgIcon>
                        <svg
                          style={{ width: "24px", height: "24px" }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M4,4H10V10H4V4M20,4V10H14V4H20M14,15H16V13H14V11H16V13H18V11H20V13H18V15H20V18H18V20H16V18H13V20H11V16H14V15M16,15V18H18V15H16M4,20V14H10V20H4M6,6V8H8V6H6M16,6V8H18V6H16M6,16V18H8V16H6M4,11H6V13H4V11M9,11H13V15H11V13H9V11M11,6H13V10H11V6M2,2V6H0V2A2,2 0 0,1 2,0H6V2H2M22,0A2,2 0 0,1 24,2V6H22V2H18V0H22M2,18V22H6V24H2A2,2 0 0,1 0,22V18H2M22,22V18H24V22A2,2 0 0,1 22,24H18V22H22Z" />
                        </svg>
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <font style={{ color: textColor }}>
                          {t`Scan QR code`}
                        </font>
                      }
                    />
                  </ListItem>
                  <ListItem
                    button
                    style={{
                      paddingLeft:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("left") : "0px") +
                        ")",
                      paddingRight:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("right") : "0px") +
                        ")",
                    }}
                    onClick={() => {
                      this.setState({
                        manualCodeOpen: true,
                        code: "",
                        codeEmpty: false,
                        codeError: false,
                        name: "",
                        nameEmpty: false,
                      });
                    }}
                  >
                    <ListItemIcon>
                      <SvgIcon>
                        <svg
                          style={{ width: "24px", height: "24px" }}
                          viewBox="0 0 24 24"
                        >
                          <path d="M17,7H22V17H17V19A1,1 0 0,0 18,20H20V22H17.5C16.95,22 16,21.55 16,21C16,21.55 15.05,22 14.5,22H12V20H14A1,1 0 0,0 15,19V5A1,1 0 0,0 14,4H12V2H14.5C15.05,2 16,2.45 16,3C16,2.45 16.95,2 17.5,2H20V4H18A1,1 0 0,0 17,5V7M2,7H13V9H4V15H13V17H2V7M20,15V9H17V15H20Z" />
                        </svg>
                      </SvgIcon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <font style={{ color: textColor }}>
                          {t`Insert code manually`}
                        </font>
                      }
                    />
                  </ListItem>
                </List>
              )}
            </GenericDialog>
            <GenericDialog
              open={this.state.qrOpen}
              close={() => this.setState({ qrOpen: false })}
              title={t`Scan QR code`}
              textButton={t`Close`}
              textButtonFunction={() => this.setState({ qrOpen: false })}
              noVerticalPadding
              noHorizontalPadding
              typographyStyle={{ height: "100%" }}
              onFullScreenChanged={() => this.setState({ qrLoaded: false })}
            >
              {qrError || (window.cordova && !cameraPermission) ? (
                <Typography
                  variant="h5"
                  className="notSelectable defaultCursor"
                  style={{
                    textAlign: "center",
                    marginTop: "32px",
                    marginBottom: "16px",
                    color: textColor,
                    marginLeft: getNotchHeight("left"),
                    marginRight: getNotchHeight("right"),
                  }}
                >
                  {t`Something went wrong`}
                </Typography>
              ) : (
                <div
                  style={{
                    visibility: qrLoaded ? "visible" : "collapse",
                  }}
                >
                  <QrReader
                    showViewFinder={false}
                    onError={() => this.setState({ qrError: true })}
                    onScan={(code) => {
                      if (code) {
                        this.setState({
                          qrOpen: false,
                          code,
                          thingDetailsOpen: true,
                          codeEmpty: false,
                          codeError: "",
                        });
                      }
                    }}
                    onLoad={async () => {
                      if (window.cordova) await sleep(500);

                      this.setState({ qrLoaded: true });
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                    facingMode="rear"
                    chooseDeviceId={(_, allDevices) =>
                      allDevices[
                        allDevices.length > 4 ? 4 : allDevices.length - 1
                      ].deviceId
                    }
                  />
                </div>
              )}
            </GenericDialog>
            <GenericDialog
              open={this.state.manualCodeOpen && !this.state.thingDetailsOpen}
              close={() => this.setState({ manualCodeOpen: false })}
              title={t`Insert code manually`}
              textButton={t`Close`}
              textButtonFunction={() =>
                this.setState({ manualCodeOpen: false })
              }
              containedButton={t`Pair`}
              containedButtonFunction={() =>
                this.pairThing(
                  this.state.code,
                  this.state.name,
                  this.props.collection
                )
              }
              containedButtonLoading={pairLoading}
              containedButtonDisabled={
                !this.state.name.replace(/\s/g, "").length ||
                pairLoading ||
                (!this.isPairCodeValid(code) && code)
              }
            >
              <TextField
                id="add-thing-code"
                label={t`Code`}
                required
                value={this.state.code}
                variant="outlined"
                error={
                  this.state.codeEmpty ||
                  this.state.codeError ||
                  (!this.isPairCodeValid(code) && code)
                }
                helperText={
                  this.state.codeEmpty
                    ? t`This field is required`
                    : !this.isPairCodeValid(code) && code
                    ? t`Enter a valid code`
                    : this.state.codeError || " "
                }
                onChange={(event) =>
                  this.setState({
                    code: event.target.value,
                    codeEmpty: event.target.value.replace(/\s/g, "") === "",
                    codeError: "",
                  })
                }
                onKeyPress={(event) => {
                  if (
                    event.key === "Enter" &&
                    this.state.code.replace(/\s/g, "").length &&
                    this.state.name.replace(/\s/g, "").length
                  ) {
                    this.pairThing(
                      this.state.code,
                      this.state.name,
                      this.props.collection
                    );
                    this.setState({ thingDetailsOpen: false });
                  }
                }}
                style={{
                  width: "100%",
                  marginBottom: "16px",
                }}
                InputLabelProps={this.state.code ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: this.state.code && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            code: "",
                            codeEmpty: true,
                            codeError: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="manual-pair-thing-name"
                label={t`Name`}
                required
                value={this.state.name}
                variant="outlined"
                error={this.state.nameEmpty || this.state.name.length > 256}
                helperText={
                  this.state.name.length > 256
                    ? t`Use fewer characters`
                    : this.state.nameEmpty
                    ? t`This field is required`
                    : " "
                }
                onChange={(event) =>
                  this.setState({
                    name: event.target.value,
                    nameEmpty: event.target.value.replace(/\s/g, "") === "",
                  })
                }
                onKeyPress={(event) => {
                  if (
                    event.key === "Enter" &&
                    this.state.code.replace(/\s/g, "").length &&
                    this.state.name.replace(/\s/g, "").length &&
                    this.state.name.length <= 256
                  ) {
                    this.pairThing(
                      this.state.code,
                      this.state.name,
                      this.props.collection
                    );
                    this.setState({ thingDetailsOpen: false });
                  }
                }}
                style={{
                  width: "100%",
                }}
                InputLabelProps={this.state.name ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: this.state.name && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            name: "",
                            nameEmpty: true,
                            codeError: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GenericDialog>
            <GenericDialog
              open={this.state.thingDetailsOpen}
              close={() => this.setState({ thingDetailsOpen: false })}
              title={t`Choose a name`}
              textButton={t`Close`}
              textButtonFunction={() =>
                this.setState({
                  thingDetailsOpen: false,
                  qrOpen: true,
                  qrLoaded: false,
                })
              }
              containedButton={t`Pair`}
              containedButtonFunction={() =>
                this.pairThing(
                  this.state.code,
                  this.state.name,
                  this.props.collection
                )
              }
              containedButtonLoading={pairLoading}
              containedButtonDisabled={
                !this.state.name.replace(/\s/g, "").length ||
                this.state.codeError ||
                pairLoading
              }
            >
              <TextField
                id="qr-pair-thing-name"
                label={t`Name`}
                required
                value={this.state.name}
                variant="outlined"
                error={this.state.nameEmpty || this.state.codeError}
                helperText={
                  this.state.nameEmpty
                    ? t`This field is required`
                    : this.state.codeError
                    ? this.state.codeError
                    : " "
                }
                onChange={(event) =>
                  this.setState({
                    name: event.target.value,
                    nameEmpty: event.target.value.replace(/\s/g, "") === "",
                    codeError: "",
                  })
                }
                onKeyPress={(event) => {
                  if (
                    event.key === "Enter" &&
                    !this.state.nameEmpty &&
                    !this.state.codeError
                  ) {
                    this.pairThing(
                      this.state.code,
                      this.state.name,
                      this.props.collection
                    );
                  }
                }}
                style={{
                  width: "100%",
                }}
                InputLabelProps={this.state.name ? { shrink: true } : {}}
                InputProps={{
                  endAdornment: this.state.name && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() =>
                          this.setState({
                            name: "",
                            nameEmpty: true,
                            codeError: "",
                          })
                        }
                        tabIndex="-1"
                        size="large"
                      >
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </GenericDialog>
          </>
        );
      }
    }
  )
);
