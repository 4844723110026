import React, { Component } from "react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { createTheme, adaptV4Theme } from "@mui/material/styles";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import DialogContent from "@mui/material/DialogContent";
import {
  GenericDialog,
  ErrorScreen,
  CenteredSpinner,
  getNotchHeight,
} from "@igloocloud/igloosharedui";
import LinearProgress from "@mui/material/LinearProgress";
import IsOnline from "is-online-component";
import gql from "graphql-tag";
import { graphql } from "@apollo/react-hoc";
import { withTranslation } from "react-i18next";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";

const VARIABLE_LIMIT = 20;

const {
  REACT_APP_LIGHT_COLOR: lightColor,
  REACT_APP_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS: lightContrastColor,
} = process.env;

let dialogContent = null;

export default withTranslation()(
  class ManageCards extends Component {
    constructor(props) {
      super(props);

      this.state = {
        hasReceivedOpen: false,
      };
    }

    componentWillReceiveProps(nextProps) {
      if (nextProps.open !== this.props.open && nextProps.open) {
        this.setState({ hasReceivedOpen: true });
      }
    }

    render() {
      const { open, close, t } = this.props;

      return (
        <GenericDialog
          open={open}
          close={close}
          title={t`Choose which cards to see`}
          textButton={t`Close`}
          textButtonFunction={close}
          noHorizontalPadding
          noDialogContent
        >
          {this.state.hasReceivedOpen && <ManageCardsContent {...this.props} />}
        </GenericDialog>
      );
    }
  }
);

const ManageCardsContent = graphql(
  gql`
    mutation ChangeVisibility($id: ID!, $hidden: Boolean) {
      updateVariable(id: $id, hidden: $hidden) {
        id
        hidden
      }
    }
  `,
  {
    name: "ChangeVisibility",
  }
)(
  graphql(
    gql`
      query ($limit: NaturalNumber!, $offset: NaturalNumber, $id: ID!) {
        thing(id: $id) {
          id
          variableCount
          variables(limit: $limit, offset: $offset, sortBy: index) {
            id
            index
            hidden
            name
            thing {
              id
            }
          }
        }
      }
    `,
    {
      name: "thingData",
      options: ({ id }) => ({
        variables: { offset: 0, limit: VARIABLE_LIMIT, id },
      }),
    }
  )(
    class ManageCardsDialogContent extends Component {
      constructor(props) {
        super(props);

        this.state = { fetchMoreLoading: false };
      }

      queryMore = async () => {
        if (
          !this.queryMore.locked &&
          this.props.thingData.thing.variableCount >
            this.props.thingData.thing.variables.length
        ) {
          this.queryMore.locked = true;

          try {
            this.setState({ fetchMoreLoading: true });
            await this.props.thingData.fetchMore({
              variables: {
                offset: this.props.thingData.thing.variables.length,
                limit:
                  this.props.thingData.thing.variableCount -
                    this.props.thingData.thing.variables.length >=
                  20
                    ? 20
                    : this.props.thingData.thing.variableCount % 20,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return prev;
                }

                const newVariables = [
                  ...prev.thing.variables,
                  ...fetchMoreResult.thing.variables,
                ].sort((a, b) =>
                  a.index > b.index ? 1 : a.index < b.index ? -1 : 0
                );

                return {
                  thing: {
                    ...prev.thing,
                    variables: newVariables,
                  },
                };
              },
            });
          } finally {
            this.setState(() => {
              this.queryMore.locked = false;

              return { fetchMoreLoading: false };
            });
          }
        }
      };

      changeVisibilityMutation = (variable) => {
        this.props.ChangeVisibility({
          variables: {
            id: variable.id,
            hidden: !variable.hidden,
          },
          optimisticResponse: {
            __typename: "Mutation",
            variable: {
              __typename: variable.__typename,
              id: variable.id,
              hidden: !variable.hidden,
            },
          },
        });
      };

      componentDidMount() {
        this.props.thingData.refetch();

        this.props.thingData.subscribeToMore({
          document: gql`
            subscription ($thingId: ID) {
              variableCreated(thingId: $thingId) {
                id
                index
                hidden
                name
                thing {
                  id
                }
              }
            }
          `,
          variables: {
            thingId: this.props.id,
          },
          updateQuery: (prev, { subscriptionData }) => {
            if (
              !subscriptionData.data ||
              subscriptionData.data.variableCreated.thing.id !== prev.thing.id
            ) {
              return prev;
            }

            const newVariables = [
              ...prev.thing.variables,
              subscriptionData.data.variableCreated,
            ].sort((a, b) =>
              a.index > b.index ? 1 : a.index === b.index ? 0 : -1
            );

            return {
              thing: {
                ...prev.thing,
                variables: newVariables,
              },
            };
          },
        });

        this.props.thingData.subscribeToMore({
          document: gql`
            subscription ($thingId: ID) {
              variableUpdated(thingId: $thingId) {
                id
                index
                hidden
                name
                thing {
                  id
                }
              }
            }
          `,
          variables: {
            thingId: this.props.id,
          },
          updateQuery: (prev, { subscriptionData }) => {
            if (
              !subscriptionData.data ||
              subscriptionData.data.variableUpdated.thing.id !== prev.thing.id
            ) {
              return prev;
            }

            const newVariables = [
              ...prev.thing.variables.filter(
                (variable) =>
                  variable.id !== subscriptionData.data.variableUpdated.id
              ),
              subscriptionData.data.variableUpdated,
            ].sort((a, b) =>
              a.index > b.index ? 1 : a.index === b.index ? 0 : -1
            );

            return {
              thing: {
                ...prev.thing,
                variables: newVariables,
              },
            };
          },
        });

        this.props.thingData.subscribeToMore({
          document: gql`
            subscription ($thingId: ID) {
              variableDeleted(thingId: $thingId)
            }
          `,
          variables: {
            thingId: this.props.id,
          },
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) {
              return prev;
            }

            const newVariables = prev.thing.variables.filter(
              (variable) =>
                variable.id !== subscriptionData.data.variableDeleted
            );

            return {
              thing: {
                ...prev.thing,
                variables: newVariables,
              },
            };
          },
        });

        this.props.thingData.subscribeToMore({
          document: gql`
            subscription ($id: ID, $limit: NaturalNumber!) {
              thingUpdated(id: $id) {
                id
                variables(limit: $limit) {
                  id
                  index
                  hidden
                  name
                  thing {
                    id
                  }
                }
              }
            }
          `,
          variables: {
            id: this.props.id,
            limit:
              this.props.thingData.thing?.variables.length || VARIABLE_LIMIT,
          },
          updateQuery: (prev, { subscriptionData }) => ({
            thing: {
              ...prev.thing,
              variables: subscriptionData.data.thingUpdated.variables.sort(
                (a, b) => (a.index > b.index ? 1 : a.index < b.index ? -1 : 0)
              ),
            },
          }),
        });
      }

      render() {
        const {
          thingData: { loading, error, thing, refetch, id },
          fullScreen,
        } = this.props;

        if (loading)
          dialogContent = (
            <div
              style={{
                height: "100%",
                marginLeft:
                  "calc(24px + " +
                  (fullScreen ? getNotchHeight("left") : "0px") +
                  ")",
                marginRight:
                  "calc(24px + " +
                  (fullScreen ? getNotchHeight("right") : "0px") +
                  ")",
              }}
            >
              <CenteredSpinner />
            </div>
          );

        if (error)
          dialogContent = (
            <div
              style={{
                height: "100%",
                marginLeft:
                  "calc(24px + " +
                  (fullScreen ? getNotchHeight("left") : "0px") +
                  ")",
                marginRight:
                  "calc(24px + " +
                  (fullScreen ? getNotchHeight("right") : "0px") +
                  ")",
              }}
            >
              <ErrorScreen
                refetch={() =>
                  refetch({
                    variables: { offset: 0, limit: 20, id },
                  })
                }
                error={error}
              />
            </div>
          );

        if (thing)
          dialogContent = (
            <DialogContent
              style={{ padding: 0 }}
              onScroll={(event) => {
                if (
                  event.target.scrollTop + event.target.clientHeight >=
                  event.target.scrollHeight - 600
                )
                  this.queryMore();
              }}
            >
              <IsOnline
                onChange={(online) => {
                  if (online) {
                    this.props.thingData.refetch();
                  }
                }}
              />
              <List style={{ height: "100%", padding: "0" }}>
                {thing.variables.map((variable) => (
                  <ListItem
                    key={variable.id}
                    button
                    style={{
                      cursor: "pointer",
                      paddingLeft:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("left") : "0px") +
                        ")",
                      paddingRight:
                        "calc(24px + " +
                        (fullScreen ? getNotchHeight("right") : "0px") +
                        ")",
                    }}
                    onClick={() => this.changeVisibilityMutation(variable)}
                  >
                    <ListItemIcon>
                      <StyledEngineProvider injectFirst>
                        <ThemeProvider
                          theme={createTheme(
                            adaptV4Theme({
                              overrides: {
                                MuiIconButton: {
                                  colorPrimary: {
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                  },
                                },
                                MuiCheckbox: {
                                  colorPrimary: {
                                    "&$checked": {
                                      color: lightContrastColor || lightColor,
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    },
                                  },
                                },
                              },
                            })
                          )}
                        >
                          <Checkbox
                            color="primary"
                            tabIndex={-1}
                            disableRipple
                            checked={!variable.hidden}
                          />
                        </ThemeProvider>
                      </StyledEngineProvider>
                    </ListItemIcon>
                    <ListItemText
                      primary={variable.name}
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        cursor: "pointer",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
              {this.state.fetchMoreLoading && <LinearProgress />}
            </DialogContent>
          );

        return dialogContent;
      }
    }
  )
);
