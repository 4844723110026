import React from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import moment from "moment"
import Moment from "react-moment"
import { withTranslation } from "react-i18next"

const { REACT_APP_COLLECTION_NAME: collectionName } = process.env

export default withTranslation()(({ open, close, collection, t }) => (
  <GenericDialog
    open={open}
    close={close}
    title={t((collectionName || "Collection") + " information")}
    textButton={t`Close`}
    textButtonFunction={close}
    selectable
    typographyStyle={{ overflowX: "hidden", textOverflow: "ellipsis" }}
  >
    <b>{t`Created` + ": "}</b>
    <Moment fromNow>
      {moment.utc(collection.createdAt.split(".")[0], "YYYY-MM-DDTh:mm:ss")}
    </Moment>
    <br />
    <b>{t`Last updated` + ": "}</b>
    <Moment fromNow>
      {moment.utc(collection.updatedAt.split(".")[0], "YYYY-MM-DDTh:mm:ss")}
    </Moment>
    <br />
    <b>{t`ID` + ": "}</b> {collection.id}
  </GenericDialog>
))
