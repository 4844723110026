import { graphql } from "@apollo/react-hoc";
import { GenericDialog } from "@igloocloud/igloosharedui";
import Clear from "@mui/icons-material/Clear";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import gql from "graphql-tag";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import SwipeableViews from "react-swipeable-views";
import tinyColor from "tinycolor2";

export default graphql(
  gql`
    mutation CreateCollection($name: String!, $picture: NaturalNumber) {
      createCollection(name: $name, picture: $picture) {
        id
        name
        picture
      }
    }
  `,
  {
    name: "CreateCollection",
  }
)(
  withTranslation()(
    class CreateCollection extends Component {
      constructor(props) {
        super(props);

        const importAll = (require) =>
          require.keys().reduce((acc, next) => {
            acc[next.replace("./", "")] = require(next);
            return acc;
          }, {});

        this.images = Object.values(
          importAll(
            require.context(
              "../../styles/assets/collectionCards",
              false,
              /\.(png|jpe?g|svg)$/
            )
          )
        );

        this.state = {
          name: "",
          slideIndex: 0,
          nameEmpty: false,
          nameError: "",
        };
      }

      componentWillReceiveProps(nextProps) {
        if (this.props.open !== nextProps.open && nextProps.open) {
          this.setState({
            nameEmpty: false,
            name: "",
            nameError: "",
            slideIndex: Math.floor(Math.random() * this.images.length),
            showLoading: false,
          });
        }
      }

      createCollectionMutation = async () => {
        this.setState({ showLoading: true });

        try {
          await this.props.CreateCollection({
            variables: {
              name: this.state.name,
              picture: this.state.slideIndex,
            },
            optimisticResponse: {
              __typename: "Mutation",
              createCollection: {
                name: this.state.name,
                picture: this.state.slideIndex,
                __typename: "Collection",
              },
            },
          });

          this.props.close();
        } catch (e) {
          this.setState({ nameError: "Error" });
        } finally {
          this.setState({ showLoading: false });
        }
      };

      render() {
        const { open, close, t } = this.props;
        const { showLoading, name, nameEmpty, nameError, slideIndex } =
          this.state;
        const {
          REACT_APP_COLLECTION_NAME: collectionName,
          REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
        } = process.env;

        return (
          <GenericDialog
            open={open}
            close={close}
            title={t(
              "Create " + (collectionName?.toLowerCase() || "collection")
            )}
            textButton={t`Close`}
            textButtonFunction={close}
            containedButton={t`Create`}
            containedButtonFunction={this.createCollectionMutation}
            containedButtonDisabled={
              !name.replace(/\s/g, "").length ||
              name.length > 256 ||
              showLoading
            }
            containedButtonLoading={showLoading}
          >
            <TextField
              id="create-collection-name"
              label={t`Name`}
              value={name}
              variant="outlined"
              error={nameEmpty || nameError || name.length > 256}
              helperText={
                name.length > 256
                  ? t`Use fewer characters`
                  : nameEmpty
                  ? t`This field is required`
                  : nameError || " "
              }
              onChange={(event) =>
                this.setState({
                  name: event.target.value,
                  nameEmpty: event.target.value.replace(/\s/g, "") === "",
                  nameError: "",
                })
              }
              onKeyPress={(event) => {
                if (event.key === "Enter" && name)
                  this.createCollectionMutation();
              }}
              style={{
                width: "100%",
                marginBottom: "16px",
              }}
              InputLabelProps={name ? { shrink: true } : {}}
              InputProps={{
                endAdornment: name && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        this.setState({
                          name: "",
                          nameEmpty: true,
                          nameError: "",
                        })
                      }
                      tabIndex="-1"
                      size="large"
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <SwipeableViews
              index={slideIndex}
              onChangeIndex={(value) => {
                this.setState({
                  slideIndex: value,
                });
              }}
              style={{
                width: "100%",
              }}
            >
              {this.images.map(({ default: image }, index) => (
                <img
                  src={image}
                  key={"collection-image-" + index}
                  alt="Collection"
                  className="notSelectable"
                  draggable="false"
                  style={{
                    width: "100%",
                  }}
                />
              ))}
            </SwipeableViews>
            <div>
              <Button
                size="small"
                onClick={() =>
                  this.setState((oldState) => ({
                    slideIndex: oldState.slideIndex - 1,
                  }))
                }
                disabled={slideIndex === 0}
                style={{
                  color: tinyColor(textColor)
                    .setAlpha(slideIndex === 0 ? 0.54 : 1)
                    .toRgbString(),
                }}
              >
                <KeyboardArrowLeft />
                {t`Back`}
              </Button>
              <Button
                size="small"
                onClick={() =>
                  this.setState((oldState) => ({
                    slideIndex: oldState.slideIndex + 1,
                  }))
                }
                disabled={slideIndex === this.images.length - 1}
                style={{
                  float: "right",
                  marginRight: 0,
                  marginLeft: "auto",
                  color: tinyColor(textColor)
                    .setAlpha(slideIndex === this.images.length - 1 ? 0.54 : 1)
                    .toRgbString(),
                }}
              >
                {t`Next`}
                <KeyboardArrowRight />
              </Button>
            </div>
          </GenericDialog>
        );
      }
    }
  )
);
