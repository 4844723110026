import React, { Component } from "react"
import { GenericDialog } from "@igloocloud/igloosharedui"
import Clear from "@mui/icons-material/Clear"
import TextField from "@mui/material/TextField"
import InputAdornment from "@mui/material/InputAdornment"
import IconButton from "@mui/material/IconButton"
import { graphql } from "@apollo/react-hoc"
import gql from "graphql-tag"
import { withTranslation } from "react-i18next"

export default graphql(
  gql`
    mutation Rename($id: ID!, $name: String) {
      updateThing(id: $id, name: $name) {
        id
        name
      }
    }
  `,
  {
    name: "Rename",
  }
)(
  withTranslation()(
    class RenameThing extends Component {
      state = { name: "", nameEmpty: false, loading: false }

      rename = async () => {
        this.setState({ loading: true })

        await this.props.Rename({
          variables: {
            id: this.props.thing.id,
            name: this.state.name,
          },
          optimisticResponse: {
            __typename: "Mutation",
            thing: {
              __typename: this.props.thing.__typename,
              id: this.props.thing.id,
              name: this.state.name,
            },
          },
        })

        this.props.close()

        this.setState({ loading: false })
      }

      componentWillReceiveProps(nextProps) {
        if (nextProps.open && !this.props.open) {
          this.setState({ name: this.props.thing.name, nameEmpty: "" })
        }
      }

      render() {
        const { open, close, t } = this.props
        const { loading } = this.state
        const { REACT_APP_THING_NAME: thingName } = process.env

        return (
          <GenericDialog
            open={open}
            close={close}
            title={t(`Rename ${thingName?.toLowerCase() || "thing"}`)}
            textButton={t`Close`}
            textButtonFunction={close}
            containedButton={t`Rename`}
            containedButtonDisabled={
              !this.state.name.replace(/\s/g, "").length || loading
            }
            containedButtonFunction={this.rename}
            containedButtonLoading={loading}
          >
            <TextField
              id="rename-thing"
              label={t`Name`}
              value={this.state.name}
              variant="outlined"
              error={this.state.nameEmpty || this.state.name.length > 256}
              helperText={
                this.state.name.length > 256
                  ? t`Too long`
                  : this.state.nameEmpty
                  ? t`This field is required`
                  : " "
              }
              onChange={(event) =>
                this.setState({
                  name: event.target.value,
                  nameEmpty: event.target.value.replace(/\s/g, "") === "",
                })
              }
              onKeyPress={(event) => {
                if (event.key === "Enter" && !this.state.nameEmpty)
                  this.rename()
              }}
              style={{
                width: "100%",
              }}
              InputLabelProps={this.state.name ? { shrink: true } : {}}
              InputProps={{
                endAdornment: this.state.name && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        this.setState({ name: "", nameEmpty: true })
                      }
                      tabIndex="-1"
                    >
                      <Clear />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </GenericDialog>
        )
      }
    }
  )
)
