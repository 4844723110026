import React, { Component } from "react";
import { AutoSizer } from "react-virtualized";
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  LineMarkSeries,
  MarkSeries,
  Highlight,
  Borders,
  Crosshair,
} from "react-vis";
import { withRouter } from "react-router";
import tinyColor from "tinycolor2";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {
  roundToPrecision,
  valueConversion,
  unitConversion,
  getTickFormat,
  debounce,
  isNullish,
} from "@igloocloud/igloosharedui";

export default withRouter(
  class Plot extends Component {
    state = {
      left: null,
      right: null,
      lessThan1200: null,
      crosshairValues: [],
      leftLabelWidth: 0,
      rightLabelWidth: 0,
    };

    updateDimensions = () => {
      const { lessThan1200 } = this.state;

      if (window.innerWidth < 1200) {
        !lessThan1200 && this.setState({ lessThan1200: true });
      } else {
        lessThan1200 && this.setState({ lessThan1200: false });
      }
    };

    checkIfNoData = (
      shownSeries,
      startDate,
      endDate,
      selectedVariables,
      loadingVariables
    ) => {
      if (
        selectedVariables[0] &&
        !loadingVariables[0] &&
        shownSeries[0] &&
        !shownSeries.flatMap(({ nodes }) =>
          nodes
            .filter(
              ({ value, timestamp }) =>
                !isNullish(value) &&
                new Date(timestamp) >= startDate &&
                new Date(timestamp) <= endDate
            )
            .flatMap(({ timestamp }) => new Date(timestamp))
        ).length
      ) {
        this.props.setSnackbarOpen(true);
      } else {
        this.props.setSnackbarOpen(false);
      }
    };

    componentDidMount() {
      this.updateDimensions();
      window.addEventListener("resize", debounce(this.updateDimensions));

      this.unlisten = this.props.history.listen((location) => {
        if (location.pathname === "/data") {
          this.checkIfNoData(
            this.props.shownSeries,
            this.props.startDate,
            this.props.endDate,
            this.props.selectedVariables,
            this.props.loadingVariables
          );
        } else {
          this.props.setSnackbarOpen(false);
        }
      });
    }

    componentWillUnmount() {
      window.removeEventListener("resize", debounce(this.updateDimensions));

      this.unlisten();
    }

    computeLabelWidth(text) {
      let canvas =
        this.computeTextWidth.canvas ||
        (this.computeTextWidth.canvas = document.createElement("canvas"));
      let context = canvas.getContext("2d");
      let metrics = context.measureText(text);

      return metrics.width * 1.06;
    }

    computeTextWidth(text) {
      let canvas =
        this.computeTextWidth.canvas ||
        (this.computeTextWidth.canvas = document.createElement("canvas"));
      let context = canvas.getContext("2d");
      let metrics = context.measureText(text);

      return metrics.width * 1.65;
    }

    componentWillReceiveProps(nextProps) {
      if (
        (nextProps.startDate !== this.props.startDate ||
          nextProps.endDate !== this.props.endDate ||
          nextProps.shownSeries.length !== this.props.shownSeries.length ||
          nextProps.shownSeries.flatMap(({ nodes }) =>
            nodes
              .filter(
                ({ value, timestamp }) =>
                  value &&
                  new Date(timestamp) >= nextProps.startDate &&
                  new Date(timestamp) <= nextProps.endDate
              )
              .flatMap(({ timestamp }) => new Date(timestamp))
          ).length !==
            this.props.shownSeries.flatMap(({ nodes }) =>
              nodes
                .filter(
                  ({ value, timestamp }) =>
                    value &&
                    new Date(timestamp) >= nextProps.startDate &&
                    new Date(timestamp) <= nextProps.endDate
                )
                .flatMap(({ timestamp }) => new Date(timestamp))
            ).length) &&
        window.location.pathname === "/data"
      ) {
        this.checkIfNoData(
          nextProps.shownSeries,
          nextProps.startDate,
          nextProps.endDate,
          nextProps.selectedVariables,
          nextProps.loadingVariables
        );
      }
    }

    render() {
      const {
        shownUnits,
        startDate,
        endDate,
        shownSeries,
        user,
        selectedVariables,
        enableAnimation,
      } = this.props;
      const {
        left,
        right,
        lessThan1200,
        crosshairValues,
        leftLabelWidth,
        rightLabelWidth,
      } = this.state;
      const {
        REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textColor,
        REACT_APP_LIGHT_COLOR_ON_LIGHT_BACKGROUNDS: lightContrastColor,
        REACT_APP_LIGHT_COLOR: lightColor,
        REACT_APP_MAIN_BACKGROUND_COLOR: backgroundColor,
        REACT_APP_SERIES_COLORS,
      } = process.env;
      const seriesColors = JSON.parse(REACT_APP_SERIES_COLORS);

      const shownTimestamps = shownSeries
        .flatMap(({ nodes }) =>
          nodes
            .filter(
              ({ value, timestamp }) =>
                !isNullish(value) &&
                new Date(timestamp) >= startDate &&
                new Date(timestamp) <= endDate
            )
            .flatMap(({ timestamp }) => new Date(timestamp))
        )
        .sort((a, b) => (+a > +b ? 1 : +a === +b ? 0 : -1));
      let shownNodesByUnit = { firstUnit: [], secondUnit: [] };
      shownSeries.forEach(({ unitOfMeasurement, nodes }) =>
        unitOfMeasurement === shownUnits[0]
          ? shownNodesByUnit.firstUnit.push(
              ...nodes
                .filter(
                  ({ timestamp }) =>
                    new Date(timestamp) >= startDate &&
                    new Date(timestamp) <= endDate
                )
                .map(({ value }) => value)
            )
          : shownNodesByUnit.secondUnit.push(
              ...nodes
                .filter(
                  ({ timestamp }) =>
                    new Date(timestamp) >= startDate &&
                    new Date(timestamp) <= endDate
                )
                .map(({ value }) => value)
            )
      );

      return (
        <AutoSizer>
          {({ height, width }) => (
            <XYPlot
              margin={{
                top: 32,
                right:
                  shownUnits[1] !== undefined && shownTimestamps[0]
                    ? rightLabelWidth + 16
                    : 16,
                bottom: 40,
                left: leftLabelWidth + 16,
              }}
              width={width}
              height={height}
              xDomain={
                shownTimestamps.length === 1
                  ? [+shownTimestamps[0] - 60_000, +shownTimestamps[0] + 60_000]
                  : left && right
                  ? [left, right]
                  : [
                      +startDate < +shownTimestamps[0]
                        ? shownTimestamps[0]
                        : startDate,
                      +endDate > +shownTimestamps[shownTimestamps.length - 1]
                        ? shownTimestamps[shownTimestamps.length - 1]
                        : endDate,
                    ]
              }
              yDomain={[0, 1]}
              xType="time"
              onMouseLeave={() => this.setState({ crosshairValues: [] })}
              onTouchEnd={() => this.setState({ crosshairValues: [] })}
              dontCheckIfEmpty
            >
              {user && shownTimestamps[0] && startDate && endDate
                ? shownSeries
                    .map((series) => {
                      let minValue, maxValue;
                      if (series.unitOfMeasurement === shownUnits[0]) {
                        minValue = Math.min(...shownNodesByUnit.firstUnit);
                        maxValue = Math.max(...shownNodesByUnit.firstUnit);
                      } else {
                        minValue = Math.min(...shownNodesByUnit.secondUnit);
                        maxValue = Math.max(...shownNodesByUnit.secondUnit);
                      }

                      return {
                        ...series,
                        nodes: series.nodes.map((node) => ({
                          ...node,
                          value:
                            node.value === null
                              ? null
                              : maxValue === minValue
                              ? 0.5
                              : (node.value - minValue) / (maxValue - minValue),
                        })),
                      };
                    })
                    .map((floatSeriesVariable, index) =>
                      floatSeriesVariable.nodes.length === 1 ? (
                        <MarkSeries
                          key={"mark-series" + floatSeriesVariable.id}
                          animation={enableAnimation}
                          data={floatSeriesVariable.nodes.map((node) => ({
                            x: +new Date(node.timestamp),
                            y: 0.5,
                          }))}
                          color={seriesColors[index % seriesColors.length]}
                          opacity={1}
                          size={3}
                          getNull={(node) => node.y !== null}
                        />
                      ) : floatSeriesVariable.visualization === "mark" ||
                        floatSeriesVariable.visualization === "bar" ||
                        floatSeriesVariable.visualization ===
                          "windDirection" ? (
                        <MarkSeries
                          key={"mark-series" + floatSeriesVariable.id}
                          animation={enableAnimation}
                          data={floatSeriesVariable.nodes.map((node) => ({
                            x: +new Date(node.timestamp),
                            y: roundToPrecision(
                              valueConversion(
                                node.value,
                                floatSeriesVariable.unitOfMeasurement,
                                user.lengthAndMass,
                                user.temperature
                              ),
                              floatSeriesVariable.precision
                            ),
                          }))}
                          color={seriesColors[index % seriesColors.length]}
                          size={3}
                          getNull={(node) => node.y !== null}
                        />
                      ) : (
                        <LineMarkSeries
                          key={"line-series" + floatSeriesVariable.id}
                          animation={enableAnimation}
                          curve="curveMonotoneX"
                          data={floatSeriesVariable.nodes.map((node) => ({
                            x: +new Date(node.timestamp),
                            y: node.value,
                          }))}
                          size={0}
                          opacity={1}
                          strokeStyle="solid"
                          stroke={
                            seriesColors[
                              selectedVariables.findIndex(
                                (id) => id === floatSeriesVariable.id
                              ) % seriesColors.length
                            ]
                          }
                          strokeWidth={3}
                          getNull={(node) => node.y !== null}
                        />
                      )
                    )
                : null}
              {startDate && endDate && (
                <MarkSeries
                  data={shownTimestamps.map((x) => ({ x, y: null }))}
                  size={0}
                  onNearestX={(value, { index }) => {
                    if (
                      (!left ||
                        !right ||
                        (value.x >= left && value.x <= right)) &&
                      value.x >= startDate &&
                      value.x <= endDate
                    ) {
                      this.setState({
                        crosshairValues: [
                          {
                            x: shownTimestamps[index],
                            y: 0.5,
                          },
                        ],
                      });
                    }
                  }}
                />
              )}
              <Borders
                style={{
                  all: { fill: backgroundColor },
                  top: { height: "30px" },
                  bottom: { y: height - 38, height: "38px" },
                }}
              />
              <HorizontalGridLines
                style={{
                  stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                }}
                tickTotal={12}
              />
              <XAxis
                attr="x"
                attrAxis="y"
                orientation="bottom"
                className="notSelectable"
                tickSize={startDate && endDate ? 6 : 0}
                tickFormat={(date) =>
                  user && startDate && endDate
                    ? shownTimestamps[0]
                      ? moment(date).format(
                          shownTimestamps.length === 1
                            ? user.timeFormat === "H24"
                              ? "HH:mm:ss"
                              : "hh:mm:ss A"
                            : getTickFormat(
                                shownTimestamps[0],
                                shownTimestamps[shownTimestamps.length - 1],
                                user.dateFormat,
                                user.timeFormat,
                                moment
                              )
                        )
                      : moment(date).format(
                          shownTimestamps.length === 1
                            ? user.timeFormat === "H24"
                              ? "HH:mm:ss"
                              : "hh:mm:ss A"
                            : getTickFormat(
                                startDate,
                                endDate,
                                user.dateFormat,
                                user.timeFormat,
                                moment
                              )
                        )
                    : ""
                }
                style={{
                  line: {
                    stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                  },
                  ticks: {
                    stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                  },
                  text: {
                    stroke: tinyColor(textColor).setAlpha(0.25).toRgbString(),
                    fontSize: "10px",
                  },
                }}
                tickTotal={lessThan1200 ? 4 : 8}
              />
              <YAxis
                attr="y"
                attrAxis="x"
                orientation="left"
                className="notSelectable"
                style={{
                  line: {
                    stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                  },
                  ticks: {
                    stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                  },
                  text: {
                    stroke: tinyColor(textColor).setAlpha(0.25).toRgbString(),
                    fontSize: "10px",
                  },
                }}
                tickSize={0}
                tickFormat={(value) => {
                  const convertedValue = user
                    ? valueConversion(
                        shownNodesByUnit.firstUnit.every(
                          (value) => value === shownNodesByUnit.firstUnit[0]
                        )
                          ? value * shownNodesByUnit.firstUnit[0] * 2
                          : value *
                              (Math.max(...shownNodesByUnit.firstUnit) -
                                Math.min(...shownNodesByUnit.firstUnit)) +
                              Math.min(...shownNodesByUnit.firstUnit),
                        shownUnits[0],
                        user.lengthAndMass,
                        user.temperature
                      )
                    : null;

                  const roundedValue = user
                    ? parseFloat(
                        convertedValue.toString().includes(".")
                          ? convertedValue.toPrecision(6)
                          : convertedValue
                      )
                    : null;

                  if (
                    leftLabelWidth <
                    this.computeLabelWidth(
                      user && shownTimestamps[0] && startDate && endDate
                        ? roundedValue +
                            (shownUnits[0] !== "%" ? " " : "") +
                            unitConversion(
                              roundedValue,
                              shownUnits[0],
                              user.lengthAndMass,
                              user.temperature
                            )
                        : ""
                    )
                  ) {
                    this.setState({
                      leftLabelWidth: this.computeLabelWidth(
                        user && shownTimestamps[0] && startDate && endDate
                          ? roundedValue +
                              (shownUnits[0] !== "%" ? " " : "") +
                              unitConversion(
                                roundedValue,
                                shownUnits[0],
                                user.lengthAndMass,
                                user.temperature
                              )
                          : ""
                      ),
                    });
                  }

                  return user && shownTimestamps[0] && startDate && endDate
                    ? roundedValue +
                        (shownUnits[0] !== "%" ? " " : "") +
                        unitConversion(
                          roundedValue,
                          shownUnits[0],
                          user.lengthAndMass,
                          user.temperature
                        )
                    : "";
                }}
                tickTotal={12}
              />
              {shownUnits[1] !== undefined &&
              shownNodesByUnit.secondUnit &&
              shownTimestamps[0] &&
              startDate &&
              endDate ? (
                <YAxis
                  attr="y"
                  attrAxis="x"
                  orientation="right"
                  className="notSelectable"
                  style={{
                    line: {
                      stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                    },
                    ticks: {
                      stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                    },
                    text: {
                      stroke: tinyColor(textColor).setAlpha(0.25).toRgbString(),
                      fontSize: "10px",
                    },
                  }}
                  tickSize={0}
                  tickFormat={(value) => {
                    const convertedValue = user
                      ? valueConversion(
                          value *
                            (Math.max(...shownNodesByUnit.secondUnit) -
                              Math.min(...shownNodesByUnit.secondUnit)) +
                            Math.min(...shownNodesByUnit.secondUnit),
                          shownUnits[1],
                          user.lengthAndMass,
                          user.temperature
                        )
                      : null;

                    const roundedValue = user
                      ? parseFloat(
                          convertedValue.toString().includes(".")
                            ? convertedValue.toPrecision(6)
                            : convertedValue
                        )
                      : null;

                    if (
                      rightLabelWidth <
                      this.computeLabelWidth(
                        user && shownTimestamps[0] && startDate && endDate
                          ? roundedValue +
                              (shownUnits[1] !== "%" ? " " : "") +
                              unitConversion(
                                roundedValue,
                                shownUnits[1],
                                user.lengthAndMass,
                                user.temperature
                              )
                          : ""
                      )
                    ) {
                      this.setState({
                        rightLabelWidth: this.computeLabelWidth(
                          user && shownTimestamps[0] && startDate && endDate
                            ? roundedValue +
                                (shownUnits[1] !== "%" ? " " : "") +
                                unitConversion(
                                  roundedValue,
                                  shownUnits[1],
                                  user.lengthAndMass,
                                  user.temperature
                                )
                            : ""
                        ),
                      });
                    }

                    return user && shownTimestamps[0] && startDate && endDate
                      ? roundedValue +
                          (shownUnits[1] !== "%" ? " " : "") +
                          unitConversion(
                            roundedValue,
                            shownUnits[1],
                            user.lengthAndMass,
                            user.temperature
                          )
                      : "";
                  }}
                  tickTotal={12}
                />
              ) : (
                <YAxis
                  attr="y"
                  attrAxis="x"
                  orientation="right"
                  className="notSelectable"
                  style={{
                    line: {
                      stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                    },
                    ticks: {
                      stroke: tinyColor(textColor).setAlpha(0.1).toRgbString(),
                    },
                    text: {
                      stroke: tinyColor(textColor).setAlpha(0.25).toRgbString(),
                      fontSize: "10px",
                    },
                  }}
                  tickSize={0}
                  tickFormat={() => ""}
                />
              )}
              {shownTimestamps[1] && (
                <Highlight
                  className="plotHighlight"
                  enableY={false}
                  color={lightContrastColor || lightColor}
                  opacity={0.2}
                  highlightWidth={width - 50}
                  highlightHeight={height - 51}
                  onBrushEnd={(range) => {
                    this.setState({
                      left: range?.left,
                      right: range?.right,
                      crosshairValues: [],
                    });
                  }}
                />
              )}
              {shownTimestamps[0] &&
                crosshairValues[0] &&
                (() => {
                  const crosshairText = shownSeries.map(
                    ({ name, nodes, unitOfMeasurement }) => {
                      let seriesRanges = nodes
                        .filter(
                          (node, index) =>
                            (!nodes[index - 1]?.value ||
                              !nodes[index + 1]?.value) &&
                            !isNullish(node.value)
                        )
                        .map(({ timestamp }) => timestamp)
                        .reverse()
                        .reduce((result, _, index, array) => {
                          if (index % 2 === 0) {
                            result.push(array.slice(index, index + 2));
                          }

                          return result;
                        }, []);

                      return seriesRanges.some(
                        (range) =>
                          +new Date(range[0]) <= +crosshairValues[0].x &&
                          +new Date(range[1]) >= +crosshairValues[0].x
                      )
                        ? `${name}: ${
                            nodes
                              .slice()
                              .sort((a, b) =>
                                Math.abs(
                                  +new Date(a.timestamp) - +crosshairValues[0].x
                                ) >
                                Math.abs(
                                  +new Date(b.timestamp) - +crosshairValues[0].x
                                )
                                  ? 1
                                  : Math.abs(
                                      +new Date(a.timestamp) -
                                        +crosshairValues[0].x
                                    ) ===
                                    Math.abs(
                                      +new Date(b.timestamp) -
                                        +crosshairValues[0].x
                                    )
                                  ? 0
                                  : -1
                              )[0].value +
                            (unitOfMeasurement
                              ? (unitOfMeasurement !== "%" ? "\u00A0" : "") +
                                unitConversion(
                                  null,
                                  unitOfMeasurement,
                                  user.lengthAndMass,
                                  user.temperature
                                )
                              : "")
                          }`
                        : "";
                    }
                  );

                  const crosshairElement = shownSeries.map(
                    ({ name, nodes, unitOfMeasurement, precision }) => {
                      let seriesRanges = nodes
                        .filter(
                          (node, index) =>
                            (isNullish(nodes[index - 1]?.value) ||
                              isNullish(nodes[index + 1]?.value)) &&
                            !isNullish(node.value)
                        )
                        .map(({ timestamp }) => timestamp)
                        .reverse()
                        .reduce((result, _, index, array) => {
                          if (index % 2 === 0) {
                            result.push(array.slice(index, index + 2));
                          }

                          return result;
                        }, []);

                      return seriesRanges.some(
                        (range) =>
                          +new Date(range[0]) <= +crosshairValues[0].x &&
                          +new Date(range[1]) >= +crosshairValues[0].x
                      ) || nodes.length === 1 ? (
                        <>
                          {name}:{" "}
                          {roundToPrecision(
                            valueConversion(
                              nodes
                                .slice()
                                .sort((a, b) =>
                                  Math.abs(
                                    +new Date(a.timestamp) -
                                      +crosshairValues[0].x
                                  ) >
                                  Math.abs(
                                    +new Date(b.timestamp) -
                                      +crosshairValues[0].x
                                  )
                                    ? 1
                                    : Math.abs(
                                        +new Date(a.timestamp) -
                                          +crosshairValues[0].x
                                      ) ===
                                      Math.abs(
                                        +new Date(b.timestamp) -
                                          +crosshairValues[0].x
                                      )
                                    ? 0
                                    : -1
                                )[0].value,
                              unitOfMeasurement,
                              user.lengthAndMass,
                              user.temperature
                            ),
                            precision
                          )}
                          {unitOfMeasurement ? (
                            <>
                              {unitOfMeasurement !== "%" ? "\u00A0" : ""}
                              {unitConversion(
                                null,
                                unitOfMeasurement,
                                user.lengthAndMass,
                                user.temperature
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      );
                    }
                  );

                  return (
                    <Crosshair
                      values={crosshairValues}
                      style={{
                        line: {
                          backgroundColor: lightContrastColor || lightColor,
                          height: height - 72,
                        },
                      }}
                    >
                      <Paper
                        style={{
                          padding: "8px",
                          width:
                            Math.max(
                              ...[
                                ...crosshairText,
                                moment(crosshairValues[0].x).format(
                                  getTickFormat(
                                    shownTimestamps.length === 1
                                      ? +shownTimestamps[0] - 60_000
                                      : shownTimestamps[0],
                                    shownTimestamps.length === 1
                                      ? +shownTimestamps[0] + 60_000
                                      : shownTimestamps[
                                          shownTimestamps.length - 1
                                        ],
                                    user.dateFormat,
                                    user.timeFormat,
                                    moment
                                  )
                                ),
                              ].map((string) => this.computeTextWidth(string))
                            ) || "216px",
                        }}
                        elevation={4}
                        className="notSelectable"
                      >
                        <Typography
                          style={{
                            fontWeight: "bolder",
                          }}
                        >
                          {moment(crosshairValues[0].x).format(
                            getTickFormat(
                              shownTimestamps.length === 1
                                ? +shownTimestamps[0] - 60_000
                                : shownTimestamps[0],
                              shownTimestamps.length === 1
                                ? +shownTimestamps[0] + 60_000
                                : shownTimestamps[shownTimestamps.length - 1],
                              user.dateFormat,
                              user.timeFormat,
                              moment
                            )
                          )}
                        </Typography>
                        <Typography>
                          {crosshairElement.map((text) => (
                            <>
                              {text}
                              {text && <br />}
                            </>
                          ))}
                        </Typography>
                      </Paper>
                    </Crosshair>
                  );
                })()}
            </XYPlot>
          )}
        </AutoSizer>
      );
    }
  }
);
