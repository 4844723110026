import React, { Component } from "react";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GenericDialog } from "@igloocloud/igloosharedui";
import { graphql } from "@apollo/react-hoc";
import gql from "graphql-tag";
import { withTranslation } from "react-i18next";

export default graphql(
  gql`
    mutation changePendingRole($id: ID!, $role: Role!) {
      pendingShare(id: $id, role: $role) {
        id
      }
    }
  `,
  {
    name: "ChangePendingRole",
  }
)(
  withTranslation()(
    class ChangeRole extends Component {
      constructor(props) {
        super(props);

        this.state = { value: null };
      }

      changePendingRole = (role) => {
        this.props.ChangePendingRole({
          variables: {
            id: this.props.menuTarget.id,
            role: role.toUpperCase(),
          },
          optimisticResponse: {
            __typename: "Mutation",
            pendingShare: {
              id: this.props.menuTarget.id,
              role: role.toUpperCase(),
              __typename: "PendingShare",
            },
          },
        });
      };

      render() {
        const { t } = this.props;

        return (
          <GenericDialog
            open={this.props.open}
            close={this.props.close}
            title={t`Change role`}
            textButton={t`Close`}
            textButtonFunction={this.props.close}
          >
            <RadioGroup
              onChange={(event) => {
                this.setState({ value: event.target.value });
                this.props.pending
                  ? this.changePendingRole(event.target.value)
                  : this.props.changeRole(event.target.value);
              }}
              value={this.state.value || this.props.selectedUserType}
            >
              <FormControlLabel
                value="admin"
                control={<Radio color="primary" />}
                label={t`Admin`}
                className="notSelectable"
              />
              <FormControlLabel
                value="editor"
                control={<Radio color="primary" />}
                label={t`Editor`}
                className="notSelectable"
              />
              <FormControlLabel
                value="viewer"
                control={<Radio color="primary" />}
                label={t`Viewer`}
                className="notSelectable"
              />
            </RadioGroup>
          </GenericDialog>
        );
      }
    }
  )
);
