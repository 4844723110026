import {
  DeleteButton,
  GenericDialog,
  MoveButtonGroup,
  theme,
} from "@igloocloud/igloosharedui";
import {
  Button,
  ButtonGroup,
  Collapse,
  MenuItem,
  SvgIcon,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  PreDragActions,
  SensorAPI,
  SnapDragActions,
} from "react-beautiful-dnd";
import { withTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import tinyColor from "tinycolor2";
import ChipInput from "./components/ChipInput";
import {
  Block,
  DragDirection,
  Props,
  Thing,
  Variable,
} from "./CreateReport.types";

//TODO: replace with real data
const series: Variable[] = [
  {
    id: "1",
    name: "Temperature",
    thing: { id: "10", name: "Weather station" },
  },
  { id: "2", name: "Humidity", thing: { id: "10", name: "Weather station" } },
  { id: "3", name: "Pressure", thing: { id: "10", name: "Weather station" } },
  { id: "4", name: "Temperature", thing: { id: "20", name: "Solar panels" } },
  { id: "5", name: "Power", thing: { id: "20", name: "Solar panels" } },
];
const metrics: Variable[] = [
  {
    id: "6",
    name: "Status",
    thing: { id: "30", name: "Smoke detector" },
  },
  {
    id: "7",
    name: "Carbon monoxide",
    thing: { id: "30", name: "Smoke detector" },
  },
  {
    id: "8",
    name: "Temperature",
    thing: { id: "40", name: "Fridge" },
  },
  {
    id: "9",
    name: "Humidity",
    thing: { id: "40", name: "Fridge" },
  },
];

const useMoveButtons =
  (
    selectedDraggable: string,
    setSelectedDraggable: (any) => void,
    dragDirection: DragDirection,
    setDragDirection: (any) => void
  ) =>
  (api: SensorAPI) => {
    const preDrag: PreDragActions = api.tryGetLock(selectedDraggable);

    if (preDrag) {
      const { moveUp, moveDown, drop }: SnapDragActions = preDrag.snapLift();

      if (dragDirection === "up") {
        moveUp();
      } else {
        moveDown();
      }

      const selectedBlock: HTMLElement = document.getElementById(
        "draggable-block-" + selectedDraggable
      );
      const dropBlock = ({ propertyName }: { propertyName: string }) => {
        if (propertyName === "transform") {
          drop();
        }

        selectedBlock.removeEventListener("transitionend", dropBlock);
      };

      selectedBlock.addEventListener("transitionend", dropBlock);
    }

    setSelectedDraggable(null);
    setDragDirection(null);
  };

const reorderBlocks = (array: Block[], fromIndex: number, toIndex: number) => {
  const element = array[fromIndex];
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, element);

  return array;
};

const createReport = async (
  setLoading: (value: boolean) => void,
  setError: (value: string) => void,
  t: (string) => string,
  collectionId: string,
  reportTitle: string,
  blocks: Block[]
) => {
  setLoading(true);

  try {
    const { ok } = await fetch(
      "https://agrentareportapi.azurewebsites.net/createReport",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          collectionId,
          reportTitle,
          blocks: blocks.map(
            ({
              localId: propertyToDelete,
              ...propertiesToKeep
            }: {
              localId: number;
            }) => propertiesToKeep
          ),
        }),
      }
    );

    if (!ok) {
      throw new Error(t`Error`);
    }
  } catch (e) {
    setError(t`Error`);
  } finally {
    setLoading(false);
  }
};

const CreateReport = withTranslation()(
  ({ open, close, t, collections }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>(null);
    const [collectionId, setCollectionId] = useState<string>(null);
    const [reportTitle, setReportTitle] = useState<string>(null);
    const [reportTitleEmpty, setReportTitleEmpty] = useState<boolean>(false);
    const [blocks, setBlocks] = useState<Block[]>([]);
    const [selectedDraggable, setSelectedDraggable] = useState<string>(null);
    const [dragDirection, setDragDirection] = useState<DragDirection>(null);
    const greaterThanSm = useMediaQuery<boolean>(
      theme(process.env).breakpoints.up("sm")
    );

    const {
      REACT_APP_COLLECTION_NAME: collectionName,
      REACT_APP_MAIN_BACKGROUND_COLOR: mainBackgroundColor,
      REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: textOnMainBackgroundColor,
    }: {
      REACT_APP_COLLECTION_NAME: string;
      REACT_APP_TEXT_ON_MAIN_BACKGROUND_COLOR: string;
      REACT_APP_MAIN_BACKGROUND_COLOR: string;
    } = process.env;
    const addButtonStyle: {
      borderColor: string;
      "&:hover": { borderColor: string };
    } = {
      borderColor: tinyColor(textOnMainBackgroundColor)
        .setAlpha(0.26)
        .toHex8String(),
      "&:hover": {
        borderColor: tinyColor(textOnMainBackgroundColor)
          .setAlpha(0.87)
          .toHex8String(),
      },
    };

    useEffect(() => setCollectionId(collections[0]?.id), collections);

    const seriesThings: Thing[] = series.reduce(
      (things, variable) => [
        ...things.filter(
          ({ id: idToRemove }) => idToRemove !== variable.thing.id
        ),
        things.find(({ id: idToFind }) => idToFind === variable.thing.id)
          ? {
              ...things.find(
                ({ id: idToFind }) => idToFind === variable.thing.id
              ),
              variables: [
                ...things.find(
                  ({ id: idToFind }) => idToFind === variable.thing.id
                ).variables,
                variable,
              ],
            }
          : {
              ...variable.thing,
              variables: [variable],
            },
      ],
      []
    );
    const metricThings: Thing[] = metrics.reduce(
      (things, variable) => [
        ...things.filter(
          ({ id: idToRemove }) => idToRemove !== variable.thing.id
        ),
        things.find(({ id: idToFind }) => idToFind === variable.thing.id)
          ? {
              ...things.find(
                ({ id: idToFind }) => idToFind === variable.thing.id
              ),
              variables: [
                ...things.find(
                  ({ id: idToFind }) => idToFind === variable.thing.id
                ).variables,
                variable,
              ],
            }
          : {
              ...variable.thing,
              variables: [variable],
            },
      ],
      []
    );

    return (
      <GenericDialog
        open={open}
        close={close}
        title={t`Create report`}
        textButton={t`Close`}
        textButtonFunction={close}
        containedButton={t`Create`}
        containedButtonFunction={() =>
          createReport(
            setLoading,
            setError,
            t,
            collectionId,
            reportTitle,
            blocks
          )
        }
        containedButtonDisabled={
          !reportTitle ||
          blocks.length === 0 ||
          !blocks.every(({ type, title, text, variableIds }: Block) =>
            type === "paragraph" ? title && text : variableIds.length
          )
        }
        containedButtonLoading={loading}
        containedButtonError={error}
        disableMaxHeight
        maxWidth="sm"
      >
        <TextField
          variant="outlined"
          label={t(collectionName || "Collection")}
          select
          fullWidth
          required
          value={collectionId}
          onChange={(event) => {
            setCollectionId(event.target.value);
          }}
          style={{ marginBottom: "32px" }}
        >
          {collections.map(({ id, name }) => (
            <MenuItem value={id} key={"collection-" + id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label={t`Report title`}
          variant="outlined"
          fullWidth
          required
          value={reportTitle}
          onChange={(event) => {
            setReportTitle(event.target.value);
            setReportTitleEmpty(!event.target.value);
          }}
          helperText={
            error ?? (reportTitleEmpty ? t`This field is required` : " ")
          }
          error={!!error || reportTitleEmpty}
          style={{ marginBottom: "16px" }}
        />
        <DragDropContext
          onDragUpdate={({ destination, source }) => {
            if (
              destination &&
              (destination.droppableId !== source.droppableId ||
                destination.index !== source.index)
            ) {
              setBlocks((blocks) =>
                reorderBlocks(blocks, source.index, destination.index)
              );
            }
          }}
          sensors={[
            useMoveButtons(
              selectedDraggable,
              setSelectedDraggable,
              dragDirection,
              setDragDirection
            ),
          ]}
        >
          <Droppable droppableId="create-report-dialog-droppable-area">
            {({ innerRef, droppableProps, placeholder }) => (
              <div ref={innerRef} {...droppableProps}>
                <div>
                  <TransitionGroup>
                    {blocks.map(
                      ({ localId, type, title, text, variableIds }, index) => (
                        <Collapse key={"draggable-block-collapse-" + localId}>
                          <Draggable
                            draggableId={localId.toString()}
                            index={index}
                          >
                            {({ draggableProps, innerRef }) => (
                              <div
                                {...draggableProps}
                                id={"draggable-block-" + localId}
                                ref={innerRef}
                              >
                                {type === "paragraph" ? (
                                  <div style={{ paddingBottom: "32px" }}>
                                    <div
                                      style={{
                                        backgroundColor: mainBackgroundColor,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          paddingBottom: "16px",
                                        }}
                                      >
                                        <TextField
                                          required
                                          label={t`Paragraph title`}
                                          variant="outlined"
                                          style={{
                                            width: "calc(100% - 144px)",
                                          }}
                                          InputLabelProps={{
                                            style: {
                                              backgroundColor:
                                                mainBackgroundColor,
                                            },
                                          }}
                                          value={title}
                                          onChange={(event) =>
                                            setBlocks((blocks) => {
                                              return blocks.map((block) =>
                                                block.localId === localId
                                                  ? {
                                                      ...block,
                                                      title: event.target.value,
                                                    }
                                                  : block
                                              );
                                            })
                                          }
                                        />
                                        <MoveButtonGroup
                                          onClick={(direction) => {
                                            setSelectedDraggable(
                                              localId.toString()
                                            );
                                            setDragDirection(direction);
                                          }}
                                          index={index}
                                          length={blocks.length}
                                        />
                                        <DeleteButton
                                          onClick={() =>
                                            setBlocks((blocks: Block[]) =>
                                              blocks.filter(
                                                ({ localId: idToRemove }) =>
                                                  idToRemove !== localId
                                              )
                                            )
                                          }
                                        />
                                      </div>
                                      <TextField
                                        required
                                        label={t`Paragraph content`}
                                        multiline
                                        rows={6}
                                        variant="outlined"
                                        fullWidth
                                        value={text}
                                        onChange={(event) =>
                                          setBlocks((blocks) => {
                                            return blocks.map((block) =>
                                              block.localId === localId
                                                ? {
                                                    ...block,
                                                    text: event.target.value,
                                                  }
                                                : block
                                            );
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                ) : type === "chart" ? (
                                  <div
                                    style={{
                                      paddingBottom: "32px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        backgroundColor: mainBackgroundColor,
                                      }}
                                    >
                                      <ChipInput
                                        setBlocks={setBlocks}
                                        mainBackgroundColor={
                                          mainBackgroundColor
                                        }
                                        textOnMainBackgroundColor={
                                          textOnMainBackgroundColor
                                        }
                                        things={seriesThings}
                                        variables={series}
                                        variableIds={variableIds}
                                        localId={localId}
                                        label={t`Series`}
                                      />
                                      <MoveButtonGroup
                                        onClick={(direction) => {
                                          setSelectedDraggable(
                                            localId.toString()
                                          );
                                          setDragDirection(direction);
                                        }}
                                        index={index}
                                        length={blocks.length}
                                      />
                                      <DeleteButton
                                        onClick={() =>
                                          setBlocks((blocks: Block[]) =>
                                            blocks.filter(
                                              ({ localId: idToRemove }) =>
                                                idToRemove !== localId
                                            )
                                          )
                                        }
                                        disableUndo
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      paddingBottom: "32px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        backgroundColor: mainBackgroundColor,
                                      }}
                                    >
                                      <ChipInput
                                        setBlocks={setBlocks}
                                        mainBackgroundColor={
                                          mainBackgroundColor
                                        }
                                        textOnMainBackgroundColor={
                                          textOnMainBackgroundColor
                                        }
                                        things={metricThings}
                                        variables={metrics}
                                        variableIds={variableIds}
                                        localId={localId}
                                        label={t`Metrics`}
                                      />
                                      <MoveButtonGroup
                                        onClick={(direction) => {
                                          setSelectedDraggable(
                                            localId.toString()
                                          );
                                          setDragDirection(direction);
                                        }}
                                        index={index}
                                        length={blocks.length}
                                      />
                                      <DeleteButton
                                        onClick={() =>
                                          setBlocks((blocks: Block[]) =>
                                            blocks.filter(
                                              ({ localId: idToRemove }) =>
                                                idToRemove !== localId
                                            )
                                          )
                                        }
                                        disableUndo
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </Draggable>
                        </Collapse>
                      )
                    )}
                  </TransitionGroup>
                  {placeholder}
                </div>
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <ButtonGroup fullWidth size="large">
          <Button
            color="inherit"
            sx={addButtonStyle}
            onClick={() =>
              setBlocks((blocks) => [
                ...blocks,
                {
                  type: "paragraph",
                  title: "",
                  text: "",
                  localId: +Date.now(),
                },
              ])
            }
          >
            <SvgIcon style={{ marginRight: greaterThanSm ? "8px" : 0 }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M21 8V6H3V8H21ZM12 18H3V16H12V18ZM15.6822 13H3V11H21V12.3414C20.3744 12.1203 19.7013 12 19 12C17.7733 12 16.6325 12.3682 15.6822 13Z"
                  fill="black"
                />
                <rect x="15" y="17" width="8" height="2" fill="black" />
                <rect
                  x="18"
                  y="22"
                  width="8"
                  height="2"
                  transform="rotate(-90 18 22)"
                  fill="black"
                />
              </svg>
            </SvgIcon>
            {greaterThanSm && t`Paragraph`}
          </Button>
          <Button
            onClick={() =>
              setBlocks((blocks) => [
                ...blocks,
                {
                  type: "chart",
                  variableIds: [],
                  localId: +Date.now(),
                },
              ])
            }
            color="inherit"
            sx={addButtonStyle}
          >
            <SvgIcon style={{ marginRight: greaterThanSm ? "8px" : 0 }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20.24 4.45L16 11.78L9.5 8L4 17.54V3H2V21H13.8027C13.4513 20.3926 13.2034 19.7179 13.083 19H5.46L10.23 10.75L15.0205 13.5095C15.8795 12.7477 16.9585 12.2287 18.1502 12.0597L21.97 5.45L20.24 4.45Z"
                  fill="black"
                />
                <rect x="15" y="17" width="8" height="2" fill="black" />
                <rect
                  x="18"
                  y="22"
                  width="8"
                  height="2"
                  transform="rotate(-90 18 22)"
                  fill="black"
                />
              </svg>
            </SvgIcon>
            {greaterThanSm && t`Series`}
          </Button>
          <Button
            onClick={() =>
              setBlocks((blocks) => [
                ...blocks,
                {
                  type: "metric",
                  variableIds: [],
                  localId: +Date.now(),
                },
              ])
            }
            color="inherit"
            sx={addButtonStyle}
          >
            <SvgIcon style={{ marginRight: greaterThanSm ? "8px" : 0 }}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M4 9V17H6V7H2V9H4ZM21.745 12.6633C20.9224 12.2394 19.9892 12 19 12C18.6593 12 18.3252 12.0284 18 12.083V11H20V9H16V7H20C20.5304 7 21.0391 7.21071 21.4142 7.58579C21.7893 7.96086 22 8.46957 22 9V10.5C22 10.8978 21.842 11.2794 21.5607 11.5607C21.2794 11.842 20.8978 12 20.5 12C20.8978 12 21.2794 12.158 21.5607 12.4393C21.6297 12.5084 21.6913 12.5835 21.745 12.6633ZM13.8027 15C13.4513 15.6074 13.2034 16.2821 13.083 17H8V13C8 11.89 8.9 11 10 11H12V9H8V7H12C12.5304 7 13.0391 7.21071 13.4142 7.58579C13.7893 7.96086 14 8.46957 14 9V11C14 12.11 13.1 13 12 13H10V15H13.8027Z"
                  fill="black"
                />
                <rect x="15" y="17" width="8" height="2" fill="black" />
                <rect
                  x="18"
                  y="22"
                  width="8"
                  height="2"
                  transform="rotate(-90 18 22)"
                  fill="black"
                />
              </svg>
            </SvgIcon>
            {greaterThanSm && t`Metrics`}
          </Button>
        </ButtonGroup>
      </GenericDialog>
    );
  }
);

export default CreateReport;
